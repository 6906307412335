import api from '../index'

export default {
  policyList(params) {
    return api.get('/SocialPolicy/PolicyList', params)
  },
  getDistrict(params) {
    return api.get('/SocialPolicy/District', params)
  },
  getPolicyByNumber(params) {
    return api.get('/SocialPolicy/GetPolicyByNumber', params)
  },
  operate(data, params) {
    return api.postwithparams('/SocialPolicy/Operate', data, params)
  },
  operateNew(params) {
    return api.post('/SocialPolicy/Operate', params)
  },
  delete(params, identify) {
    return api.post('/SocialPolicy/Delete/' + identify, params)
  },
  downloadDemo(params) {
    return api.getfile('/SocialPolicy/DownloadDemo/' + params.type)
  },
  savePolicy(params) {
    return api.post('/SocialPolicy/Policy', params)
  },
  addPolicy(params) {
    return api.post('/SocialPolicy', params)
  },
  uploadFile(params) {
    return api.post('/SocialPolicy/Upload/', params)
  },
  multiImport(params) {
    return api.post('/SocialPolicy/FileUpload', params)
  },
  getInsurance(districtName) {
    let urls = '/SocialPolicy/Insurance';
    districtName.forEach(item => {
      urls.indexOf('districtNames') == -1 ? urls += '?districtNames=' + item : urls += '&districtNames=' + item
    })
    return api.get(urls)
  },
  getPolicyName(params) {
    return api.get('/SocialPolicy/PolicyName', params)
  },
  fanyouSocialPolicyList(params) {
    let urls = '/SocialPolicy/SocialPolicyList';
    if (params.Districts) {
      params.Districts.forEach(item => {
        urls.indexOf('Districts') == -1 ? urls += '?Districts=' + item : urls += '&Districts=' + item
      })
    }
    let newParams = {};
    for (let i in params) {
      if (i != 'Districts') {
        newParams[i] = params[i]
      }
    }
    return api.get(urls, newParams)
  },
  fanyouSocialPolicyDetail(policyId) {
    return api.get('/SocialPolicy/SocialPolicyDetail/' + policyId)
  },
  getUploadFile(fileName) {
    return api.getfile('/SocialPolicy/GetUploadFile/' + fileName)
  },
}