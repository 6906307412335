import api from '../index'

export default {
  AddCostTemplate(params) { // 新增费用模板保存
    return api.post('/cost/template/update', params)
  },
  UpdateCostTemplate(params) { // 编辑模板保存
    return api.post('/cost/template/update', params)
  },
  CostTemplateList(params) { // 费用模板列表
    return api.get('/cost/template', params)
  },
  CostTemplateDetail(bid) { // 模板详情
    return api.get('/cost/template/' + bid)
  },
  getSelectedBranches(effectMonth,bid) { // 获取已被绑定的网点，传id则排除当前所传Id模板绑定的网点数据，查所有则传0
    return api.get('/cost/template/getSelected/' +effectMonth +'/'+ bid)
  },
  CostTemplateLog(params){//获取日志 //已不需要
    return api.get('/cost/template/log',params)
  },
  GetVersionListByName(TemplateName)
  {
    return api.get('/cost/template/getVersionList/'+TemplateName)
  },
  GetTemplateByNameAndVersion(TemplateName,Version)
  {
    return api.get('/cost/template/'+TemplateName+'/'+Version)
  },
  getTemplateName(){//获取最新自定义托管网点名称
    return api.get('/cost/template/getName')
  },
  IsExistType(type){//判断模板是否已存在
    return api.get('/cost/template/IsExistType/'+type)
  },
  EssentialsUpload(params,filename) { // 小库文件上传
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return api.postwithparams('/EssentialsUpload/'+ filename, params, headers)
  },
}