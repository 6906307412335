<template>
  <div class="fullscreen-message">
    <div class="content" style="width: 100%;">
      <!-- 这里放置消息内容，包括图片和文本 -->
      <img
        :src="imageUrl"
        alt=""
        style="width: auto; height: auto; margin-bottom: 16px"
      />
      <h3 style="color: #ccc; margin: 0; text-align: center;font-weight:400">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    message: String
  }
}
</script>

<style>
.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    flex-direction: column;
    background-color: white;
}
.fullscreen-message {
  position: fixed;
  top: 0;
  z-index: 99999;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: white;
  padding: 20px;
  z-index: 99999;
  border-radius: 5px;
}
.el-table::before {
    left: 0;
    bottom: 0;
    width:0%;
    height: 1px;
}
.el-checkbox__inner {
    /* display: inline-block; */
    position: static;
    /* border: 1px solid #DCDFE6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #FFF;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46), background-color .25s cubic-bezier(.71,-.46,.29,1.46); */
}
</style>
