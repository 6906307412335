<template>
  <div id="AddNetwork">
    <div class="page-header">
      <p class="page-path">
        服务网点管理<span class="slashline">/</span>小库网点列表<span
          class="slashline"
          >/</span
        >编辑网点
      </p>
      <p class="page-title">编辑网点</p>
    </div>
    <div class="info-filling">
      <div class="info-basic">
        <p class="title">网点基本信息</p>
        <el-form
          ref="basicform"
          :model="basicform"
          label-width="140px"
          class="basicform"
          :rules="basicformRules"
        >
          <el-form-item label="网点地区：" prop="branchDistrict">
            <el-cascader
              ref="cascader"
              :options="districtOptions"
              v-model="basicform.branchDistrictCodes"
              clearable
              @change="branchDistrictChange"
              :props="defaultProps"
              :disabled="isDis"
              id="branchDistrictCascader"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="网点名称：" prop="name">
            <el-input v-model="basicform.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="网点负责人：" prop="branchRepresentative">
            <el-input
              v-model="basicform.branchRepresentative"
              placeholder="请输入"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否需要在保人员：">
            <el-radio-group v-model="basicform.isPersonalEssential">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
            <div class="form-inline-group">
              <el-form-item
                prop="socialEssential"
                :rules="
                  basicform.isPersonalEssential == 1
                    ? basicformRules.socialEssential
                    : [{ required: false }]
                "
              >
                <span class="text">社保</span>
                <el-input
                  v-model.number="basicform.socialEssential"
                  placeholder="请输入"
                  style="width: 70px"
                  :disabled="basicform.isPersonalEssential == 0"
                  @input="
                    (value) => {
                      basicform.socialEssential = value.replace(/[^\d]/g, '');
                    }
                  "
                  @blur="
                    (e) => {
                      basicform.socialEssential = Number(e.target.value);
                    }
                  "
                ></el-input>
                <span class="text">人</span>
              </el-form-item>
              <el-form-item
                prop="accEssential"
                :rules="
                  basicform.isPersonalEssential == 1
                    ? basicformRules.accEssential
                    : [{ required: false }]
                "
              >
                <span class="text">公积金</span>
                <el-input
                  v-model.number="basicform.accEssential"
                  placeholder="请输入"
                  style="width: 70px"
                  :disabled="basicform.isPersonalEssential == 0"
                  @input="
                    (value) => {
                      basicform.accEssential = value.replace(/[^\d]/g, '');
                    }
                  "
                  @blur="
                    (e) => {
                      basicform.accEssential = Number(e.target.value);
                    }
                  "
                ></el-input>
                <span class="text">人</span>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="社保补缴:">
            <el-col :span="10">
                <el-radio-group style="padding-left:5px;"
                v-model="basicform.socialOfflineOperation"
              >
                <el-radio :label="1">线上操作</el-radio>
                <el-radio :label="2">线下操作</el-radio>
              </el-radio-group>
            </el-col>
            <el-col class="line" :span="4">公积金补缴：</el-col>
            <el-col :span="10">
               <el-radio-group
                v-model="basicform.accOfflineOperation"
              >
                <el-radio :label="1">线上操作</el-radio>
                <el-radio :label="2">线下操作</el-radio>
              </el-radio-group>
            </el-col>
          </el-form-item>
          <el-form-item label="收件地址：" prop="consigneeAddressInfo">
            <el-input
              v-model="consigneeAddressInfo.receiver"
              placeholder="请输入收件人"
              maxlength="20"
              style="width:49.5%;margin-bottom: 10px"
            ></el-input>
             <el-input
              v-model="consigneeAddressInfo.phoneNumber"
              placeholder="请输入电话号码"
              maxlength="20"
              style="margin-left:1px;width:50%;margin-bottom: 10px"
            ></el-input>
             <el-input
              type="textarea"
              v-model="consigneeAddressInfo.address"
              :rows="1"
              placeholder="请输入邮寄地址"
              maxlength="100"
              style="margin-bottom: 10px"
            ></el-input>
             <el-input
              type="textarea"
              v-model="consigneeAddressInfo.remark"
              placeholder="备注"
              :rows="1"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="公积金比例设置：">
            <div
              class="form-inline-group"
              style="margin-left: 0; margin-bottom: 14px"
              v-for="(item, index) in basicform.accRatio"
              :key="index"
            >
              <el-form-item
                :prop="'accRatio.' + index + '.person'"
                :rules="{
                  required: true,
                  message: '请输入个人缴纳比例',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="item.person"
                  placeholder="个人缴纳比例（%）"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="margin-left: 10px"
                :prop="'accRatio.' + index + '.enterprise'"
                :rules="{
                  required: true,
                  message: '请输入单位缴纳比例',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="item.enterprise"
                  placeholder="单位缴纳比例（%）"
                ></el-input>
              </el-form-item>
              <el-button
                type="default"
                icon="el-icon-delete"
                @click.prevent="removeProvidentfundRatio(item)"
                style="margin-left: 6px"
              ></el-button>
            </div>
            <div>
              <el-button
                icon="el-icon-plus"
                class="addButton"
                type="primary"
                @click="addprovidentfundRatio"
              ></el-button>
            </div>
          </el-form-item>
          <el-form-item label="特殊情况说明：" prop="remark">
            <el-input
              type="textarea"
              v-model="basicform.remark"
              placeholder="请输入"
              maxlength="200"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="create-filling">
        <p class="title">开户所需资料</p>
        <div class="filling-btn-group">
          <el-button type="primary" @click="addCreateRow">新增</el-button
          ><el-button @click="delCreateRow">删除</el-button>
        </div>
        <el-form
          ref="createTableForm"
          :model="createTableForm"
          :rules="createTableFormRules"
        >
          <el-table
            ref="createTable"
            max-height="500"
            :data="createTableForm.createTableData"
            border
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="资料名称">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'createTableData.' + scope.$index + '.name'"
                  :rules="createTableFormRules.name"
                >
                  <el-input
                    v-model="scope.row.name"
                    placeholder="请输入"
                    maxlength="256"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料类型">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'createTableData.' + scope.$index + '.format'"
                  :rules="createTableFormRules.format"
                >
                  <el-select v-model="scope.row.format" placeholder="请选择">
                    <el-option label="原件类" value="原件类"></el-option>
                    <el-option label="图像类" value="图像类"></el-option>
                    <el-option label="文本类" value="文本类"></el-option>
                    <el-option label="表格或协议类" value="表格或协议类"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料要求">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'createTableData.' + scope.$index + '.requirements'"
                  :rules="createTableFormRules.requirements"
                >
                  <el-input
                    v-model="scope.row.requirements"
                    placeholder="请输入"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料份数">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'createTableData.' + scope.$index + '.quantity'"
                  :rules="createTableFormRules.quantity"
                >
                  <el-input
                    v-model.number="scope.row.quantity"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="适用场景">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'createTableData.' + scope.$index + '.occations'"
                  :rules="createTableFormRules.occations"
                >
                  <el-select v-model="scope.row.occations" placeholder="请选择">
                    <el-option label="社保" :value="0"></el-option>
                    <el-option label="公积金" :value="1"></el-option>
                    <el-option label="社保和公积金" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料样本">
              <template slot-scope="scope">
                <div
                  v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample != ''"
                >
                  {{ scope.row.uploadSampleFileName
                  }}<el-button
                    type="text"
                    @click="
                      scope.row.sample = scope.row.uploadSampleFile = scope.row.uploadSampleFileName =
                        ''
                    "
                    >删除</el-button
                  >
                </div>
                <el-button
                  v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample == ''"
                  @click="$refs['createTableSample' + scope.$index].click()"
                  :loading="scope.row.uploadSampleLoading"
                  >上传文件</el-button
                >
                <input
                  type="file"
                  hidden
                  :ref="'createTableSample' + scope.$index"
                  @change="uploadSampleFile($event, scope, '开户')"
                />
                <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
              </template>
            </el-table-column>
            <el-table-column label="空表上传">
              <template slot-scope="scope">
                <div
                  v-if="
                    (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample != ''
                  "
                >
                  {{ scope.row.uploadEmptySampleFileName
                  }}<el-button
                    type="text"
                    @click="
                      scope.row.emptySample = scope.row.uploadEmptySampleFile = scope.row.uploadEmptySampleFileName =
                        ''
                    "
                    >删除</el-button
                  >
                </div>
                <el-button
                  v-if="
                    (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample == ''
                  "
                  @click="
                    $refs['createTableEmptySample' + scope.$index].click()
                  "
                  :loading="scope.row.uploadEmptySampleLoading"
                  >上传文件</el-button
                >
                <input
                  type="file"
                  hidden
                  :ref="'createTableEmptySample' + scope.$index"
                  @change="uploadEmptySampleFile($event, scope, '开户')"
                />
                <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div class="hosting-filling">
        <p class="title">托管所需资料</p>
        <div class="filling-btn-group">
          <el-button type="primary" @click="addHostingRow">新增</el-button
          ><el-button @click="delHostingRow">删除</el-button>
        </div>
        <el-form
          ref="hostingTableForm"
          :model="hostingTableForm"
          :rules="hostingTableFormRules"
        >
          <el-table
            ref="hostingTable"
            max-height="500"
            :data="hostingTableForm.hostingTableData"
            border
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="资料名称">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'hostingTableData.' + scope.$index + '.name'"
                  :rules="hostingTableFormRules.name"
                >
                  <el-input
                    v-model="scope.row.name"
                    placeholder="请输入"
                    maxlength="256"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料类型">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'hostingTableData.' + scope.$index + '.format'"
                  :rules="hostingTableFormRules.format"
                >
                  <el-select v-model="scope.row.format" placeholder="请选择">
                    <el-option label="原件类" value="原件类"></el-option>
                    <el-option label="图像类" value="图像类"></el-option>
                    <el-option label="文本类" value="文本类"></el-option>
                    <el-option label="表格或协议类" value="表格或协议类"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料要求">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'hostingTableData.' + scope.$index + '.requirements'"
                  :rules="hostingTableFormRules.requirements"
                >
                  <el-input
                    v-model="scope.row.requirements"
                    placeholder="请输入"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料份数">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'hostingTableData.' + scope.$index + '.quantity'"
                  :rules="hostingTableFormRules.quantity"
                >
                  <el-input
                    v-model.number="scope.row.quantity"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="适用场景">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'hostingTableData.' + scope.$index + '.occations'"
                  :rules="hostingTableFormRules.occations"
                >
                  <el-select v-model="scope.row.occations" placeholder="请选择">
                    <el-option label="社保" :value="0"></el-option>
                    <el-option label="公积金" :value="1"></el-option>
                    <el-option label="社保和公积金" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="资料样本">
              <template slot-scope="scope">
                <div
                  v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample != ''"
                >
                  {{ scope.row.uploadSampleFileName
                  }}<el-button
                    type="text"
                    @click="
                      scope.row.sample = scope.row.uploadSampleFile = scope.row.uploadSampleFileName =
                        ''
                    "
                    >删除</el-button
                  >
                </div>
                <el-button
                  v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample == ''"
                  @click="$refs['hostingTableSample' + scope.$index].click()"
                  :loading="scope.row.uploadSampleLoading"
                  >上传文件</el-button
                >
                <input
                  type="file"
                  hidden
                  :ref="'hostingTableSample' + scope.$index"
                  @change="uploadSampleFile($event, scope, '托管')"
                />
                <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
              </template>
            </el-table-column>
            <el-table-column label="空表上传">
              <template slot-scope="scope">
                <div
                  v-if="
                    (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample != ''
                  "
                >
                  {{ scope.row.uploadEmptySampleFileName
                  }}<el-button
                    type="text"
                    @click="
                      scope.row.emptySample = scope.row.uploadEmptySampleFile = scope.row.uploadEmptySampleFileName =
                        ''
                    "
                    >删除</el-button
                  >
                </div>
                <el-button
                  v-if="
                    (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample == ''
                  "
                  @click="
                    $refs['hostingTableEmptySample' + scope.$index].click()
                  "
                  :loading="scope.row.uploadEmptySampleLoading"
                  >上传文件</el-button
                >
                <input
                  type="file"
                  hidden
                  :ref="'hostingTableEmptySample' + scope.$index"
                  @change="uploadEmptySampleFile($event, scope, '托管')"
                />
                <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
    </div>
    <div class="bottom-btn-group">
      <el-button @click="returnServiceNetwork">返回</el-button>
      <el-button type="primary" @click="submitSource">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddNetwork",
  components: {},
  data: function () {
    return {
      districtOptions: [],
      defaultProps:{
        value: 'code',
        label: 'areaName',
        children: 'childs'
      },
      isFirst:true,
      isDis:true,
      BranchDetail: "",
      districtCNArr:[],
      branchDistrictList: [],
      basicform: {
        branchDistrictCodes:[],
        // 网点基本信息
        sid: "",
        branchDistrict: "",
        name: "",
        branchRepresentative: "",
        isPersonalEssential: "",
        consigneeAddress: "",
        socialEssential: "",
        accEssential: "",
        socialOfflineOperation:0,
        accOfflineOperation:0,
        remark: "",
        accRatio: [
          {
            person: "",
            enterprise: "",
          },
        ],
      },
      consigneeAddressInfo:{
        receiver:"",
        phoneNumber:"",
        address:"",
        remark:""
      },
      basicformRules: {
        branchDistrict: [
          { required: true, message: "请选择网点地区", trigger: "change" },
        ],
        name: [
          { required: false, message: "网点名称不能为空", trigger: "change" },
        ],
        branchRepresentative: [
          { required: true, message: "请输入网点负责人", trigger: "blur" },
          { min: 1, max: 20, message: "长度在1到20个字符", trigger: "blur" },
        ],
        // consigneeAddress: [
        //   { required: true, message: "请输入网点负责人", trigger: "blur" },
        //   { min: 1, max: 200, message: "长度在1到200个字符", trigger: "blur" },
        // ],
        socialEssential: [
          { required: true, message: "请输入社保人数", trigger: "blur" },
        ],
        accEssential: [
          { required: true, message: "请输入公积金人数", trigger: "blur" },
        ]
      },
      createTableForm: {
        createTableData: [
          // 开户所需资料--表数据
          {
            rowindex: 0,
            type: 0, // 开户资料 0
            name: "",
            format: "",
            requirements: "",
            quantity: "",
            occations: "",
            sample: "",
            uploadSampleFile: "",
            uploadSampleFileName: "",
            uploadSampleLoading: false,
            emptySample: "",
            uploadEmptySampleFile: "",
            uploadEmptySampleFileName: "",
            uploadEmptySampleLoading: false,
          },
        ],
      },
      createTableFormRules: {
        name: [
          { required: true, message: "请填写资料名称", trigger: "change" },
          { min: 1, max: 256, message: "长度在1到256个字符", trigger: "change" },
        ],
        format: [
          { required: true, message: "请选择资料类型", trigger: "change" },
        ],
        requirements: [
          { required: true, message: "请输入资料要求", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" },
        ],
        quantity: [
          { required: true, message: "请输入资料份数", trigger: "change" },
          { type: "number", message: "必须为数字", trigger: "blur" },
          { pattern: /^([0-9]+)$/, message: "必须大于等于0", trigger: "blur" },
        ],
        occations: [
          { required: true, message: "请选择适用场景", trigger: "change" },
        ],
      },
      hostingTableForm: {
        hostingTableData: [
          // 托管所需资料--表数据
          {
            rowindex: 0,
            type: 1, // 托管资料 1
            name: "",
            format: "",
            requirements: "",
            quantity: "",
            occations: "",
            sample: "",
            uploadSampleFile: "",
            uploadSampleFileName: "",
            uploadSampleLoading: false,
            emptySample: "",
            uploadEmptySampleFile: "",
            uploadEmptySampleFileName: "",
            uploadEmptySampleLoading: false,
          },
        ],
      },
      hostingTableFormRules: {
        name: [
          { required: true, message: "请填写资料名称", trigger: "change" },
          { min: 1, max: 256, message: "长度在1到256个字符", trigger: "change" },
        ],
        format: [
          { required: true, message: "请选择资料类型", trigger: "change" },
        ],
        requirements: [
          { required: true, message: "请输入资料要求", trigger: "change" },
          { min: 1, max: 50, message: "长度在1到50个字符", trigger: "change" },
        ],
        quantity: [
          { required: true, message: "请输入资料份数", trigger: "change" },
          { type: "number", message: "必须为数字", trigger: "blur" },
          { pattern: /^([0-9]+)$/, message: "必须大于等于0", trigger: "blur" },
        ],
        occations: [
          { required: true, message: "请选择适用场景", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    addCreateRow() {
      // 新增一行开户所需资料
      if (this.createTableForm.createTableData == undefined) {
        this.createTableForm.createTableData = [];
      }
      let obj = {
        rowindex:
          this.createTableForm.createTableData == [] ||
          this.createTableForm.createTableData.length == 0
            ? 0
            : this.createTableForm.createTableData[
                this.createTableForm.createTableData.length - 1
              ].rowindex + 1,
        type: 0,
        name: "",
        format: "",
        requirements: "",
        quantity: "",
        occations: "",
        sample: "",
        uploadSampleFile: "",
        uploadSampleFileName: "",
        uploadSampleLoading: false,
        emptySample: "",
        uploadEmptySampleFile: "",
        uploadEmptySampleFileName: "",
        uploadEmptySampleLoading: false,
      };
      this.createTableForm.createTableData.push(obj);
    },
    branchDistrictChange() {
      let loc = "";
      if(this.$refs['cascader'].getCheckedNodes()[0] == undefined){
        this.basicform.branchDistrict = loc;
        return;
      }
      for (let i = 0; i < this.$refs['cascader'].getCheckedNodes()[0].pathLabels.length; i++){
        loc += this.$refs['cascader'].getCheckedNodes()[0].pathLabels[i];
      }
      this.basicform.branchDistrict = loc;
      this.createBranchName(this.basicform.branchDistrict);
    },
    delCreateRow() {
      for (let i = this.createTableForm.createTableData.length; i > 0; i--) {
        for (let j = 0; j < this.$refs.createTable.selection.length; j++) {
          if (
            this.createTableForm.createTableData[i - 1].rowindex ==
            this.$refs.createTable.selection[j].rowindex
          ) {
            this.createTableForm.createTableData.splice(i - 1, 1);
            break;
          }
        }
      }
    },
    addHostingRow() {
      // 新增一行托管所需资料
      if (this.hostingTableForm.hostingTableData == undefined) {
        this.hostingTableForm.hostingTableData = [];
      }
      let obj = {
        rowindex:
          this.hostingTableForm.hostingTableData == [] ||
          this.hostingTableForm.hostingTableData.length == 0
            ? 0
            : this.hostingTableForm.hostingTableData[
                this.hostingTableForm.hostingTableData.length - 1
              ].rowindex + 1,
        type: 1,
        name: "",
        format: "",
        requirements: "",
        quantity: "",
        occations: "",
        sample: "",
        uploadSampleFile: "",
        uploadSampleFileName: "",
        uploadSampleLoading: false,
        emptySample: "",
        uploadEmptySampleFile: "",
        uploadEmptySampleFileName: "",
        uploadEmptySampleLoading: false,
      };
      this.hostingTableForm.hostingTableData.push(obj);
    },
    delHostingRow() {
      for (let i = this.hostingTableForm.hostingTableData.length; i > 0; i--) {
        for (let j = 0; j < this.$refs.hostingTable.selection.length; j++) {
          if (
            this.hostingTableForm.hostingTableData[i - 1].rowindex ==
            this.$refs.hostingTable.selection[j].rowindex
          ) {
            this.hostingTableForm.hostingTableData.splice(i - 1, 1);
            break;
          }
        }
      }
    },
    returnServiceNetwork() {
      this.$router.push("/ServiceBranch");
    },
    addprovidentfundRatio() {
      this.basicform.accRatio.push({
        person: "",
        enterprise: "",
      });
    },
    removeProvidentfundRatio(item) {
      var index = this.basicform.accRatio.indexOf(item);
      if (index !== -1) {
        this.basicform.accRatio.splice(index, 1);
      }
    },
    uploadSampleFile($event, scope, tableName) {
      this.EssentialsUpload($event.target.files, scope, "sample", tableName);
    },
    uploadEmptySampleFile($event, scope, tableName) {
      this.EssentialsUpload(
        $event.target.files,
        scope,
        "emptySample",
        tableName
      );
    },
    submitSource() {
      // 提交
      let validRes = [];
      this.$refs.basicform.validate((valid) => {
        validRes.push(valid);
      });
      this.$refs.createTableForm.validate((valid) => {
        validRes.push(valid);
      });
      this.$refs.hostingTableForm.validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      if (res) {
        this.confirmSubmit();
      } else {
        console.log("error submit!!");
      }
    },
    async confirmSubmit() {
      let bf = this.basicform;
      let ensentialFiles = this.createTableForm.createTableData.concat(
        this.hostingTableForm.hostingTableData
      );
      if (ensentialFiles == [] || ensentialFiles.length <= 0) {
        this.$message.warning("请添加开户所需资料和托管所需资料");
        return;
      } else if (
        this.createTableForm.createTableData == [] ||
        this.createTableForm.createTableData.length <= 0
      ) {
        this.$message.warning("请添加开户所需资料");
        return;
      } else if (
        this.hostingTableForm.hostingTableData == [] ||
        this.hostingTableForm.hostingTableData.length <= 0
      ) {
        this.$message.warning("请添加托管所需资料");
        return;
      }
      let ccc = false;
      console.log(this.createTableForm.createTableData);
      console.log(this.hostingTableForm.hostingTableData);
      this.createTableForm.createTableData.forEach(x => {
        if(x.format =="表格或协议类" && (x.emptySample == ""||x.emptySample == ""||x.sample == undefined||x.sample == undefined)){
          ccc = true;
          this.break;
        }
      }); 
      this.hostingTableForm.hostingTableData.forEach(x => {
        if(x.format =="表格或协议类" && (x.emptySample == ""||x.emptySample == ""||x.sample == undefined||x.sample == undefined)){
          ccc = true;
          this.break;
        }
      }); 
      if(ccc){
        this.$message.warning("资料类型为【表格或协议类】时，资料样本和空表必须上传");
        return;
      }
      if(this.consigneeAddressInfo.address == null || this.consigneeAddressInfo.address == ""){
        this.$message.warning("请填写邮寄地址！");
        return;
      }
      if(this.consigneeAddressInfo.receiver == null || this.consigneeAddressInfo.receiver == ""){
        this.$message.warning("请填写收件人！");
        return;
      }
      if(this.consigneeAddressInfo.phoneNumber == null || this.consigneeAddressInfo.phoneNumber == ""){
        this.$message.warning("请填写收件人联系电话！");
        return;
      }
      if(this.basicform.socialOfflineOperation == null || this.basicform.socialOfflineOperation == 0){
        this.$message.warning("请选择社保补缴的操作方式！");
        return;
      }
      if(this.basicform.accOfflineOperation == null || this.basicform.accOfflineOperation == 0){
        this.$message.warning("请选择公积金补缴的操作方式！");
        return;
      }
      bf.consigneeAddress = "邮寄地址："+this.consigneeAddressInfo.address +" 收件人："+this.consigneeAddressInfo.receiver +" 收件人联系电话："+this.consigneeAddressInfo.phoneNumber+" 备注："+this.consigneeAddressInfo.remark;
      let params = {
        id: 0,
        sid: bf.sid,
        name: bf.name,
        consigneeAddress: bf.consigneeAddress,
        branchRepresentative: bf.branchRepresentative,
        isPersonalEssential: bf.isPersonalEssential,
        socialEssential: bf.socialEssential,
        accEssential: bf.accEssential,
        auditStatus: this.BranchDetail.auditStatus, // 审核状态 0 待审核   1 审核成功   2 审核拒绝    3 未提交
        status: this.BranchDetail.status, // 是否启用 0 未启用 1 启用
        branchDistrict: bf.branchDistrict,
        accRatio: JSON.stringify(bf.accRatio),
        remark: bf.remark,
        ensentialFiles: ensentialFiles,
        socialOfflineOperation:bf.socialOfflineOperation,
        accOfflineOperation:bf.accOfflineOperation,
      };
      console.log(params);
      try {
        let res = await this.$api.branch.UpdateBranch(params);
        if (res) {
          this.$message({
            message: "编辑网点成功！",
            type: "success",
          });
          this.returnServiceNetwork();
        } else {
          if(params.auditStatus == 1 || params.auditStatus == 2)
          {
            this.$message.error("一个网点未提交的状态只有一条数据 或 数据保存失败");
          }else{
            this.$message.error("编辑网点失败！");
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async EssentialsUpload(files, scope, type, tableName) {
      // 开户/托管表 --> 上传文件（sample/emptySample）api
      if (files[0].size / 1000 > 10240) {
        this.$message({
          message: "文件不可大于10mb！",
          type: "warning",
        });
        return;
      }
      let params = new FormData();
      params.append("file", files[0]);
      let filename ='';
      if (type == "sample") {
        if (tableName == "开户") {
          this.createTableForm.createTableData[scope.$index].uploadSampleLoading = true;
          filename = this.createTableForm.createTableData[scope.$index].name;

        } else {
          this.hostingTableForm.hostingTableData[scope.$index].uploadSampleLoading = true;
          filename = this.hostingTableForm.hostingTableData[scope.$index].name;
        }
      } else {
        if (tableName == "开户") {
          this.createTableForm.createTableData[scope.$index].uploadEmptySampleLoading = true;
          filename = this.createTableForm.createTableData[scope.$index].name;
        } else {
          this.hostingTableForm.hostingTableData[scope.$index].uploadEmptySampleLoading = true;
          filename = this.hostingTableForm.hostingTableData[scope.$index].name;
        }
      }
      try {
        let res = await this.$api.branch.EssentialsUpload(params, filename);
        if (res) {
          if (type == "sample") {
            if (tableName == "开户") {
              this.createTableForm.createTableData[scope.$index].uploadSampleFile = files[0];
              this.createTableForm.createTableData[scope.$index].uploadSampleFileName =
                res.msg;
              this.createTableForm.createTableData[scope.$index].sample = res.data;
              this.createTableForm.createTableData[scope.$index].uploadSampleLoading = false;
            } else {
              this.hostingTableForm.hostingTableData[scope.$index].uploadSampleFile = files[0];
              this.hostingTableForm.hostingTableData[scope.$index].uploadSampleFileName =
                res.msg;
              this.hostingTableForm.hostingTableData[scope.$index].sample = res.data;
              this.hostingTableForm.hostingTableData[scope.$index].uploadSampleLoading = false;
            }
          } else {
            if (tableName == "开户") {
              this.createTableForm.createTableData[scope.$index].uploadEmptySampleFile =
                files[0];
              this.createTableForm.createTableData[scope.$index].uploadEmptySampleFileName =
                res.msg;
              this.createTableForm.createTableData[scope.$index].emptySample = res.data;
              this.createTableForm.createTableData[
                scope.$index
              ].uploadEmptySampleLoading = false;
            } else {
              this.hostingTableForm.hostingTableData[scope.$index].uploadEmptySampleFile =
                files[0];
              this.hostingTableForm.hostingTableData[scope.$index].uploadEmptySampleFileName =
                res.msg;
              this.hostingTableForm.hostingTableData[scope.$index].emptySample = res.data;
              this.hostingTableForm.hostingTableData[
                scope.$index
              ].uploadEmptySampleLoading = false;
            }
          }
          this.$message({
            message: "上传文件成功！",
            type: "success",
          });
        } else {
          this.$message.error("上传文件失败！");
        }
      } catch (e) {
        console.log(e);
      }
    },
    createBranchName(districtName) {
      this.BranchesCount(districtName);
    },
    async BranchesCount(districtName) {
      try {
        let res = await this.$api.branch.BranchesCount(districtName);
        if (res != undefined) {
          let num = res + 1;
          this.basicform.name = this.basicform.branchDistrict + num + "号网点";
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getDistrict() {
      try {
        let res = await this.$api.policy.getDistrict({});
        if (res.code == 200) {
          this.branchDistrictList = res.data;
        } else {
          this.$message.error("地区列表加载失败！");
        }
      } catch (e) {
        console.log(e);
      }
    },
    manageData() {
      let dt = this.BranchDetail;
      console.log(dt.id);
      this.basicform = {
        sid: dt.id,
        branchDistrict: dt.branchDistrict,
        name: dt.name,
        branchRepresentative: dt.branchRepresentative,
        isPersonalEssential: dt.isPersonalEssential,
        consigneeAddress: dt.consigneeAddress,
        socialEssential: dt.socialEssential,
        accEssential: dt.accEssential,
        socialOfflineOperation:dt.socialOfflineOperation,
        accOfflineOperation:dt.accOfflineOperation,
        remark: dt.remark,
        accRatio: JSON.parse(dt.accRatio),
      };
      if(this.BranchDetail.auditStatus==3){
        this.isDis = false;
      }else{
        this.isDis = true;
      }
      if(this.basicform.branchDistrict!=null&&this.basicform.branchDistrict!=undefined){
        this.districtCNArr = this.getDistrictCNArr(this.basicform.branchDistrict);
      }
      console.log(this.basicform);
      if(this.basicform.consigneeAddress != null && this.basicform.consigneeAddress != ""){
        if(this.basicform.consigneeAddress.indexOf('邮寄地址：')>-1 && this.basicform.consigneeAddress.indexOf('收件人：')>-1 && this.basicform.consigneeAddress.indexOf('收件人联系电话：')>-1&& this.basicform.consigneeAddress.indexOf('邮寄地址：')< this.basicform.consigneeAddress.indexOf('收件人：')&& this.basicform.consigneeAddress.indexOf('收件人：')< this.basicform.consigneeAddress.indexOf('收件人联系电话：')){
          if(this.basicform.consigneeAddress.indexOf('邮寄地址：')>-1 ){
            this.consigneeAddressInfo.address = this.basicform.consigneeAddress.substring(0,this.basicform.consigneeAddress.indexOf('收件人：'));
            this.consigneeAddressInfo.address = this.consigneeAddressInfo.address.replace('邮寄地址：','');
          }
          if(this.basicform.consigneeAddress.indexOf('收件人：')>-1 ){
            this.consigneeAddressInfo.receiver = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('收件人：'),this.basicform.consigneeAddress.indexOf('收件人联系电话：') );
            this.consigneeAddressInfo.receiver = this.consigneeAddressInfo.receiver.replace("收件人：","");
          }
           if(this.basicform.consigneeAddress.indexOf('收件人：')>-1 ){
            this.consigneeAddressInfo.phoneNumber = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('收件人联系电话：'),this.basicform.consigneeAddress.indexOf('备注：') );
            this.consigneeAddressInfo.phoneNumber = this.consigneeAddressInfo.phoneNumber.replace("收件人联系电话：","");
          }
          if(this.basicform.consigneeAddress.indexOf('备注：')>-1 ){
            this.consigneeAddressInfo.remark = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('备注：'),this.basicform.consigneeAddress.length);
            this.consigneeAddressInfo.remark = this.consigneeAddressInfo.remark.replace("备注：","");
          }
        }else{
          this.consigneeAddressInfo.remark = this.basicform.consigneeAddress
        }
        
      }
      let createTableData = [];
      let hostingTableData = [];
      dt.ensentialFiles.forEach((item) => {
        if (item.type == 0) {
          createTableData.push(item);
        } else {
          hostingTableData.push(item);
        }
      });
      createTableData.forEach((item, index) => {
        item.rowindex = index;
      });
      hostingTableData.forEach((item, index) => {
        item.rowindex = index;
      });
      this.createTableForm.createTableData = createTableData;
      this.hostingTableForm.hostingTableData = hostingTableData;
    },
    getDistrictCNArr(distStr){
      let distCNArr = [];
      let distCodeArr = [];
      if(distStr.indexOf("市辖区")>-1 && distStr.indexOf("省")<0){
        distCNArr[0] = distStr.substring(0,distStr.indexOf("市辖区"));
        distCNArr[1] = "市辖区";
        distCNArr[2] = distStr.substring(distStr.indexOf("市辖区"),distStr.length).replace("市辖区","");
      }else if(distStr.indexOf("特别行政区")>-1){
        distCNArr[0] = distStr.substring(0,distStr.indexOf("特别行政区")) + "特别行政区";
        distCNArr[1] = distStr.substring(distStr.indexOf("特别行政区"),distStr.length).replace("特别行政区","");
      }else{
        let provinceIndex = 0;
        let cityIndex = 0;
        if(distStr.indexOf("自治区")>-1){
          provinceIndex = distStr.indexOf("自治区")+3;
          if(distStr.indexOf("盟")>-1){
            cityIndex = distStr.indexOf("盟")+1;
          }else{
            cityIndex = distStr.indexOf("市")+1;
          }
        }else{
          provinceIndex = distStr.indexOf("省")+1;
          if(distStr.indexOf("自治州")>-1){
            cityIndex = distStr.indexOf("自治州")+3;
          }else if(distStr.indexOf("地区")>-1){
            cityIndex = distStr.indexOf("地区")+2;
          }else if(distStr.indexOf("行政区划")>-1){
            cityIndex = distStr.indexOf("行政区划")+4;
          }else{
            cityIndex = distStr.indexOf("市")+1;
          }
        }
        distCNArr[0] = distStr.substring(0,provinceIndex);
        distCNArr[1] = distStr.substring(provinceIndex,cityIndex);
        distCNArr[2] = distStr.substring(cityIndex,distStr.length);
      }
      for(let i = 0;i< this.districtOptions.length; i++){
        if(this.districtOptions[i].areaName == distCNArr[0]){
          distCodeArr[0] = this.districtOptions[i].code;
          for(var j = 0; j < this.districtOptions[i].childs.length;j++){
            if(this.districtOptions[i].childs[j].areaName == distCNArr[1]){
              distCodeArr[1] = this.districtOptions[i].childs[j].code;
              for(var k = 0; k < this.districtOptions[i].childs[j].childs.length;k++){
                 if(this.districtOptions[i].childs[j].childs[k].areaName == distCNArr[2]){
                   distCodeArr[2] = this.districtOptions[i].childs[j].childs[k].code;
                 }
              }
            }
          }
        }
      }
      this.basicform.branchDistrictCodes = distCodeArr;
      console.log(this.basicform.branchDistrictCodes);
      return;
    },
    async getDetail() {
      let bid = this.$route.params.bid;
      try {
        let res = await this.$api.branch.BranchDetail(bid);
        if (res) {
          this.BranchDetail = res;
          this.manageData();
        } else {
          this.$message.error("获取详情失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("未找到该网点地区，请先前往地区配置！");
      }
    },
    getAreaCascader(){
      this.$api.OperateDeadline.GetCascaderData().then(res => {
        this.districtOptions =  res;
        this.getDetail();
      })
    }
  },
  mounted() {
    this.getAreaCascader();
    this.getDistrict();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#AddNetwork {
  padding: 20px;
}
.info-filling {
  background: #fff;
  padding: 20px 20px;
}
.info-filling .title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-family: 微软雅黑;
  display: inline-block;
  margin-right: 16px;
}
.basicform {
  width: 800px;
  padding: 20px 16px;
}
.form-inline-group {
  display: inline-block;
  margin-left: 30px;
  color: #606266;
}
.form-inline-group .el-form-item {
  display: inline-block;
  margin-bottom: 0;
}
.form-inline-group .text {
  margin: 0 10px;
}
.filling-btn-group {
  display: inline-block;
}
#AddNetwork .el-table {
  margin: 20px 0;
}
.bottom-btn-group {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.addButton {
  width: 100%;
}
</style>
