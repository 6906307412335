import api from '../index'

export default {
    GetAreaData(params){  //获取地区下拉选择数据
        return api.get('/operateDeadline/Select', params);
    },
    GetAll(params){  //获取城市配置操作截止日列表
        return api.get('/operateDeadline', params);
    },
    AddAreaAndSocialOperateDeadline(params){  //新增地区和社保操作截止日
        return api.post('/operateDeadline/Add', params);
    },
    EditSocialOperateDeadline(params){  //编辑社保操作截止日
        return api.post('/operateDeadline/Edit', params);
    },
    DeleteAreaAndSocialOperateDeadline(params){ //软删除社保操作截止日和地区
        return api.post('/operateDeadline/Del', params);
    },
    GetCascaderData(params){ //获取地区三级联动数据
        return api.get('/operateDeadline/Cascader',params);
    }
}