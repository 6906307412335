import api from '../index'

export default {
    accounts(params) {
        return api.get('/accounts', params)
    },
    count() {
        return api.get('/accounts/count')
    },
    pesonals(params) {
        return api.get('/accounts/' + params.id + '/pesonals')
    },
    logistics(params) {
        return api.get('/accounts/' + params.id + '/logistics')
    },
    logistic(params) {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return api.post('/accounts/logistic', params, headers)
    },
    status(params) {
        return api.post('/accounts/status', params)
    },
    export(params) { // 导出开户人员数据
        return api.postfile('/accounts/export', params)
    },

    getUkeys(id) {  // 获取开户单位Ukey数据
         return api.get('/accounts/' + id + '/ukeys');
    },

    postUkeys(id, params) { // 提交开户单位Ukey数据
        return api.post('/accounts/' + id + '/ukeys', params);
        // return api.post('https://localhost:44350/accounts/' + id + '/ukeys', params)
    }

}