import api from '../index'

export default {
    DoPendingNotice(params) {
        /*
        params = {
          categoryIds: [], // 多选的险种Id
          accountId: "", // 
          materialApplyType: -1, // 申报类型 0-社保新增、1-社保续保、2-社保停保、3-社保变更、4-社保补缴、5-社保退保 6-公积金新增、7-公积金调入、8-公积金封存、9-公积金变更、10-公积金保补缴、11-公积金退保
          reason: "", //异常反馈理由
          deadLine: "2020-12-03 16:18:23", // 操作截止日
          description: "", // 异常情况描述
          userName: "", // 操作人姓名
          concatFieldDossiers: [] //资料选择
        }
        */
        return api.post(process.env.VUE_APP_PENDINGNOTICE_BASIC_URL+'Pendingnotice/DoTsPendingNotice', params)
    },
    SeenPendingNotice(ids) { 
        // id是 orderfeedbackId主键
        return api.get(process.env.VUE_APP_PENDINGNOTICE_BASIC_URL+'Pendingnotice/infoBulk?categoryIds='+ ids)
    }
}
