<template>
  <div id="AccountDetail">
    <div class="page-header">
      <p class="page-path">
        业务管理<span class="slashline">/</span>托管账户管理<span
          class="slashline"
          >/</span
        >单位详情
      </p>
      <p class="page-title">单位详情</p>
    </div>
    <div class="operation-pannel">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="basic-info">单位基本信息</div>
          </template>
          <el-form
            :model="basicInfoForm"
            label-position="top"
            ref="basicInfoForm"
            class="basic-info-form"
          >
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="单位名称" prop="enterpriseName">
                  <el-input
                    v-model="basicInfoForm.enterpriseName"
                    placeholder="请输入"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位编号" prop="enterpriseId">
                  <el-input
                    v-model="basicInfoForm.enterpriseId"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属客户" prop="customerId">
                  <el-select
                    v-model="basicInfoForm.customerId"
                    placeholder="请选择"
                    :disabled="true"
                  >                  
                    <el-option label="ERS" value="KH100010"></el-option>
                    <el-option label="FAR" value="KH100012"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="托管类型" prop="entrustType">
                  <el-select
                    v-model="basicInfoForm.entrustType"
                    placeholder="请选择"
                    multiple
                    :disabled="true"
                  >
                    <el-option label="社保" :value="0"></el-option>
                    <el-option label="公积金" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账户状态" prop="accountStatus">
                  <el-select
                    v-model="basicInfoForm.accountStatus"
                    placeholder="请选择"
                    :disabled="true"
                  >
                    <el-option label="有效" :value="0"></el-option>
                    <el-option label="无效" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="托管网点" prop="branchId">
                  <el-select
                    v-model="basicInfoForm.branchId"
                    placeholder="请选择"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in branchList"
                      :label="item.lable"
                      :value="item.value"
                      :key="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="开始托管时间" prop="beginTime">
                  <el-date-picker
                  filterable
                    :editable="false"
                    v-model="basicInfoForm.beginTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                    :disabled="true"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结束托管时间" prop="endTime">
                  <el-date-picker
                    :editable="false"
                    v-model="basicInfoForm.endTime"
                    type="month"
                    placeholder=""
                    :disabled="true"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="basic-info">托管资料</div>
          </template>
          <el-form
            :model="entrustForm"
            label-position="right"
            label-width="120px"
            ref="entrustForm"
            class="basic-info-form"
          >
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="附件资料：">
                  <el-button @click="filesToRar">下载资料</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="30"
              v-for="(item, index) in entrustForm.info"
              :key="index"
            >
              <el-col :span="8">
                <el-form-item label="Ukey：">
                  {{ item.ukey }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账号：">
                  {{ item.account }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="密码：">
                  {{ item.password }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="3" v-if="entrustDetail.entrustType == 0">
          <template slot="title">
            <div class="basic-info">社保信息</div>
          </template>
          <el-form
            :model="socialSecurityForm"
            label-position="right"
            label-width="180px"
            ref="socialSecurityForm"
            class="basic-info-form"
          >
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="社保出账日：">
                  <div class="form-inline-group">
                    <el-form-item prop="billMonth">
                      <el-select
                        v-model="socialSecurityForm.billMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="billDay">
                      <el-select
                        v-model="socialSecurityForm.billDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社保扣款日：" prop="deductDate">
                  <el-select
                    v-model="socialSecurityForm.deductDate"
                    placeholder="请选择"
                    :disabled="true"
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="增员截止日(社保)：">
                  <div class="form-inline-group">
                    <el-form-item prop="addDeadlineMonth">
                      <el-select
                        v-model="socialSecurityForm.addDeadlineMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="addDeadlineDay">
                      <el-select
                        v-model="socialSecurityForm.addDeadlineDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="减员截止日(社保)：">
                  <div class="form-inline-group">
                    <el-form-item prop="minusDeadlineMonth">
                      <el-select
                        v-model="socialSecurityForm.minusDeadlineMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="minusDeadlineDay">
                      <el-select
                        v-model="socialSecurityForm.minusDeadlineDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="3" v-if="entrustDetail.entrustType == 1">
          <template slot="title">
            <div class="basic-info">公积金政策</div>
          </template>
          <el-form
            :model="providentFundForm"
            label-position="right"
            label-width="180px"
            ref="providentFundForm"
            class="basic-info-form"
          >
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="公积金出账日：">
                  <div class="form-inline-group">
                    <el-form-item prop="billMonth">
                      <el-select
                        v-model="providentFundForm.billMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="billDay">
                      <el-select
                        v-model="providentFundForm.billDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公积金扣款日：" prop="deductDate">
                  <el-select
                    v-model="providentFundForm.deductDate"
                    placeholder="请选择"
                    :disabled="true"
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="增员截止日(公积金)：">
                  <div class="form-inline-group">
                    <el-form-item prop="addDeadlineMonth">
                      <el-select
                        v-model="providentFundForm.addDeadlineMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="addDeadlineDay">
                      <el-select
                        v-model="providentFundForm.addDeadlineDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="减员截止日(公积金)：">
                  <div class="form-inline-group">
                    <el-form-item prop="minusDeadlineMonth">
                      <el-select
                        v-model="providentFundForm.minusDeadlineMonth"
                        placeholder="请选择"
                        style="width: 104px"
                        :disabled="true"
                      >
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="minusDeadlineDay">
                      <el-select
                        v-model="providentFundForm.minusDeadlineDay"
                        placeholder="请选择"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="缴费比例：">
                  <div class="form-inline-group">
                    <el-form-item prop="enterprisePercent">
                      <el-input
                        v-model="providentFundForm.enterprisePercent"
                        placeholder="单位比例"
                        style="width: 104px"
                        :disabled="true"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="personPercent">
                      <el-input
                        v-model="providentFundForm.personPercent"
                        placeholder="个人比例"
                        style="width: 104px; margin-left: 4px"
                        :disabled="true"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="bottom-btn-group">
      <el-button @click="returnAccountList">返回</el-button>
      <el-button type="primary" @click="returnAccountList">确定</el-button>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import FileSaver from 'file-saver'
export default {
  name: "AccountDetail",
  components: {},
  data: function () {
    return {
      entrustDetail: "",
      activeNames: ["1", "2", "3", "4"],
      branchList: [], // 托管网点list
      basicInfoForm: {
        enterpriseName: "",
        enterpriseNum: "",
        customer: "",
        hostingType: [],
        accountStatus: 0,
        branch: "",
        beginTime: new Date(),
        endTime: "",
      },
      entrustForm: {
        // 托管资料
        info: [],
      },
      socialSecurityForm: {
        // 社保信息
        billMonth: "",
        billDay: "",
        deductDate: "",
        addDeadlineMonth: "",
        addDeadlineDay: "",
        minusDeadlineMonth: "",
        minusDeadlineDay: "",
      },
      providentFundForm: {
        // 社保信息
        billMonth: "",
        billDay: "",
        deductDate: "",
        addDeadlineMonth: "",
        addDeadlineDay: "",
        minusDeadlineMonth: "",
        minusDeadlineDay: "",
        enterprisePercent: "",
        personPercent: "",
      },
      fileUrl:[],
      fileName:'',
    };
  },
  methods: {
    returnAccountList() {
      this.$router.go(-1); 
      // this.$router.push({
      //   name: "HostingAccountList",
      // });
    },
    async getDetail() {
      let id = this.$route.params.id;
      try {
        let [detailData, branches] = await Promise.all([
          this.$api.entrustAccount.detail(id),
          this.$api.publicapi.branches(),
        ]);
        if(detailData.fileUrl.split(";").length > 0 && detailData.fileUrl != null && detailData.fileUrl != "" && detailData.fileUrl != "[]" && detailData.fileUrl != "string"){
          detailData.fileUrl.split(";").forEach(x => {
            if(x != null && x != ""){
                  let obj = {
                      fileUrl:x,
                      fileName:x.split("/")[x.split("/").length - 1]
                  }
                  if(this.fileUrl.length > 0){
                      this.fileUrl.forEach(element => {
                          if(element.fileName == obj.fileName){
                              let list = obj.fileName.split(".");
                              list[0] = list[0] + "-" + 1;
                              obj.fileName = list[0] + "." + list[1];
                              this.recursive(obj.fileName);
                              obj.fileName = this.fileName;
                          }
                      });
                  }
                  this.fileUrl.push(obj);
              }
          });
        }
        if (detailData && branches) {
          this.entrustDetail = detailData;
          this.branchList = branches;
          this.manageData();
        } else {
          this.$message.error("获取信息失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取信息失败！");
      }
    },
    recursive(fileName){
        this.fileUrl.forEach(element => {
            if(element.fileName == fileName){
                let firstList = fileName.split(".");
                let secondList = firstList[0].split("-");
                secondList[1] = parseInt(secondList[1]) + 1;
                firstList[0] = secondList[0] + "-" + secondList[1];
                fileName = firstList[0] + "." + firstList[1]; 
                this.recursive(fileName);
            }else{
                this.fileName = fileName;
            }
        });
    },
    manageData() {
      let od = this.entrustDetail;
      let entrustType = [];
      if (od.entrustType == 0) {
        // 社保
        entrustType = [0];
      } else if (od.entrustType == 1) {
        // 公积金
        entrustType = [1];
      } else if (od.entrustType == 2) {
        // 社保公积金
        entrustType = [0, 1];
      }
      this.basicInfoForm = {
        // 基本信息
        enterpriseName: od.enterpriseName,
        enterpriseId: od.enterpriseId,
        customerId: od.customerId,
        entrustType: entrustType,
        branchId: od.branchId,
        beginTime: od.beginTime,
        endTime: od.endtime,
        accountStatus: 0,
      };
      this.entrustForm.info = od.entrustUkeys; // 托管资料
      if (od.entrustType == 0) {
        if (od.entrustDate != null) {
          this.socialSecurityForm = od.entrustDate;
        }
      } else if (od.entrustType == 1) {
        if (od.entrustDate != null) {
          this.providentFundForm = od.entrustDate;
        }
      }
    },
    filesToRar() { //文件打包
        if(this.fileUrl.length < 1){
          this.$message.error("没有附件资料可下载！");
          return;
        }
        let zip = new JSZip();
        let cache = {};
        let promises = [];
        let fileName = '申报资料';
        this.title = '正在加载压缩文件';
        for (let item of this.fileUrl) {
            const promise= this.getImgArrayBuffer(item.fileUrl).then(data => {
                // 下载文件, 并存成ArrayBuffer对象(blob)
                zip.file(item.fileName, data, { binary: true }); // 逐个添加文件
                cache[item.fileName] = data;
            });
            promises.push(promise);
        }
        Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" }).then(content => {
                this.title = '正在压缩';
                // 生成二进制流
                FileSaver.saveAs(content, fileName); // 利用file-saver保存文件 自定义文件名
                this.title = '压缩完成';
            });
        }).catch(error =>{
            console.log(error);
            this.$message.error('文件压缩失败');
        });
    },
    getImgArrayBuffer(url){     //获取文件blob
        return new Promise((resolve, reject) => {
            //通过请求获取文件blob格式
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", url, true);
            xmlHttp.responseType = "blob";
            xmlHttp.onload = function () {
                if (this.status == 200) {
                    resolve(this.response);
                }else{
                    reject(this.status);
                }
            }
            xmlHttp.send();
        });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#AccountDetail {
  padding: 20px;
}

.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.form-inline-group .el-form-item {
  display: inline-block;
  margin-bottom: 0;
}
.bottom-btn-group {
  display: block;
  margin: 0 auto;
  text-align: center;
}
</style>
