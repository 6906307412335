import api from '../index'

export default {
  count() { // 托管账户列表统计
    return api.get('/entrust/accounts/count')
  },
  accounts(params) { // 获取托管账户列表
    return api.get('/entrust/accounts', params)
  },
  date(id) { // 获取操作截止日
    return api.get('/entrust/accounts/' + id + '/date')
  },
  editDate(params) { // 编辑操作截至日
    return api.post('/entrust/accounts/date', params)
  },
  detail(id) {
    return api.get('/entrust/accounts/' + id)
  },
  cancel(id) {
    return api.post('/entrust/accounts/' + id + '/cancel')
  },
  orderCount(enterpriseId, entrustType) {
    return api.post('/entrust/accounts/orderCount/' + enterpriseId + "/" + entrustType)
  },
  editData(id) {
    return api.get('/entrust/accounts/' + id + '/edit')
  },
  editDataModify(params) {
    return api.post('/entrust/accounts/' + params.id + '/edit', params.body)
  }
}