<!-- HTML -->
<template>
  <div id="personMove">
    <div class="page-header">
      <p class="page-path">
        数据统计<span class="slashline">/</span>人员异动统计-小库
      </p>
      <p class="page-title">人员异动统计-小库</p>
    </div>
    <div class="operation-pannel">
      <!-- 搜索 -->
      <el-form
        :model="filters"
        class="filters-form"
        label-width="100px"
        ref="filtersform"
        style="padding: 20px 0"
      >
        <el-row>
          <el-col>
            <el-date-picker
              v-model="filters.months"
              type="monthrange"
              range-separator="-"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              placeholder="请选择实际月份范围"
              value-format="yyyy-MM"
              style="max-width: 375px; margin-bottom: 5px"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row style="display: flex">
          <el-col>
            <el-input
              v-model="filters.enterpriseName"
              placeholder="请输入单位信息"
              style="max-width: 220px"
              auto-complete="false"
            ></el-input>
          </el-col>
          <el-col>
            <el-input
              v-model="filters.branch"
              placeholder="请输入网点信息"
              style="max-width: 220px"
              auto-complete="false"
            ></el-input>
          </el-col>
          <el-col>
            <el-select
              v-model="filters.customerId"
              placeholder="请选择客户"
              style="max-width: 220px"
              auto-complete="false"
              clearable
            >
              <el-option
                v-for="item in customerList"
                :label="item.lable"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col>
            <el-select
              v-model="filters.generateType"
              placeholder="请选择生成类型"
              style="max-width: 220px"
              auto-complete="false"
              clearable
            >
              <el-option label="手动生成" value="1"></el-option>
              <el-option label="自动生成" value="2"></el-option>
            </el-select>
          </el-col>
          <el-col>
            <el-select
              v-model="filters.diffStatus"
              placeholder="请选择差值状态"
              style="max-width: 220px"
              auto-complete="false"
              clearable
            >
              <el-option label="平" value="0"></el-option>
              <el-option label="正" value="1"></el-option>
              <el-option label="负" value="2"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form>
      <!-- 按钮 -->
      <div style="text-align: center; margin-bottom: 20px">
        <el-button type="success" :loading="loading1" @click="searchList"
          >查询</el-button
        >
        <el-button type="primary" :loading="loading2" @click="reset"
          >重置</el-button
        >
        <el-button type="warning" :loading="loading3" @click="exportData"
          >导出</el-button
        >
        <el-button type="danger" :loading="loading4" @click="showGenerate"
          >手动生成</el-button
        >
      </div>
      <!-- 表格 -->
      <el-table
        max-height="500"
        :data="tableData"
        show-summary
        border
        stripe
        style="margin-bottom: 20px"
        @sort-change="handleSortChange"
      >
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod"
          width="50"
        />
        <el-table-column
          prop="month"
          label="实际月份"
          sortable="custom"
          width="120"
        />
        <el-table-column prop="enterpriseName" label="所属单位" width="200" />
        <el-table-column prop="branch" label="托管网点" width="300" />
        <el-table-column prop="customer" label="所属客户" width="100" />
        <el-table-column
          prop="lastMonthInsuredNum"
          sortable="custom"
          label="上月在保人数"
          width="130"
        />
        <el-table-column
          prop="addNum"
          sortable="custom"
          label="流进人数"
          width="130"
        >
          <template slot-scope="scope">
            <span
              style="lighting-color: green; color: green"
              @click="showDetail(1, scope.row.summaryCode)"
              >{{ scope.row.addNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="lostNum"
          sortable="custom"
          label="流出人数"
          width="130"
        >
          <template slot-scope="scope">
            <span
              style="lighting-color: red; color: red"
              @click="showDetail(2, scope.row.summaryCode)"
              >{{ scope.row.lostNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="currentMonthInsuredNum"
          sortable="custom"
          label="本月在保人数"
          width="130"
        />
        <el-table-column
          prop="diffNum"
          sortable="custom"
          label="差数"
          width="130"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.diffNum > 0" style="color: green">{{
              scope.row.diffNum
            }}</span>
            <span v-else-if="scope.row.diffNum < 0" style="color: red">{{
              scope.row.diffNum
            }}</span>
            <span v-else>{{ scope.row.diffNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="generateType" label="生成类型" width="80" />
        <el-table-column prop="generateDate" label="生成时间" width="150" />
        <el-table-column prop="remark" label="备注" />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000, 2000]"
        :current-page="currentPage"
        layout="jumper, prev, pager, next, sizes, total"
      >
      </el-pagination>
      <!-- 弹窗 -->
      <el-dialog
        :title="detail.title"
        width="600px"
        h
        :visible.sync="detail.dialog"
        @close="detailClose"
      >
        <el-form
          :model="detail.filters"
          class="filters-form"
          style="width: 250px; margin: 0 40px; float: left"
        >
          <el-row :gutter="0">
            <el-col :span="3">
              <el-input
                v-model="detail.filters.idCardOrName"
                placeholder="请输入姓名/证件号码"
                style="width: 300px"
                auto-complete="false"
              ></el-input>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮 -->
        <div style="text-align: center; margin-bottom: 20px">
          <el-button
            type="success"
            :loading="detail.loading1"
            @click="searchDetail"
            >筛选</el-button
          >
          <el-button
            type="primary"
            :loading="detail.loading2"
            @click="detailReset"
            >重置</el-button
          >
        </div>
        <el-table
          max-height="400"
          width="700"
          :data="detail.tableData"
          style="margin-bottom: 20px"
        >
          <el-table-column
            label="序号"
            type="index"
            :index="detail.indexMethod"
            width="100"
          />
          <el-table-column prop="name" label="姓名" width="200" />
          <el-table-column prop="idCard" label="证件号码" width="250" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @size-change="detailHandleSizeChange"
          @current-change="detailHandleCurrentChange"
          :total="detail.totalCount"
          :page-size="detail.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000, 2000]"
          :current-page="detail.currentPage"
          layout="jumper, prev, pager, next, sizes, total"
        >
        </el-pagination>
      </el-dialog>
      <el-dialog
        title="信息"
        width="600px"
        h
        :visible.sync="generate.dialog"
        @close="generateClose"
      >
        <el-form
          :model="generate.filters"
          class="filters-form"
          label-width="110px"
          style="width: 500px; margin: 0 40px"
        >
          <el-row :gutter="0">
            <el-col>
              <el-form-item label="* 实际月份" prop="name">
                <el-date-picker
                  :clearable="false"
                  :editable="false"
                  v-model="generate.filters.month"
                  type="month"
                  placeholder="请选择实际月份"
                  value-format="yyyy-MM"
                  style="width: 300px"
                  auto-complete="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col>
              <el-form-item label="网点" prop="name">
                <el-select
                  :filterable="true"
                  :clearable="true"
                  v-model="generate.filters.branchId"
                  placeholder="-请选择网点-"
                  style="width: 300px"
                  auto-complete="false"
                >
                  <el-option
                    v-for="item in generate.branchList"
                    :label="item.lable"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col>
              <el-form-item label="所属客户" prop="name">
                <el-select
                  :filterable="true"
                  :clearable="true"
                  v-model="generate.filters.customerId"
                  placeholder="-请选择客户-"
                  style="width: 300px"
                  auto-complete="false"
                >
                  <el-option
                    v-for="item in customerList"
                    :label="item.lable"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col>
              <el-form-item label="单位" prop="name">
                <el-select
                  :filterable="true"
                  :clearable="true"
                  v-model="generate.filters.enterpriseId"
                  placeholder="-请选择单位-"
                  style="width: 300px"
                  auto-complete="false"
                >
                  <el-option
                    v-for="item in generate.enterpriseList"
                    :label="item.lable"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮 -->
        <div style="text-align: center; margin-bottom: 20px">
          <el-button
            type="success"
            :loading="generate.loading1"
            @click="generateData"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<!-- Script -->
<script>
export default {
  data () {
    return {
      indexMethod: 1,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      filters: {
        months: [],
        startMonth: '',
        endMonth: '',
        enterpriseName: '',
        branch: '',
        customerId: '',
        generateType: '',
        diffStatus: ''
      },
      tableData: [],
      customerList: [],
      sortBy: null,
      sortOrder: 0,
      totalCount: 0,
      currentPage: 1,
      pageSize: 10,
      detail: {
        indexMethod: 1,
        loading1: false,
        loading2: false,
        title: '',
        dialog: false,
        tableData: [],
        filters: {
          moveType: 0,
          code: '',
          idCardOrName: ''
        },
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      generate: {
        open: 0,
        loading1: false,
        dialog: false,
        filters: {
          month: '',
          branchId: '',
          customerId: '',
          enterpriseId: ''
        },
        branchList: [],
        baseEnterpriseList: [],
        enterpriseList: []
      }
    }
  },
  created () {
    this.initMonth()
    this.getSelects1()
    this.searchList()
  },
  watch: {
    'filters.months' (newValue) {
      this.filters.startMonth = newValue == null ? '' : newValue[0]
      this.filters.endMonth = newValue == null ? '' : newValue[1]
    },
    'detail.filters.moveType' (newValue) {
      this.detail.title = newValue == 1 ? '流进人数弹窗' : '流出人数弹窗'
    },
    'generate.dialog' () {
      if (this.generate.open == 0) {
        this.getSelects2()
        this.generate.open = 1
      }
    },
    'generate.filters.branchId' (newValue, oldValue) {
      if (newValue != oldValue) {
        this.generate.filters.enterpriseId = ''
        this.generate.enterpriseList = []
        for (var enterprise of this.generate.baseEnterpriseList) {
          var flag1 = true
          var flag2 = true
          let customerId = this.generate.filters.customerId
          if (
            customerId != null &&
            customerId != '' &&
            customerId != enterprise.customerId
          ) {
            flag1 = false
          }
          if (
            newValue != null &&
            newValue != '' &&
            newValue != enterprise.branchId
          ) {
            flag2 = false
          }
          if (flag1 && flag2) {
            this.generate.enterpriseList.push(enterprise)
          }
        }
      }
    },
    'generate.filters.customerId' (newValue, oldValue) {
      if (newValue != oldValue) {
        this.generate.filters.enterpriseId = ''
        this.generate.enterpriseList = []
        for (var enterprise of this.generate.baseEnterpriseList) {
          var flag1 = true
          var flag2 = true
          let branchId = this.generate.filters.branchId
          if (
            branchId != null &&
            branchId != '' &&
            branchId != enterprise.branchId
          ) {
            flag1 = false
          }
          if (
            newValue != null &&
            newValue != '' &&
            newValue != enterprise.customerId
          ) {
            flag2 = false
          }
          if (flag1 && flag2) {
            this.generate.enterpriseList.push(enterprise)
          }
        }
      }
    }
  },
  methods: {
    async getSelects1 () {
      this.customerList = await this.$api.publicapi.customers()
    },
    async getSelects2 () {
      this.generate.branchList = await this.$api.publicapi.branches()
      const enterprises = await this.$api.publicapi.enterprises_v2()
      this.generate.baseEnterpriseList = enterprises
      this.generate.enterpriseList = JSON.parse(JSON.stringify(enterprises))
    },
    // 主页面
    searchList () {
      this.currentPage = 1
      this.getList()
    },
    async getList () {
      let listParams = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startMonth: this.filters.startMonth,
        endMonth: this.filters.endMonth,
        enterpriseName: this.filters.enterpriseName,
        branch: this.filters.branch,
        customerId:
          this.filters.customerId == '' ? '-1' : this.filters.customerId,
        generateType:
          this.filters.generateType == '' ? '-1' : this.filters.generateType,
        diffStatus:
          this.filters.diffStatus == '' ? '-1' : this.filters.diffStatus,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder
      }
      try {
        this.loading1 = true
        let listData = await this.$api.personMove.list(listParams)
        this.totalCount = listData.totalCount
        this.tableData = listData.item
        this.loading1 = false
      } catch (err) {
        console.log(err)
        this.loading1 = false
      }
    },
    reset () {
      this.loading2 = true
      location.reload()
    },
    handleSizeChange (pageSize) {
      // 每页条数改变
      this.pageSize = pageSize
      this.getList()
    },
    handleCurrentChange (currentPage) {
      // 当前页码改变
      this.currentPage = currentPage
      this.getList()
    },
    exportData () {
      this.$confirm('确认导出下面表中的所有数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading3 = true
          let listParams = {
            startMonth: this.filters.startMonth,
            endMonth: this.filters.endMonth,
            enterpriseName: this.filters.enterpriseName,
            branch: this.filters.branch,
            customerId:
              this.filters.customerId == '' ? '-1' : this.filters.customerId,
            generateType:
              this.filters.generateType == ''
                ? '-1'
                : this.filters.generateType,
            diffStatus:
              this.filters.diffStatus == '' ? '-1' : this.filters.diffStatus
          }
          this.exportDis = true
          this.$api.personMove
            .export(listParams)
            .then(response => {
              let fileName =
                '小库托管-人员异动统计导出' +
                new Date().toLocaleString('en-GB') +
                '.zip'
              this.$downloadByFileBytes(response, fileName)
              this.exportDis = false
              this.loading3 = false
            })
            .catch(() => {
              this.$message.error(
                '操作失败,发生异常,请尝试重新登录,或者联系管理员!'
              )
              this.exportDis = false
              this.loading3 = false
            })
        })
        .catch(() => {})
    },
    handleSortChange (obj) {
      if (obj != undefined && obj != null) {
        this.sortBy = obj.order != null ? obj.prop : null
        this.sortOrder = obj.order == 'ascending' ? 1 : -1
        this.getList()
      }
    },
    // 详情页面
    showDetail (moveType, code) {
      this.detail.currentPage = 1
      this.detail.pageSize = 10
      this.detail.filters.moveType = moveType
      this.detail.filters.code = code
      this.detail.dialog = true
      this.getDetail()
    },
    searchDetail () {
      this.detail.currentPage = 1
      this.getDetail()
    },
    async getDetail () {
      let listParams = {
        currentPage: this.detail.currentPage,
        pageSize: this.detail.pageSize,
        idCardOrName: this.detail.filters.idCardOrName
      }
      try {
        this.detail.loading1 = true
        let detailData = await this.$api.personMove.detail(
          this.detail.filters.code,
          this.detail.filters.moveType,
          listParams
        )
        this.detail.totalCount = detailData.totalCount
        this.detail.tableData = detailData.item
        this.detail.loading1 = false
      } catch (err) {
        console.log(err)
        this.detail.loading1 = false
      }
    },
    detailHandleSizeChange (pageSize) {
      // 每页条数改变
      this.detail.pageSize = pageSize
      this.getDetail()
    },
    detailHandleCurrentChange (currentPage) {
      // 当前页码改变
      this.detail.currentPage = currentPage
      this.getDetail()
    },
    detailReset () {
      this.detail.filters.idCardOrName = ''
      this.detail.currentPage = 1
      this.detail.pageSize = 10
      this.getDetail()
    },
    detailClose () {
      this.detail.tableData = []
      this.detail.filters.moveType = 0
      this.detail.filters.code = ''
      this.detail.filters.idCardOrName = ''
      this.detail.title = ''
    },
    showGenerate () {
      this.generate.dialog = true
    },
    initMonth () {
      let date = new Date()
      // let month = date.getFullYear() + "-" + (date.getMonth() + 1);
      // 月份前补0
      let month =
        date.getFullYear() + '-' + (date.getMonth() + 1 + '').padStart(2, '0') //我加了之后接口报错了
      this.filters.months[0] = month
      this.filters.months[1] = month
      this.filters.startMonth = month
      this.filters.endMonth = month
      this.generate.filters.month = month
    },
    generateClose () {
      this.initMonth()
      this.generate.filters.branchId = ''
      this.generate.filters.customerId = ''
      this.generate.filters.enterpriseId = ''
      this.generate.enterpriseList = JSON.parse(
        JSON.stringify(this.generate.baseEnterpriseList)
      )
    },
    generateData () {
      this.$confirm('确认生成相关数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.generate.loading1 = true
          let listParams = {
            generateMonth: this.generate.filters.month,
            branchId: this.generate.filters.branchId,
            customerId: this.generate.filters.customerId,
            enterpriseId: this.generate.filters.enterpriseId
          }
          let detailData = await this.generateRequest(listParams)
          this.$confirm(detailData, '提示', {
            confirmButtonText: '确定',
            type: 'success',
            showCancelButton: false
          }).then(() => {
            this.reset()
          })
        })
        .catch(() => {})
    },
    async generateRequest (listParams) {
      return await this.$api.personMove.generate(listParams)
    }
  }
}
</script>

<!-- CSS -->
<style src="@/assets/public.css"></style>
<style scoped>
#InsuredEmployee {
  padding: 20px;
}

#InsuredEmployee .operation-pannel {
  padding-top: 0;
}

.mailDetailInfo .eachMailInfo {
  border-bottom: 1px solid #ededed;
}

.eachMailInfo + .eachMailInfo {
  margin-top: 30px;
}

.mail-info {
  line-height: 20px;
  margin-bottom: 16px;
}

.mailDetailInfo {
  max-height: 500px;
  overflow-y: auto;
}

.delUploadFileIcon {
  margin-left: 16px;
}

.uploadTips {
  color: #888;
}
</style>
