import api from '../index'

export default {
    // 列表
    list(params) {
        return api.get('/statistics/personMove', params)
    },
    // 详情
    detail(code, moveType, params) {
        return api.get('/statistics/personMove/detail/' + code + '/' + moveType, params)
    },
    // 生成
    generate(params) {
        return api.post('/statistics/personMove/generate', params)
    },
    // 导出
    export(params) {
        return api.getfile('/statistics/personMove/export', params)
    },
}
