import api from '../index'

export default {
  enterprises() {
    return api.get('/public/enterprises')
  },
  enterprises_v2() {
    return api.get('/public/enterprises/v2')
  },
  branches() {
    return api.get('/public/branchs')
  },
  customers() {
    return api.get('/public/customers')
  },
  getMenuId(menuName) {
    return api.get('/public/getMenuId/小库托管-' + menuName)
  },
  getOpLogs(menuId, relationIdStr, params) {
    return api.get('/public/getOpLogs/' + menuId + '/' + relationIdStr, params)
  }
}