<template>
  <div id="AddNetwork">
    <div class="page-header">
      <p class="page-path">
        服务网点管理<span class="slashline">/</span>小库网点列表<span
          class="slashline"
          >/</span
        >网点详情
      </p>
      <p class="page-title">网点详情</p>
    </div>
    <div class="info-filling">
      <div class="info-basic">
        <p class="title">网点基本信息</p>
        <el-form
          ref="basicform"
          :model="basicform"
          label-width="140px"
          class="basicform"
        >
          <el-form-item label="网点地区：" prop="branchDistrict">
            <el-select
              v-model="basicform.branchDistrict"
              placeholder="请选择"
              disabled
            >
              <el-option
                v-for="item in branchDistrictList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网点名称：" prop="name">
            <el-input v-model="basicform.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="网点负责人：" prop="branchRepresentative">
            <el-input
              v-model="basicform.branchRepresentative"
              placeholder="请输入"
              maxlength="20"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="是否需要在保人员：">
            <el-radio-group v-model="basicform.isPersonalEssential" disabled>
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
            <div class="form-inline-group">
              <el-form-item prop="socialEssential">
                <span class="text">社保</span>
                <el-input
                  v-model="basicform.socialEssential"
                  placeholder="请输入"
                  style="width: 70px"
                  disabled
                ></el-input>
                <span class="text">人</span>
              </el-form-item>
              <el-form-item prop="accEssential">
                <span class="text">公积金</span>
                <el-input
                  v-model="basicform.accEssential"
                  placeholder="请输入"
                  style="width: 70px"
                  disabled
                ></el-input>
                <span class="text">人</span>
              </el-form-item>
            </div>
          </el-form-item>
          <div>
            <el-form-item style="display:inline-block;" prop="socialOfflineOperation" label="社保补缴：">
            <el-radio-group
              v-model="basicform.socialOfflineOperation"
              disabled
            >
              <el-radio :label="1">线上操作</el-radio>
              <el-radio :label="2">线下操作</el-radio>
            </el-radio-group>
            </el-form-item>
            <el-form-item style="display:inline-block;" prop="accOfflineOperation" label="公积金补缴：">
              <el-radio-group
                v-model="basicform.accOfflineOperation"
                disabled 
              >
                <el-radio :label="1">线上操作</el-radio>
                <el-radio :label="2">线下操作</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item label="收件地址：" prop="consigneeAddressInfo">
            <el-input
              v-model="consigneeAddressInfo.receiver"
              placeholder="请输入收件人"
              maxlength="20"
              style="width:49.5%;margin-bottom: 10px"
              disabled
            ></el-input>
             <el-input
              v-model="consigneeAddressInfo.phoneNumber"
              placeholder="请输入电话号码"
              maxlength="20"
              style="margin-left:1px;width:50%;margin-bottom: 10px"
              disabled
            ></el-input>
             <el-input
              type="textarea"
              v-model="consigneeAddressInfo.address"
              disabled
              :rows="1"
              placeholder="请输入邮寄地址"
              maxlength="100"
              style="margin-bottom: 10px"
            ></el-input>
             <el-input
              type="textarea"
              v-model="consigneeAddressInfo.remark"
              disabled
              placeholder="备注"
              :rows="1"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="公积金比例设置：">
            <div
              class="form-inline-group"
              style="margin-left: 0; margin-bottom: 14px"
              v-for="(item, index) in basicform.accRatio"
              :key="index"
            >
              <el-form-item :prop="'accRatio.' + index + '.person'">
                <el-input
                  v-model="item.person"
                  placeholder="个人缴纳比例（%）"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item
                style="margin-left: 10px"
                :prop="'accRatio.' + index + '.enterprise'"
              >
                <el-input
                  v-model="item.enterprise"
                  placeholder="单位缴纳比例（%）"
                  disabled
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="特殊情况说明：" prop="remark">
            <el-input
              type="textarea"
              v-model="basicform.remark"
              placeholder="请输入"
              maxlength="200"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="create-filling">
        <p class="title">开户所需资料</p>
        <el-table
          ref="createTable"
          max-height="500"
          :data="createTableData"
          border
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="资料名称">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.name"
                placeholder="请输入"
                maxlength="10"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="资料类型">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.format"
                placeholder="请选择"
                disabled
              >
                <el-option label="原件类" value="原件类"></el-option>
                <el-option label="图像类" value="图像类"></el-option>
                <el-option label="文本类" value="文本类"></el-option>
                <el-option label="表格或协议类" value="表格或协议类"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="资料要求">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.requirements"
                placeholder="请输入"
                maxlength="50"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="资料份数">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.quantity"
                placeholder="请输入"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="适用场景">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.occations"
                placeholder="请选择"
                disabled
              >
                <el-option label="社保" :value="0"></el-option>
                <el-option label="公积金" :value="1"></el-option>
                <el-option label="社保和公积金" :value="2"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="资料样本">
            <template slot-scope="scope">
              <div
                v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample != ''"
              >
                {{ scope.row.sample }}
              </div>
              <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
            </template>
          </el-table-column>
          <el-table-column label="空表上传">
            <template slot-scope="scope">
              <div
                v-if="
                  (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample != ''
                "
              >
                {{ scope.row.emptySample }}
              </div>
              <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="hosting-filling">
        <p class="title">托管所需资料</p>
        <el-table
          ref="hostingTable"
          max-height="500"
          :data="hostingTableData"
          border
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="资料名称">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.name"
                placeholder="请输入"
                maxlength="10"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="资料类型">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.format"
                placeholder="请选择"
                disabled
              >
                <el-option label="原件类" value="原件类"></el-option>
                <el-option label="图像类" value="图像类"></el-option>
                <el-option label="文本类" value="文本类"></el-option>
                <el-option label="表格或协议类" value="表格或协议类"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="资料要求">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.requirements"
                placeholder="请输入"
                maxlength="50"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="资料份数">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.quantity"
                placeholder="请输入"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="适用场景">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.occations"
                placeholder="请选择"
                disabled
              >
                <el-option label="社保" :value="0"></el-option>
                <el-option label="公积金" :value="1"></el-option>
                <el-option label="社保和公积金" :value="2"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="资料样本">
            <template slot-scope="scope">
              <div
                v-if="(scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.sample != ''"
              >
                {{ scope.row.sample }}
              </div>
              <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
            </template>
          </el-table-column>
          <el-table-column label="空表上传">
            <template slot-scope="scope">
              <div
                v-if="
                  (scope.row.format === '图像类'||scope.row.format === '表格或协议类') && scope.row.emptySample != ''
                "
              >
                {{ scope.row.emptySample }}
              </div>
              <div v-if="scope.row.format === '原件类'||scope.row.format === '文本类'">*</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="bottom-btn-group">
      <el-button @click="returnServiceNetwork">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddNetwork",
  components: {},
  data: function () {
    return {
      BranchDetail: "",
      branchDistrictList: [],
      basicform: {
        // 网点基本信息
        branchDistrict: "",
        name: "",
        branchRepresentative: "",
        isPersonalEssential: "",
        consigneeAddress: "",
        socialEssential: "",
        accEssential: "",
        socialOfflineOperation:0,
        accOfflineOperation:0,
        remark: "",
        accRatio: [
          {
            person: "",
            enterprise: "",
          },
        ],
      },
      consigneeAddressInfo:{
        receiver:"",
        phoneNumber:"",
        address:"",
        remark:""
      },
      createTableData: [
        // 开户所需资料--表数据
        {
          rowindex: 0,
          type: 0, // 开户资料 0
          name: "",
          format: "",
          requirements: "",
          quantity: "",
          occations: "",
          sample: "",
          uploadSampleFile: "",
          uploadSampleFileName: "",
          uploadSampleLoading: false,
          emptySample: "",
          uploadEmptySampleFile: "",
          uploadEmptySampleFileName: "",
          uploadEmptySampleLoading: false,
        },
      ],
      hostingTableData: [
        // 托管所需资料--表数据
        {
          rowindex: 0,
          type: 1, // 托管资料 1
          name: "",
          format: "",
          requirements: "",
          quantity: "",
          occations: "",
          sample: "",
          uploadSampleFile: "",
          uploadSampleFileName: "",
          uploadSampleLoading: false,
          emptySample: "",
          uploadEmptySampleFile: "",
          uploadEmptySampleFileName: "",
          uploadEmptySampleLoading: false,
        },
      ],
    };
  },
  methods: {
    returnServiceNetwork() {
      this.$router.push("/ServiceBranch");
    },
    manageData() {
      let dt = this.BranchDetail;
      this.basicform = {
        branchDistrict: dt.branchDistrict,
        name: dt.name,
        branchRepresentative: dt.branchRepresentative,
        isPersonalEssential: dt.isPersonalEssential,
        consigneeAddress: dt.consigneeAddress,
        socialEssential: dt.socialEssential,
        accEssential: dt.accEssential,
        remark: dt.remark,
        accRatio: JSON.parse(dt.accRatio),
        socialOfflineOperation:dt.socialOfflineOperation,
        accOfflineOperation:dt.accOfflineOperation,
      };
      if(this.basicform.consigneeAddress != null && this.basicform.consigneeAddress != ""){
        if(this.basicform.consigneeAddress.indexOf('邮寄地址：')>-1 && this.basicform.consigneeAddress.indexOf('收件人：')>-1 && this.basicform.consigneeAddress.indexOf('收件人联系电话：')>-1&& this.basicform.consigneeAddress.indexOf('邮寄地址：')< this.basicform.consigneeAddress.indexOf('收件人：')&& this.basicform.consigneeAddress.indexOf('收件人：')< this.basicform.consigneeAddress.indexOf('收件人联系电话：')){
          if(this.basicform.consigneeAddress.indexOf('邮寄地址：')>-1 ){
            this.consigneeAddressInfo.address = this.basicform.consigneeAddress.substring(0,this.basicform.consigneeAddress.indexOf('收件人：'));
            this.consigneeAddressInfo.address = this.consigneeAddressInfo.address.replace('邮寄地址：','');
          }
          if(this.basicform.consigneeAddress.indexOf('收件人：')>-1 ){
            this.consigneeAddressInfo.receiver = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('收件人：'),this.basicform.consigneeAddress.indexOf('收件人联系电话：') );
            this.consigneeAddressInfo.receiver = this.consigneeAddressInfo.receiver.replace("收件人：","");
          }
           if(this.basicform.consigneeAddress.indexOf('收件人：')>-1 ){
            this.consigneeAddressInfo.phoneNumber = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('收件人联系电话：'),this.basicform.consigneeAddress.indexOf('备注：') );
            this.consigneeAddressInfo.phoneNumber = this.consigneeAddressInfo.phoneNumber.replace("收件人联系电话：","");
          }
          if(this.basicform.consigneeAddress.indexOf('备注：')>-1 ){
            this.consigneeAddressInfo.remark = this.basicform.consigneeAddress.substring(this.basicform.consigneeAddress.indexOf('备注：'),this.basicform.consigneeAddress.length);
            this.consigneeAddressInfo.remark = this.consigneeAddressInfo.remark.replace("备注：","");
          }
        }else{
          this.consigneeAddressInfo.remark = this.basicform.consigneeAddress
        }
        
      }
      let createTableData = [];
      let hostingTableData = [];
      dt.ensentialFiles.forEach((item) => {
        if (item.type == 0) {
          createTableData.push(item);
        } else {
          hostingTableData.push(item);
        }
      });
      createTableData.forEach((item, index) => {
        item.rowindex = index;
      });
      hostingTableData.forEach((item, index) => {
        item.rowindex = index;
      });
      this.createTableData = createTableData;
      this.hostingTableData = hostingTableData;
    },
    async getDetail() {
      let bid = this.$route.params.bid;
      try {
        let res = await this.$api.branch.BranchDetail(bid);
        if (res) {
          this.BranchDetail = res;
          this.manageData();
        } else {
          this.$message.error("获取详情失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取详情失败！");
      }
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#AddNetwork {
  padding: 20px;
}
.info-filling {
  background: #fff;
  padding: 20px 20px;
}
.info-filling .title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-family: 微软雅黑;
  display: inline-block;
  margin-right: 16px;
}
.basicform {
  width: 800px;
  padding: 20px 16px;
}
.form-inline-group {
  display: inline-block;
  margin-left: 30px;
  color: #606266;
}
.form-inline-group .el-form-item {
  display: inline-block;
  margin-bottom: 0;
}
.form-inline-group .text {
  margin: 0 10px;
}
.filling-btn-group {
  display: inline-block;
}
#AddNetwork .el-table {
  margin: 20px 0;
}
.bottom-btn-group {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.addButton {
  width: 100%;
}
</style>
