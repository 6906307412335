<template>
  <div id="ApplyList">
    <div class="page-header">
      <p class="page-path">
        业务管理<span class="slashline">/</span>托管业务申报列表
      </p>
      <p class="page-title">托管业务申报列表</p>
    </div>
    <div class="operation-pannel">
      <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单位名称：" prop="enterpriceName">
              <el-input
                v-model="filters.enterpriceName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否回寄：" prop="isReturned">
              <el-select
                v-model="filters.isReturned"
                placeholder="请选择"
                clearable
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="托管类型：" prop="hostingType">
              <el-select
                v-model="filters.hostingType"
                placeholder="请选择"
                clearable
              >
                <el-option label="社保托管" value="0"></el-option>
                <el-option label="公积金托管" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="searchList">查询</el-button>
            <el-button
              type="default"
              @click="
                resetForm('filtersform');
                searchList();
              "
              >重置</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="托管网点：" prop="branch">
              <el-input
                v-model="filters.branch"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请状态：" prop="applyStatus">
              <el-select
                v-model="filters.applyStatus"
                placeholder="请选择"
                clearable
              >
                <el-option label="待受理" value="0"></el-option>
                <el-option label="办理中" value="1"></el-option>
                <el-option label="办理成功" value="2"></el-option>
                <el-option label="办理失败" value="3"></el-option>
                <el-option label="已取消" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属客户：" prop="customer">
              <el-select
                v-model="filters.customer"
                placeholder="请选择"
                clearable
              >
                <el-option label="ERS" value="KH100010"></el-option>
                <el-option label="FAR" value="KH100012"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button
          type="primary"
          @click="HostingAcceptance"
          v-show="processShow"
          >受理业务</el-button
        >
        <el-button
          type="primary"
          plain
          @click="HostingSuccessfully"
          v-show="processShow"
          >成功</el-button
        >
        <el-button
          type="danger"
          plain
          @click="HostingFailed"
          v-show="processShow"
          >失败</el-button
        >
        <el-button
          type="default"
          v-if="activeKutab == 0"
          @click="$router.push({ name: 'AddHosting' })"
          v-show="addShow"
          >新增托管</el-button
        >
        <el-button
          type="default"
          @click="FillingDeliveryInfo"
          v-show="processShow"
          >填写邮寄资料</el-button
        >
      </div>
      <el-table
        ref="ApplyListTable"
        max-height="500"
        :data="ApplyListTableData"
        border
        style="margin: 32px 0 30px"
        v-loading="ApplyListTableLoading"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column
          label="申请编号"
          prop="code"
          width="120"
        ></el-table-column>
        <el-table-column
          label="单位名称"
          prop="enterpriseName"
          width="150"
        ></el-table-column>
        <el-table-column label="所属客户" prop="customer"></el-table-column>
        <el-table-column
          label="托管类型"
          width="100"
          prop="entrustTypeText"
        ></el-table-column>
        <el-table-column label="申请类型" prop="applyTypeText">
        </el-table-column>
        <el-table-column label="申请状态" prop="applyStatusText">
        </el-table-column>
        <el-table-column label="托管网点" width="175" prop="branch">
        </el-table-column>
        <el-table-column label="是否回寄">
          <template slot-scope="scope">
            <div v-if="scope.row.isReturned">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column label="邮件往来" width="110">
          <template slot-scope="scope">
            <el-button type="default" @click="checkExpressDelivery(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="托管申报时间"
          prop="creationTime"
          width="150"
        ></el-table-column>
        <el-table-column label="托管资料" width="110">
          <template slot-scope="scope">
            <el-button type="default" @click="checkHostingMaterial(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="反馈说明" width="150" prop="failDescription">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="办理失败"
      :visible.sync="failedDialog"
      width="500px"
      @close="resetForm('failedDialogForm')"
    >
      <el-form
        :model="failedDialogForm"
        ref="failedDialogForm"
        :rules="failedDialogFormRules"
        label-width="110px"
      >
        <el-form-item label="失败原因：" prop="failReason">
          <el-select
            v-model="failedDialogForm.failReason"
            placeholder="请选择失败原因"
          >
            <el-option label="资料不全" value="资料不全"></el-option>
            <el-option label="不符合资格" value="资料不全"></el-option>
            <el-option label="其他原因" value="其他原因"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因说明：" prop="failDescribe">
          <el-input
            type="textarea"
            v-model="failedDialogForm.failDescribe"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="回寄单号：" prop="returnNoteNumber">
                  <el-input v-model="failedDialogForm.returnNoteNumber"></el-input>
                </el-form-item>
                <el-form-item label="收件人姓名：" prop="recipientName">
                  <el-input v-model="failedDialogForm.recipientName"></el-input>
                </el-form-item>
                <el-form-item label="收件人电话：" prop="recipientPhone">
                  <el-input v-model="failedDialogForm.recipientPhone"></el-input>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address">
                  <el-input
                    type="textarea"
                    v-model="failedDialogForm.address"
                    maxlength="200"
                  ></el-input>
                </el-form-item>
                <el-form-item label="回寄图片：" prop="uploadFileName">
                  <el-button
                    icon="el-icon-upload2"
                    @click="$refs.failedDialogUploadFile.click()"
                    >上传文件</el-button
                  >
                  <p>支持扩展名: .rar .zip .doc .docx .pdf .jpg...</p>
                  <input
                    type="file"
                    id="failedDialogUploadFile"
                    ref="failedDialogUploadFile"
                    hidden
                    @change="
                      (e) => {
                        failedDialogForm.uploadFile = e.target.files[0];
                        failedDialogForm.uploadFileName = e.target.files[0].name;
                      }
                    "
                  />
                  <div v-if="failedDialogForm.uploadFileName != ''">
                    {{ failedDialogForm.uploadFileName }}
                    <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      circle
                      style="margin-left: 10px"
                      @click="
                        failedDialogForm.uploadFileName = '';
                        failedDialogForm.uploadFile = '';
                        $refs.failedDialogUploadFile.value = '';
                      "
                    ></el-button>
                  </div>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="failedDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="SubmitFailedDialogForm('failedDialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="查看详情"
      :visible.sync="expressDeliveryDialog"
      width="500px"
      @close="expressDeliveryInfo = []"
    >
      <div class="mailDetailInfo">
        <div
          class="eachMailInfo"
          v-for="(item, index) in expressDeliveryInfo"
          :key="index"
        >
          <p class="mail-info" v-if="item.type === 0">
            客户邮寄单号：{{ item.trackingNo }}
          </p>
          <p class="mail-info" v-if="item.type === 1">
            回寄单号：{{ item.trackingNo }}
          </p>
          <p class="mail-info">收件人姓名：{{ item.name }}</p>
          <p class="mail-info">收件人联系方式：{{ item.phone }}</p>
          <p class="mail-info">详细地址：{{ item.address }}</p>
          <p class="mail-info">物流信息：</p>
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in item.tracksJson"
              :key="index"
              :timestamp="
                commonjs.dateFtt('yyyy-MM-dd hh:mm', new Date(activity.time))
              "
            >
              {{ activity.station }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            expressDeliveryInfo = [];
            expressDeliveryDialog = false;
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            expressDeliveryInfo = [];
            expressDeliveryDialog = false;
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="托管资料"
      :visible.sync="hostingInformationDialog"
      width="500px"
      @close="resetForm('hostingInformationForm')"
    >
      <el-form
        :model="hostingInformationForm"
        ref="hostingInformationForm"
        label-width="90px"
      >
        <div v-for="(item, index) in hostingInformationForm.info" :key="index">
          <el-form-item label="Ukey：" :prop="item.ukey">
            <el-input v-model="item.ukey" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="账号：" :prop="item.account">
            <el-input v-model="item.account" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="密码：" :prop="item.password">
            <el-input v-model="item.password" :disabled="true"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="申报资料：">
          <el-button @click="filesToRar">下载资料</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            resetForm('hostingInformationForm');
            hostingInformationDialog = false;
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            resetForm('hostingInformationForm');
            hostingInformationDialog = false;
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="填写邮件资料"
      :visible.sync="fillingExpressInfoDialog"
      width="500px"
      @close="resetForm('fillingExpressInfoDialogForm')"
    >
      <el-form
        :model="fillingExpressInfoDialogForm"
        ref="fillingExpressInfoDialogForm"
        :rules="fillingExpressInfoDialogFormRules"
        label-width="110px"
      >
        <el-form-item label="回寄单号：" prop="returnNoteNumber">
          <el-input
            v-model="fillingExpressInfoDialogForm.returnNoteNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人姓名：" prop="recipientName">
          <el-input
            v-model="fillingExpressInfoDialogForm.recipientName"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人电话：" prop="recipientPhone">
          <el-input
            v-model="fillingExpressInfoDialogForm.recipientPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input
            type="textarea"
            v-model="fillingExpressInfoDialogForm.address"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="回寄图片：" prop="uploadFileName">
          <el-button
            icon="el-icon-upload2"
            @click="$refs.fillingExpressInfoDialogUploadFile.click()"
            >上传文件</el-button
          >
          <p>支持扩展名: .rar .zip .doc .docx .pdf .jpg...</p>
          <input
            type="file"
            id="fillingExpressInfoDialogUploadFile"
            ref="fillingExpressInfoDialogUploadFile"
            hidden
            @change="
              (e) => {
                fillingExpressInfoDialogForm.uploadFile = e.target.files[0];
                fillingExpressInfoDialogForm.uploadFileName =
                  e.target.files[0].name;
              }
            "
          />
          <div v-if="fillingExpressInfoDialogForm.uploadFileName != ''">
            {{ fillingExpressInfoDialogForm.uploadFileName }}
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              circle
              style="margin-left: 10px"
              @click="
                fillingExpressInfoDialogForm.uploadFileName = '';
                fillingExpressInfoDialogForm.uploadFile = '';
                $refs.fillingExpressInfoDialogUploadFile.value = '';
              "
            ></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            fillingExpressInfoDialog = false;
            resetForm('fillingExpressInfoDialogForm');
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            SubmitFillingExpressInfoDialogForm('fillingExpressInfoDialogForm')
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Cookie from "js.cookie";
import kutabs from "@/components/kutabs";
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
  name: "AddNetwork",
  components: {
    kutabs,
  },
  data: function () {
    return {
      menuId: "",
      Kutabs: [
        {
          name: "申请托管",
          num: 0,
        },
        {
          name: "取消托管",
          num: 0,
        },
      ],
      activeKutab: 0,
      processShow: false,
      addShow: true,
      filters: {
        enterpriceName: "",
        isReturned: "",
        hostingType: "",
        branch: "",
        applyStatus: "0", // 0 待受理；1 办理中；2 办理成功；3 办理失败；4 已取消
        customer: "",
      },
      ApplyListTableData: [],
      ApplyListTableLoading: false,
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      failedDialog: false,
      failedDialogForm: {
        failReason: "",
        failDescribe: "",
      },
      failedDialogFormRules: {
        failReason: [
          { required: true, message: "请选择失败原因", trigger: "change" },
        ],
      },
      fillingExpressInfoDialog: false,
      fillingExpressInfoDialogForm: {
        returnNoteNumber: "",
        recipientName: "",
        recipientPhone: "",
        address: "",
        uploadFile: "",
        uploadFileName: "",
        description: "",
      },
      fillingExpressInfoDialogFormRules: {
        returnNoteNumber: [
          { required: true, message: "请填写回寄单号", trigger: "blur" },
        ],
        recipientName: [
          { required: true, message: "请输入收件人姓名", trigger: "blur" },
        ],
        recipientPhone: [
          { required: true, message: "请输入收件人电话", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        uploadFileName: [
          { required: true, message: "请上传回寄图片", trigger: "change" },
        ],
      },
      expressDeliveryDialog: false,
      expressDeliveryInfo: [],
      hostingInformationDialog: false,
      hostingInformationForm: {
        info: [],
      },
      fileUrl: [],
      fileName: "",
    };
  },
  async created() {
    let menuId =  await this.$api.publicapi.getMenuId("托管业务管理-托管业务申报");
    this.menuId = menuId;
  },
  methods: {
    tabChanged(params) {
      if (params.name == "申请托管") {
        this.activeKutab = 0;
      } else if (params.name == "取消托管") {
        this.activeKutab = 1;
      }
      this.searchList();
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getData();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.fileUrl = [];
      this.fileName = "";
      if (formName == "filtersform") {
        this.filters.applyStatus = "";
      }
    },
    HostingAcceptance() {
      // 受理业务--托管
      let ids = [];
      let selection = this.$refs.ApplyListTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.applyStatus != 0) {
          this.$message({
            message: "请选择“待受理”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      this.$confirm("是否确认受理业务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api--
          this.changeStatus(ids, 1);
        })
        .catch(() => {});
    },
    HostingSuccessfully() {
      // 托管成功
      let ids = [];
      let selection = this.$refs.ApplyListTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.applyStatus != 1) {
          this.$message({
            message: "请选择“办理中”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      let msg =
        this.activeKutab == 0
          ? "是否确认业务托管成功？"
          : "是否确认该项业务办理成功？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api--
          this.changeStatus(ids, 2);
        })
        .catch(() => {});
    },
    HostingFailed() {
      // 托管失败
      let selection = this.$refs.ApplyListTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.applyStatus != 1) {
          this.$message({
            message: "请选择“办理中”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.failedDialog = true;
    },
    SubmitFailedDialogForm(formName) {
      // 提交“办理失败”弹窗表格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let selection = this.$refs.ApplyListTable.selection;
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          this.changeStatus(ids, 3);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    FillingDeliveryInfo() {
      // 填写邮寄资料
      let selection = this.$refs.ApplyListTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.fillingExpressInfoDialog = true;
    },
    SubmitFillingExpressInfoDialogForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.logistics();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async logistics() {
      let finfo = this.fillingExpressInfoDialogForm;
      let FailResson = finfo.failReason;
      switch (FailResson) {
        case 0:
          FailResson = "资料不全";
          break;
        case 1:
          FailResson = "不符合资格";
          break;
        case 2:
          FailResson = "其他原因";
          break;
        default:
          FailResson = "其他原因";
      }
      // 新增资料邮寄信息
      let params = new FormData();
      params.append("Name", finfo.recipientName);
      params.append("Phone", finfo.recipientPhone);
      params.append("Address", finfo.address);
      params.append("TrackingNo", finfo.returnNoteNumber);
      params.append("Company", ""); // 快递公司
      params.append("EntrustId", this.$refs.ApplyListTable.selection[0].id); // 托管申报ID
      params.append("FailResson", FailResson);
      params.append("Description", finfo.description);
      params.append("File", finfo.uploadFile);
      try {
        let res = await this.$api.entrustOrder.addLogistic(params);
        if (res) {
          this.fillingExpressInfoDialog = false;
          this.resetForm("fillingExpressInfoDialogForm");
          this.$message({
            message: "提交成功！",
            type: "success",
          });
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          } else {
            this.$message.error("提交失败！");
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async changeStatus(ids, status) {
      let params = {
        lstId: ids,
        applyStatus: status,
        failResson: this.failedDialogForm.failReason,
        description: this.failedDialogForm.failDescribe,
      };
      try {
        let res = await this.$api.entrustOrder.status(params);
        if (res) {
          if (status == 1) {
            this.$message({
              message: "受理成功",
              type: "success",
            });
          } else if (status == 2) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          } else if (status == 3) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.failedDialog = false;
          }
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.data.msg);
      }
    },
    checkExpressDelivery(row) {
      // 查看快递详情
      this.getLogistics(row.id);
    },
    async getLogistics(id) {
      try {
        let res = await this.$api.entrustOrder.logistics(id);
        if (res && res.length > 0) {
          this.expressDeliveryInfo = res;
          this.expressDeliveryDialog = true;
        } else {
          this.$message("暂无快递信息");
        }
      } catch (e) {
        console.log(e);
      }
    },
    checkHostingMaterial(row) {
      // 查看托管资料
      this.getUkey(row.id);
      if (
        row.fileUrl.split(";").length > 0 &&
        row.fileUrl != null &&
        row.fileUrl != "" &&
        row.fileUrl != "[]" &&
        row.fileUrl != "string"
      ) {
        row.fileUrl.split(";").forEach((x) => {
          if (x != null && x != "") {
            let obj = {
              fileUrl: x,
              fileName: x.split("/")[x.split("/").length - 1],
            };
            if (this.fileUrl.length > 0) {
              this.fileUrl.forEach((element) => {
                if (element.fileName == obj.fileName) {
                  let list = obj.fileName.split(".");
                  list[0] = list[0] + "-" + 1;
                  obj.fileName = list[0] + "." + list[1];
                  this.recursive(obj.fileName);
                  obj.fileName = this.fileName;
                }
              });
            }
            this.fileUrl.push(obj);
          }
        });
      }
    },
    recursive(fileName) {
      this.fileUrl.forEach((element) => {
        if (element.fileName == fileName) {
          let firstList = fileName.split(".");
          let secondList = firstList[0].split("-");
          secondList[1] = parseInt(secondList[1]) + 1;
          firstList[0] = secondList[0] + "-" + secondList[1];
          fileName = firstList[0] + "." + firstList[1];
          this.recursive(fileName);
        } else {
          this.fileName = fileName;
        }
      });
    },
    async getUkey(id) {
      try {
        let res = await this.$api.entrustOrder.ukey(id);
        if (res && res.length > 0) {
          this.hostingInformationForm.info = res;
          this.hostingInformationDialog = true;
        } else {
          this.$message("暂无托管资料");
        }
      } catch (e) {
        console.log(e);
      }
    },
    filesToRar() {
      //文件打包
      if (this.fileUrl.length < 1) {
        this.$message.error("没有申报资料可下载！");
        return;
      }
      let zip = new JSZip();
      let cache = {};
      let promises = [];
      let fileName = "申报资料";
      this.title = "正在加载压缩文件";
      for (let item of this.fileUrl) {
        const promise = this.getImgArrayBuffer(item.fileUrl).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.fileName, data, { binary: true }); // 逐个添加文件
          cache[item.fileName] = data;
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            this.title = "正在压缩";
            // 生成二进制流
            FileSaver.saveAs(content, fileName); // 利用file-saver保存文件 自定义文件名
            this.title = "压缩完成";
          });
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("文件压缩失败");
        });
    },
    getImgArrayBuffer(url) {
      //获取文件blob
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", url, true);
        xmlHttp.responseType = "blob";
        xmlHttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlHttp.send();
      });
    },
    checkFeedback(row) {
      // 查看反馈说明
      console.log(row);
    },
    searchList() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.totalCount = 0;
      this.getData();
    },
    async getData() {
      let listParams = {
        ApplyType: this.activeKutab,
        EnterpriseName: this.filters.enterpriceName,
        // Code: this.filters.applyNum,
        IsReturned: this.filters.isReturned,
        EntrustType: this.filters.hostingType,
        Branch: this.filters.branch,
        ApplyStatus: this.filters.applyStatus,
        CustomerId: this.filters.customer,
        PageCount: this.currentPage,
        TakeCount: this.pageSize,
      };
      this.ApplyListTableLoading = true;
      try {
        let [listData, stat] = await Promise.all([
          this.$api.entrustOrder.orders(listParams),
          this.$api.entrustOrder.count(),
        ]);
        this.totalCount = listData.totalCount;
        this.ApplyListTableData = listData.item;
        this.Kutabs = [
          {
            name: "申请托管",
            num: stat.apply0Number,
          },
          {
            name: "取消托管",
            num: stat.apply1Number,
          },
        ];
        this.ApplyListTableLoading = false;
      } catch (err) {
        console.log(err);
        this.ApplyListTableLoading = false;
      }
    },
    async IsOperable(pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid);
        if (pid == "OP100348") {
          this.processShow = res;
        }
        if (pid == "OP100349") {
          this.addShow = res;
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取按钮权限失败！");
      }
    },
    getBtnAuth() {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get("userId");
      if (userId == null || userId == "")
        userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

      this.IsOperable("OP100348", userId);
      this.IsOperable("OP100349", userId);
    },
  },
  mounted() {
    this.getData();
    this.getBtnAuth();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#ApplyList {
  padding: 20px;
}

#ApplyList .operation-pannel {
  padding-top: 0;
}

.mail-info {
  line-height: 20px;
  margin-bottom: 16px;
}
</style>
