<template>
  <div id="app">
    <keep-alive>
    <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件 -->
    </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件 -->
    </router-view>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "App",
  components: {},
  data: function () {
    return {};
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #f0f2f5;
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
</style>
