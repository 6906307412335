import api from '../index'

export default {
  SaleBillList(params) { // 应收账单列表
    return api.get('/bill/sale', params)
  },
  SaleBillDetail(bid) { // 应收账单详情
    return api.get('/bill/sale/' + bid)
  },
  SaleSocialBillInfo(bid) { // 应收社保账单
    return api.get('/bill/sale/socialBill/'+ bid)
  },
  SaleAccBillInfo(bid) { // 应收公积金账单
    return api.get('/bill/sale/accBill/'+ bid)
  },
  SaleServiceBillInfo(bid) { // 应收公积金账单
    return api.get('/bill/sale/serviceBill/'+ bid)
  },
  SaleOpenBillInfo(bid) { // 应收开户账单
    return api.get('/bill/sale/openBill/'+ bid)
  },
  SocialBillDetail(params) { // 社保账单详情
    return api.get('/bill/sale/getSocialdetail', params)
  },
  AccBillDetail(params) { // 公积金账单详情
    return api.get('/bill/sale/getAccdetail', params)
  },
  ServiceBillDetail(params) { // 公积金账单详情
    return api.get('/bill/sale/getServicedetail', params)
  },
  IsImported(params){
    return api.post('/bill/sale/IsImported', params)
  },
  BillAudit(bid, billType,flag, opinion) { // 1、提交审核 2、审核拒绝 3、审核通过 4、撤回账单 5、推送账单 
    return api.post('/bill/sale/BillAudit/' + bid + '/' + billType +'/'+ flag+'/' + opinion)
  },
  exportBill(params) {
    return api.postfile('/bill/sale/export', params)
  },
  BillPay(bid, billType,flag) { // 1、缴费成功 2、缴费失败
    return api.post('/bill/sale/BillPay/' + bid + '/' + billType +'/'+ flag)
  },
  uploadExcel(params){//导入为防止解析存储超时，超时设置为5分钟
    return api.ImportPost('/bill/sale/import',params,{
      'Content-Type':'multipart/form-data'
  },300000)
}
}