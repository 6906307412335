import api from '../index'

export default {
    ApplyFieldInfoList(params) { // 申报字段列表
        return api.get('/applyField', params)
    },
    ApplyFieldDetailList(params) { // 申报字段详情列表
        return api.get('/applyField/Detail', params)
    },
    EditApplyFieldInfo(params){  //新增或修改申报字段
        return api.post('/applyField/Edit', params)
    },
    UpLoadFile(params){ //上传文件
        return api.post('/applyField/Upload',params)
    },
    AddRegionCheck(params){  //新增校验地区
        return api.get('/applyField/CheckRegion',params)
    },
    UpdateAuditStatus(params){ //提交审核
        return api.post('/applyField/UpdateAuditStatus/' + params)
    },
    DelApplyField(params){  //软删除申报字段
        return api.post('/applyField/DelApplyField/' + params)
    },
    WithdrawApplyField(params){ //撤回,将待审核撤回到待提交
        return api.post('/applyField/WithdrawApplyField/' + params)
    },
    AuditApplyField(params){ //审核
        return api.post('/applyField/Audit',params)
    },
    ImportExcel(params){ //批量导入
        return api.post('/applyField/Import',params)
    }
}