import axios from 'axios'
import Vue from 'vue'
import myImage from '@/assets/fileassets/302.png';
import FullScreenMessage from '../components/FullScreenMessage '; // 导入 FullScreenMessage 组件

// import router from '@/router' // 导入 Vue Router 实例

// 从环境变量中获取登录页面 URL
const loginUrl = process.env.VUE_APP_LOGIN_URL;
// 创建 axios 实例
let http = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // 一定要VUE_APP_A为前缀
  timeout: 120000
})

// 设置 post、put 默认 Content-Type
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
http.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';
http.defaults.headers["Cache-Control"] = "no-cache";
http.defaults.withCredentials = true;

// 添加请求拦截器
http.interceptors.request.use(config => {
  if (config.method === 'post' || config.method === 'put') {
    // config.data = JSON.stringify(config.data)
  } else if (config.method === 'get') {
    // 给GET 请求后追加时间戳
    let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
    config.url += symbol + '_=' + Date.now()
  }
  // 请求发送前进行处理
  return config
}, error => {
  // 请求错误处理
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
  let { data } = response
  return data
}, error => {
  let info = {}
  let { status, statusText, data } = error.response
  if (status == 403) {
    //403
    const fullscreenMessageInstance = new Vue({
      render: h => h(FullScreenMessage, {
        props: {
          imageUrl: myImage, // 你的图片路径
          message: 'Hi 登录超时，二秒钟之后跳转到登录页面。'
        }
      })
    }).$mount();
  
    document.body.appendChild(fullscreenMessageInstance.$el);
  
    // 设置延时跳转到登录页
    setTimeout(() => {
      window.location.href = loginUrl;
    },2000); // 延时，单位为毫秒
  }

  // else if (!error.response) {
  //   info = {
  //     code: 5000,
  //     msg: 'Network Error'
  //   }
  // } 
  else {
    // 此处整理错误信息格式
    info = {
      code: status,
      data: data,
      msg: statusText
    }
  }
  return Promise.reject(info)
})

export default function () {
  return http
}