import axios from './axios'

let instance = axios()
export default {
  get(url, params, headers) {
    let options = {}
    if (params) {
      options.params = params
    }
    if (headers) {
      options.headers = headers
    }
    return instance.get(url, options)
  },
  getfile(url, params, headers) {
    let options = {}
    if (params) {
      options.params = params
    }
    if (headers) {
      options.headers = headers
    }
    options.responseType = "blob";
    return instance.get(url, options)
  },
  post(url, params, headers, options = {}) {
    // let options = {}
    if (headers) {
      options.headers = headers
    }
    return instance.post(url, params, options)
  },
  postfile(url, params, headers) {
    let options = {}
    if (headers) {
      options.headers = headers
    }
    options.responseType = "blob";
    return instance.post(url, params, options)
  },
  postwithparams(url, data, params, headers) {
    let options = {}
    if (params) {
      options.params = params
    }
    if (headers) {
      options.headers = headers
    }
    return instance.post(url, data, options)
  },
  put(url, params, headers) {
    let options = {}
    if (headers) {
      options.headers = headers
    }
    return instance.put(url, params, options)
  },
  delete(url, params, headers) {
    let options = {}
    if (params) {
      options.params = params
    }
    if (headers) {
      options.headers = headers
    }
    return instance.delete(url, options)
  },
  ImportPost(url, params, headers, timeout) {
    let options = {}
    let outtime = 60000
    if (headers) {
      options.headers = headers
    }
    if (timeout) {
      outtime = timeout
    }
    return instance.post(url, params, { timeout: outtime }, options)
  },
}