<template>
  <div id="OperateDeadlineList">
    <div class="page-header">
      <p class="page-title">客户单位初始员工信息</p>
    </div>
    <div class="operation-pannel">
      <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row
          class="responsive-row"
          style="margin-left: -38px; display: flex"
        >
          <el-col :span="4">
            <el-form-item label="所属单位:" prop="enterprise">
              <el-input
                v-model="filters.enterprise"
                clearable
                placeholder="请输入单位信息"
                class="responsive-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="数据ID:" prop="enterprise">
              <el-input
                v-model="filters.listId"
                clearable
                placeholder="请输入数据ID"
                class="responsive-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态:" prop="status">
              <el-select
                :disabled="filters.tab !== '全部'"
                v-model="filters.status"
                filterable
                placeholder="请选择托管初始状态"
                class="responsive-input"
              >
                <!-- <el-option
                  v-for="item in Kutabs"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                ></el-option> -->
                <el-option label="全部" :value="-1"></el-option>
                <el-option label="待审核" :value="2"></el-option>
                <el-option label="审核通过" :value="3"></el-option>
                <el-option label="审核不通过" :value="4"></el-option>
                <el-option label="待发送" :value="0"></el-option>
                <el-option label="已作废" :value="9"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="托管生效月份:" prop="month">
              <el-date-picker
                class="responsive-input"
                v-model="filters.month"
                type="month"
                @change="handlePeriodChange"
                placeholder="请输入托管生效月份"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="托管类型:" prop="type">
              <el-select
                v-model="filters.type"
                filterable
                class="responsive-input"
                placeholder="请选择托管初始状态"
              >
                <el-option label="全部" :value="-1"></el-option>
                <el-option label="社保托管" :value="0"></el-option>
                <el-option label="公积金托管" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="searchData">筛选</el-button>
            <el-button type="default" @click="resetFilter">重置</el-button>
          </el-col>
        </el-row>
        <div>
          <el-dropdown
            style="margin-right: 10px"
            @command="(command) => handleCommand(command)"
          >
            <el-button type="primary" class="el-dropdown-link">
              导入数据<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu>
              <el-dropdown-item command="a">社保托管</el-dropdown-item>
              <el-dropdown-item command="b">公积金托管</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button @click="handlecheck()" type="primary">提交审核</el-button>
          <el-button @click="handledelete()" type="danger">作废</el-button>
          <el-button @click="handleget()" type="danger">生成在保信息</el-button>
        </div>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        ref="tableData"
        border
        style="width: 100%; margin-bottom: 30px"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55" key="1"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          show-overflow-tooltip
          :index="indexMethod"
          width="50"
          key="2"
        >
        </el-table-column>
        <el-table-column
          prop="enterprise"
          label="所属单位"
          width="130"
          show-overflow-tooltip
          key="3"
        ></el-table-column>
        <el-table-column
          prop="listId"
          label="数据ID"
          width="220"
          show-overflow-tooltip
          key="4"
        ></el-table-column>
        <el-table-column
          prop="branch"
          label="托管网点"
          width="220"
          show-overflow-tooltip
          key="4"
        ></el-table-column>
        <el-table-column
          prop="entrustType"
          label="托管类型"
          width="100"
          show-overflow-tooltip
          key="5"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="参缴城市"
          show-overflow-tooltip
          width="100"
          key="6"
        ></el-table-column>
        <el-table-column
          prop="customerName"
          label="所属客户"
          show-overflow-tooltip
          width="120"
          key="7"
        ></el-table-column>
        <el-table-column
          prop="initNum"
          label="初始人数"
          show-overflow-tooltip
          width="100"
          key="8"
        ></el-table-column>
        <el-table-column
          prop="month"
          label="托管生效月份"
          show-overflow-tooltip
          width="110"
          key="9"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          show-overflow-tooltip
          width="110"
          key="10"
        ></el-table-column>
        <el-table-column
          prop="applyResult"
          label="在保信息构建状态"
          show-overflow-tooltip
          width="150"
          key="10"
        ></el-table-column>
        <el-table-column
          prop="generatedTime"
          label="生成时间"
          show-overflow-tooltip
          width="150"
          key="11"
        >
          <!-- 使用插槽显示格式化后的时间 -->
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.generatedTime) }}
          </template></el-table-column
        >
        <el-table-column
          prop="updateTime"
          label="更新时间"
          width="150"
          show-overflow-tooltip
          key="12"
        >
          <!-- 使用插槽显示格式化后的时间 -->
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="editor"
          label="凭证信息"
          width="80"
          key="13"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              <el-button
                class="tools-a"
                type="text"
                @click="handlelook(scope.row)"
              >
                <span class="blue-col">查看 </span></el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="returnReasons"
          label="退回原因"
          width="100"
          show-overflow-tooltip
          key="14"
        ></el-table-column>
        <el-table-column prop="editor" label="操作" width="80" key="15">
          <template slot-scope="scope">
            <div>
              <el-button
                class="tools-a"
                type="text"
                @click="handleDetail(scope.row)"
              >
                <span class="blue-col"> 查看详情 </span></el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        align="left"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalCount"
        layout="total,prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="'导入新增弹框'"
      :visible.sync="dialogData.showDialog"
      width="40%"
      :close-on-click-modal="false"
      left
      :close-on-press-escape="false"
    >
      <adds-or-edit
        v-if="dialogData.showDialog"
        :id="dialogData.curId"
        :is-edit="dialogData.isEdit"
        :inv-check-status="dialogData.InvCheckStatus"
        :is-only-read="dialogData.isOnlyRead"
        :trusteeship-type="dialogData.trusteeshipType"
        :entrust-type="dialogData.entrustType"
        :form-data="dialogData.curData"
        @success="dialogSuccess"
        @close="dialogClose"
      />
    </el-dialog>
    <el-dialog
      :title="'查看凭证信息弹框'"
      :visible.sync="dialogDataView.showDialog"
      width="40%"
      :close-on-click-modal="false"
      left
      :close-on-press-escape="false"
    >
      <look-or-edit
        v-if="dialogDataView.showDialog"
        :id="dialogDataView.curId"
        :is-edit="dialogDataView.isEdit"
        :inv-check-status="dialogDataView.InvCheckStatus"
        :is-only-read="dialogDataView.isOnlyRead"
        :form-data="dialogDataView.curData"
        @success="dialogSuccess"
        @close="dialogClose"
      />
    </el-dialog>
    <el-dialog
      :title="'客户基本信息'"
      :visible.sync="dialogdetailData.showDialog"
      fullscreen
      :close-on-click-modal="false"
      left
      :close-on-press-escape="false"
    >
      <detail-or-edit
        v-if="dialogdetailData.showDialog"
        :id="dialogdetailData.curId"
        :is-edit="dialogdetailData.isEdit"
        :inv-check-status="dialogdetailData.InvCheckStatus"
        :is-only-read="dialogdetailData.isOnlyRead"
        :form-data="dialogdetailData.curData"
        :entrustType="dialogdetailData.entrustType"
        @success="dialogSuccess"
        @close="dialogClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import kutabs from "@/components/kutabs";
import addsOrEdit from "./addedit.vue";
import lookOrEdit from "./lookview.vue";
import detailOrEdit from "./detail.vue";
import moment from "moment";

export default {
  components: {
    kutabs,
    addsOrEdit,
    lookOrEdit,
    detailOrEdit,
  },
  name: "PersonnelUnderProtection",
  data: function () {
    return {
      loading: true,
      dialogDataView: {
        showDialog: false,
        isOnlyRead: false,
        curId: 0,
      },
      dialogdetailData: {
        showDialog: false,
        isOnlyRead: false,
        entrustType: "",
        curId: 0,
      },
      dialogData: {
        showDialog: false,
        isOnlyRead: false,
        curId: 0,
        trusteeshipType: "",
      },
      // 搜索属性
      filters: {
        enterprise: "",
        listId:'',
        month: "",
        status: -1,
        type: -1,
      },
      // 切换栏目数量
      Kutabs: [
        {
          name: "全部",
          value: -1,
          num: 0,
        },
        {
          name: "待审核",
          value: 2,
          num: 0,
        },
        {
          name: "审核通过",
          value: 3,
          num: 0,
        },
        {
          name: "审核不通过",
          value: 4,
          num: 0,
        },
        {
          name: "待发送",
          value: 0,
          num: 0,
        },
        {
          name: "已作废",
          value: 9,
          num: 0,
        },
       
      ],
      // 定义表格接收
      tableData: [],
      tableLoading: true,
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      show: false,
      addCityDialog: false,
      editDialog: false,
      addCityForm: {
        province: "",
        city: "",
        region: "",
      },
      provinceOptions: [],
      cityOptions: [],
    };
  },
  mounted() {
    this.getList();
    this.listnumber();
    this.filters.tab = "全部"; // 如果需要默认选中 '全部' 状态
  },
  methods: {
    handleCommand(command) {
      if (command === "a") {
        this.handleAdd(1,0);
      } else if (command === "b") {
        this.handleAdd(2,1);
      }
    },
    async listnumber() {
      try {
        let res = await this.$api.PersonnelUnderProtection.listnumber();
        if (res) {
          // 遍历 this.Kutabs 数组，为每个状态赋值相应的数量
          this.Kutabs.forEach((item) => {
            // 根据状态名称从 res 对象中获取对应的数量，并转换为数字类型
            const num = parseInt(res[item.name]);
            // 将获取到的数量赋值给对应状态的 num 属性
            item.num = isNaN(num) ? 0 : num;
          });
        } else {
          this.$message.error("查询出错");
        }
        this.tableLoading = false;
      } catch (e) {
        this.tableLoading = false;
      }
    },
    // 数据审核
    handlecheck() {
      let arr = this.$refs.tableData.selection;
      console.log("123", arr);

      if (arr.length <= 0) {
        this.$alert("请选中数据后进行该操作", "提示弹框", {
          confirmButtonText: "确定",
          callback: () => {
            // this.$message({
            //   type: 'info',
            //   message: '请选中数据后进行该操作'
            // })
          },
        });
      } else if (arr.length > 1) {
        this.$alert("无法进行批量提交", "提示弹框", {
          confirmButtonText: "确定",
          callback: () => {
            // this.$message({
            //   type: 'info',
            //   message: '请选中数据后进行该操作'
            // })
          },
        });
      } else if (arr[0].status != "待发送") {
        this.$alert("只有处于待发送状态的数据可进行提交", "提示弹框", {
          confirmButtonText: "确定",
          callback: () => {},
        });
      } else {
        this.$confirm("确认提交选中数据信息？", "提交弹框", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$api.PersonnelUnderProtection.checklist(arr)
              .then((res) => {
                this.searchData();
                this.$message.success(res.msg);
              })
              .catch((error) => {
                if (error && error.data && error.data.msg) {
                  this.$message.error(error.msg);
                } else {
                  this.$message.error("提交失败！");
                }
                this.$message.error(error.msg);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },

    // 数据作废
    handledelete() {
      let arr = this.$refs.tableData.selection;
      let hasNonPendingStatus = arr.some((obj) => obj.status !== "待发送");
      if (arr.length <= 0) {
        this.$alert("请选中数据后进行该操作", "提示弹框", {
          confirmButtonText: "确定",
        });
      } else if (hasNonPendingStatus) {
        this.$alert("只有处于待发送状态的数据可进行作废", "提示弹框", {
          confirmButtonText: "确定",
        });
      } else {
        this.$confirm(
          "作废后该数据将无法提交，确定作废选中的数据信息？",
          "提交弹框",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            const listIds = arr.map((item) => item.listId);
            this.$api.PersonnelUnderProtection.deletelist(listIds)
              .then((res) => {
                console.log("res", res);
                this.searchData();
                this.$message.success(res.msg);
              })
              .catch((error) => {
                if (error && error.data && error.data.msg) {
                  this.$message.error(error.data.msg);
                } else {
                  this.$message.error("作废失败！");
                }
                this.$message.error(error.data.msg);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    handleget() {
      // 获取选中的行
      let selectedRows = this.$refs.tableData.selection;

      if (selectedRows.length === 0) {
        this.$message({
          message: "请先选择任一项进行操作",
        });
        return;
      }

      if (selectedRows.length > 1) {
        this.$message({
          message: "只能选择一项进行操作",
        });
        return;
      }

      // 现在我们确定只有一行被选中
      let selectedRow = selectedRows[0];

      if (selectedRow.status !== "审核通过" || selectedRow.applyResult !== '构建申报失败') {
        debugger
        this.$alert(
          "只有处于审核通过且构建申报失败状态的数据可进行操作",
          "提示弹框",
          {
            confirmButtonText: "确定",
          }
        );
        return;
      }

      this.$confirm("确定生成该公司初始在保人员信息？", "生成在保信息弹框", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const listId = selectedRow.listId;
          console.log('hayhahah ',listId)
          debugger
          this.$api.PersonnelUnderProtection.getputcheck(listId) 
            .then((res) => {
              debugger
              console.log("res", res);
              this.searchData();
              this.$message.success(res.msg);
            })
            .catch((error) => {
              if (error && error.data && error.data.msg) {
                this.$message.error(error.data.msg);
              } else {
                this.$message.error("失败！");
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 时间的统一管理
    formatDateTime(dateTimeString) {
      if (!dateTimeString) {
        return ""; // 如果日期时间字符串为空，则直接返回空字符串
      }
      // 使用 Moment.js 解析并格式化日期时间
      const formattedDateTime = moment(
        dateTimeString,
        "MM/DD/YYYY HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm:ss");
      return formattedDateTime;
    },

    //新增按钮的编辑框
    handleAdd(type,entrustType) {
      this.dialogData.trusteeshipType = type;
      this.dialogData.entrustType = entrustType;
      this.dialogData.isEdit = false;
      this.dialogData.isOnlyRead = false;
      this.dialogData.curId = "";
      this.dialogData.curData = {};
      this.dialogData.showDialog = true;
    },
    // 详情
    handleDetail(row) {
      this.dialogdetailData.entrustType = row.entrustType;
      this.dialogdetailData.isEdit = true;
      this.dialogdetailData.isOnlyRead = true;
      this.dialogdetailData.curId = row.listId;
      // console.log(row.id)
      this.dialogdetailData.curData = row;
      this.dialogdetailData.showDialog = true;
    },
    // 查看
    handlelook(row) {
      console.log(row);
      this.dialogDataView.isEdit = true;
      this.dialogDataView.isOnlyRead = true;
      this.dialogDataView.curId = row.listId;
      console.log(row.id);
      this.dialogDataView.curData = row;
      this.dialogDataView.showDialog = true;
    },
    // 成功
    dialogSuccess() {
      this.dialogData.showDialog = false;
      this.dialogDataView.showDialog = false;
      this.dialogdetailData.showDialog = false;
      if (this.dialogData.isEdit == false) {
        this.searchData();
      }
    },
    // 取消
    dialogClose() {
      this.dialogData.showDialog = false;
      this.dialogdetailData.showDialog = false;
    },
    // 时间
    handlePeriodChange(value) {
      if (value) {
        const year = value.getFullYear();
        const month = String(value.getMonth() + 1).padStart(2, "0");
        this.filters.month = `${year}-${month}`;
      } else {
        this.filters.month = null;
      }
    },
    // 切换栏目
    tabChanged(params) {
      this.filters.tab = params.name;
      console.log("this.filters.tab", this.filters.tab);
      if (params.name == "全部") {
        this.filters.status = -1;
      } else if (params.name == "待审核") {
        this.filters.status = 2;
      } else if (params.name == "审核通过") {
        this.filters.status = 3;
      } else if (params.name == "审核不通过") {
        this.filters.status = 4;
      } else if (params.name == "待发送") {
        this.filters.status = 0;
      } else if (params.name == "已作废") {
        this.filters.status = 9;
      }
      // this.$refs.kutabs.updateTabsStatus(params.name);
      this.searchData();
    },
    searchData() {
      //查询
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
      this.listnumber();
    },
    resetFilter() {
      //重置
      this.filters = {
        enterprise: "",
        month: "",
        status: this.filters.tab !== "全部" ? this.filters.status : -1, //
        type: -1,
        listId:'',
        tab: this.filters.tab,
      };
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
      this.listnumber();
    },

    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    //列表
    async getList() {
      let params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        enterprise: this.filters.enterprise,
        month: this.filters.month,
        listId:this.filters.listId,
        status: this.filters.status,
        type: this.filters.type,
      };
      this.tableLoading = true;
      try {
        let res =
          await this.$api.PersonnelUnderProtection.getPersonnelUnderProtectionList(
            params
          );
        if (res) {
          this.totalCount = res.totalCount;
          this.tableData = res.item;
        } else {
          this.$message.error("查询出错");
        }
        this.tableLoading = false;
      } catch (e) {
        this.tableLoading = false;
      }
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getList();
      this.listnumber();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getList();
      this.listnumber();
    },
  },
};
</script>
<style scoped>
.responsive-row {
  margin-left: -38px;
  display: flex;
  flex-wrap: wrap;
}

.el-col {
  flex: 1 0 auto; /* 设置 el-col 元素在 flex 布局下的基本属性 */
  padding: 0 8px;
}

.responsive-input {
  width: 100%; /* 设置 input 框默认宽度为 100% */
}

/* 添加媒体查询，在小屏幕下调整 input 框的大小 */
@media (max-width: 768px) {
  .responsive-input {
    width: calc(100% - 20px); /* 例子中设置宽度减去 20px 的 padding */
  }
}
</style>
