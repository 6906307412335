import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './api/install'
/*import { Button, Input, InputNumber, Row, Col, Table, TableColumn, Switch, Message, MessageBox, Loading, Pagination, Form, FormItem, Radio, RadioGroup, Select, Option, Cascader, Tabs, TabPane, Dialog, Collapse, CollapseItem, DatePicker, Timeline, TimelineItem, Upload, Image, Link } from 'element-ui';*/
import Element from 'element-ui'
import cookies from 'vue-cookies'
import optLog from './components/optLog.vue'
Vue.config.productionTip = false;

Vue.use(api)

/*Vue.use(Button)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Dialog)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(DatePicker)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Link)
Vue.use(Loading.directive);*/
Vue.use(Element);

Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$confirm = Element.MessageBox.confirm;
Vue.prototype.$loading = Element.Loading.service;
Vue.prototype.$message = Element.Message;
Vue.prototype.$cookies = cookies;

//  提示信息转成到txt文本以供下载
Vue.prototype.$msgTotxt = function downloadTxt(text, fileName) {
  let element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', fileName)
  element.style.display = 'none'
  element.click()
}

Vue.prototype.$downloadByFileBytes = function downloadByFileBytes(bytes, fileName) {
  let blob = new Blob([bytes]);
  let links = document.createElement('a');
  links.style.display = "none";
  links.href = window.URL.createObjectURL(blob);
  links.download = fileName;
  document.body.appendChild(links);
  links.click();
  //释放内存
  window.URL.revokeObjectURL(links.href);
  document.body.removeChild(links);
}

import commonjs from './assets/common'
Vue.prototype.commonjs = commonjs;

Vue.component('OptLog', optLog);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
