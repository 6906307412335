import api from '../index'

export default {
    addslist(data) {  //导入新增
        // let data = new FormData();
        return api.post('/social-security/pending-participant-list/import', data, {
            'Content-Type': 'multipart/form-data'
        },{'responseType' :"blob"});
    },
    listnumber(params) {  //全部列表切换栏目数量
        return api.get('/social-security/pending-participant-list/statistics', params);
    },
    getPersonnelUnderProtectionList(params) {  //获取全部列表√
        return api.get('/social-security/pending-participant-list/list-page', params);
    },
    lookattachment(params) {  //查看凭证√
        return api.get(`/social-security/pending-participant-list/attachment/${params}`, params);
    },
    deletelist(params) {  //作废√
        let data = new FormData();
        params.forEach(id => {
            data.append('listIds', id);
        });
        return api.put('/social-security/pending-participant-list/cancel', data, {
            'Content-Type': 'multipart/form-data'
        });
    },
    checklist(params) { //审核√
        const listId = params[0].listId;
        return api.put(`/social-security/pending-participant-list/list-check-push/${listId}`, params);
    },
    lookessentialInformation(params) {//客户基本信息√
        return api.get(`/social-security/pending-participant-list/list-base/${params}`, params);
    },
    downmb(params) {//下载模版√
        return api.getfile(`/social-security/pending-participant-list/template`, params);
    },
    

    lookemployeetabledate(params) {//客户初始员工信息√
        const listId = params.listId;
        return api.get(`/social-security/pending-participant-list/detail-page/${listId}`, params);
    },
    detailsindetail(params) {// 详情里面的基本信息√
        return api.get(`/social-security/pending-participant-list/detail-base/${params}`, params);
    },
    getselectenterprises(entrustType) { // 单位下拉信息
        return api.get(`/public/enterprises/v2?entrustType=${entrustType}`);
      },
   
    getputcheck(listId) {
        return api.put(`/social-security/pending-participant-list/manual_init_apply/${listId}`);
      },
}