<template>
    <div id="OperateDeadlineList">
        <div class="page-header">
            <p class="page-path">
                城市配置<span class="slashline">/</span>城市配置列表
            </p>
            <p class="page-title">城市配置</p>
        </div>
        <div class="operation-pannel">
            <el-form :model="filters"
                     class="filters-form"
                     label-width="110px"
                     ref="filtersform">
                <el-row :gutter="16">
                    <el-col :span="4">
                        <el-form-item label="省：" prop="Province">
                            <el-input v-model="filters.Province" clearable placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="市：" prop="City">
                            <el-input v-model="filters.City" clearable placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="区：" prop="Region">
                            <el-input v-model="filters.Region" clearable placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" :offset="2">
                        <el-button type="primary" @click="searchData">查询</el-button>
                        <el-button type="default" @click="resetFilter">重置</el-button>
                    </el-col>
                </el-row>
                <div class="op-row">
                    <el-button @click="addConfig" type="primary" v-show="addShow">添加城市</el-button>
                    <el-button @click="edit" type="primary" plain v-show="editShow">编辑操作截止日</el-button>
                    <el-button @click="del" type="danger" v-show="addShow">删除</el-button>
                </div>
            </el-form>
        </div>
        <div class="table-container">
            <el-table max-height="500"
                      :data="tableData"
                      ref="tableData"
                      border
                      style="width: 100%; margin-bottom: 30px"
                      v-loading="tableLoading">
                <el-table-column type="selection" width="55" key="1"> </el-table-column>
                <el-table-column type="index"
                                 label="序号"
                                 :index="indexMethod"
                                 width="50"
                                 key="2">
                </el-table-column>
                <el-table-column prop="province" label="省" sortable width="175" key="3"></el-table-column>
                <el-table-column prop="city" label="市" sortable width="230" key="4"></el-table-column>
                <el-table-column prop="region" label="区" width="230" key="5"></el-table-column>
                <el-table-column prop="officialSocialDeadline" label="社保官方操作截止日" width="160" key="6"></el-table-column>
                <el-table-column prop="officialAccumulationDeadline" label="公积金官方操作截止日" width="170" key="7"></el-table-column>
                <el-table-column prop="socialOutAccount" label="社保出账日" width="100" key="8"></el-table-column>
                <el-table-column prop="accumulationOutAccount" label="公积金出账日" width="110" key="9"></el-table-column>
                <el-table-column prop="creationTime" label="创建时间" width="120" key="10"></el-table-column>
                <el-table-column prop="editTime" label="更新时间" width="120" key="11"></el-table-column>
                <el-table-column prop="editor" label="编辑人" width="80" key="12"></el-table-column>
                <el-table-column prop="id" label="id" v-if="this.show" key="13"></el-table-column>
            </el-table>
            <el-pagination background
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :page-sizes="[10, 20, 30, 40, 50, 100]"
                           :page-size="pageSize"
                           :current-page="currentPage"
                           :total="totalCount"
                           layout="prev, pager, next, sizes, jumper">
            </el-pagination>
        </div>

        <el-dialog :title="'新增城市'" class="popContainer" :visible.sync="addCityDialog" width="300px" :before-close="addCityClose">
            <hr/>
            <el-form ref="addCityForm" :model="addCityForm" :rules="addCityRules" ><br/>
                <el-form-item label="省:" prop="province">
                    <el-select v-model="addCityForm.province" filterable :loading="provinceLoading" @change="provinceOption" placeholder="请选择" style="width:50%;">
                        <el-option
                            v-for="item in provinceOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="市:" prop="city">
                    <el-select v-model="addCityForm.city" filterable :loading="cityLoading" placeholder="请选择" style="width:50%;">
                        <el-option
                            v-for="item in cityOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区:" prop="region">
                    <el-input v-model="addCityForm.region" placeholder="请输入" style="width:50%;"></el-input>
                </el-form-item>
            </el-form>
            <hr/><br/>
            <div style="text-align:right;">
                <el-button @click="addCityConfirm" type="primary">确定</el-button>
                <el-button @click="addCityClose">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="'编辑操作截止日'" class="popContainer" :visible.sync="editDialog" width="600px" :before-close="editClose">
            <hr/>
            <el-form ref="editForm" :model="editForm"><br/>
                <el-form-item :required="true" label="社保官方操作截止日:"><br/>
                    <el-select v-model="editForm.officialSocialMonth" @change="officialSocialMonthOption" placeholder="请选择" style="width:20%;">
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.socialDateType" placeholder="请选择" style="width:20%;">
                        <el-option label="准确日期" value="准确日期"></el-option>
                        <el-option label="模糊日期" value="模糊日期"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.socialAccurate" @change="socialAccurateOption" placeholder="请选择" v-if="this.editForm.socialDateType == '准确日期'" style="width:20%;margin-left:80px;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="editForm.socialFirstRange" placeholder="请选择" v-if="this.editForm.socialDateType == '模糊日期'" style="width:20%;margin-left:80px;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <span v-if="this.editForm.socialDateType == '模糊日期'">&nbsp;~&nbsp;</span>
                    <el-select v-model="editForm.socialSecondRange" @change="socialSecondRangeOption" placeholder="请选择" v-if="this.editForm.socialDateType == '模糊日期'" style="width:20%;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :required="true" label="社保出账日:"><br/>
                    <el-select v-model="editForm.socialOutDate" placeholder="请选择" style="width:20%;">
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.socialDate" placeholder="请选择" style="width:20%;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <span style="color:red;">&nbsp;账单日默认为官方操作截止日+2</span>
                </el-form-item>

                <el-form-item :required="true" label="公积金官方操作截止日:"><br/>
                    <el-select v-model="editForm.officialAccumulationMonth" @change="officialAccumulationMonthOption" placeholder="请选择" style="width:20%;">
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.accumulationDateType" placeholder="请选择" style="width:20%;">
                        <el-option label="准确日期" value="准确日期"></el-option>
                        <el-option label="模糊日期" value="模糊日期"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.accumulationAccurate" @change="accumulationAccurateOption" placeholder="请选择" v-if="this.editForm.accumulationDateType == '准确日期'" style="width:20%;margin-left:80px;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="editForm.accumulationFirstRange" placeholder="请选择" v-if="this.editForm.accumulationDateType == '模糊日期'" style="width:20%;margin-left:80px;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <span v-if="this.editForm.accumulationDateType == '模糊日期'">&nbsp;~&nbsp;</span>
                    <el-select v-model="editForm.accumulationSecondRange" @change="accumulationSecondRangeOption" placeholder="请选择" v-if="this.editForm.accumulationDateType == '模糊日期'" style="width:20%;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :required="true" label="公积金出账日:"><br/>
                    <el-select v-model="editForm.accumulationOutDate" placeholder="请选择" style="width:20%;">
                        <el-option label="上月" value="上月"></el-option>
                        <el-option label="当月" value="当月"></el-option>
                        <el-option label="次月" value="次月"></el-option>
                    </el-select>&nbsp;
                    <el-select v-model="editForm.accumulationDate" placeholder="请选择" style="width:20%;">
                        <el-option
                            v-for="item in dateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <span style="color:red;">&nbsp;账单日默认为官方操作截止日+2</span>
                </el-form-item>
            </el-form>
            <hr/><br/>
            <div style="text-align:right;">
                <el-button @click="editConfirm" type="primary">确定</el-button>
                <el-button @click="editClose">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "OperateDeadlineList",
        components: {},
        data: function () {
            return {
                filters:{
                    Province:'',
                    City:'',
                    Region:''
                },
                tableData: [],
                tableLoading:true,
                pageSize: 10,
                currentPage: 1,
                totalCount: 0,
                show: false,
                addCityDialog: false,
                editDialog: false,
                addCityForm: {
                    province:'',
                    city:'',
                    region:''
                },
                addCityRules: {
                    province:[{required:true,message:"省份不能为空",trigger:"change"}],
                    city:[{required:true,message:"城市不能为空",trigger:"change"}],
                    region:[{required:true,message:"区不能为空",trigger:"change"}]
                },
                provinceOptions: [],
                cityOptions: [],
                editForm: {
                    officialSocialMonth:'',
                    socialDateType:'',
                    socialAccurate:'',
                    socialFirstRange:'',
                    socialSecondRange:'',
                    socialOutDate:'',
                    socialDate:'',
                    officialAccumulationMonth:'',
                    accumulationDateType:'',
                    accumulationAccurate:'',
                    accumulationFirstRange:'',
                    accumulationSecondRange:'',
                    accumulationOutDate:'',
                    accumulationDate:''
                },
                dateOptions: [{
                    value: '1号',
                    label: '1号'
                },{
                    value: '2号',
                    label: '2号'
                },{
                    value: '3号',
                    label: '3号'
                },{
                    value: '4号',
                    label: '4号'
                },{
                    value: '5号',
                    label: '5号'
                },{
                    value: '6号',
                    label: '6号'
                },{
                    value: '7号',
                    label: '7号'
                },{
                    value: '8号',
                    label: '8号'
                },{
                    value: '9号',
                    label: '9号'
                },{
                    value: '10号',
                    label: '10号'
                },{
                    value: '11号',
                    label: '11号'
                },{
                    value: '12号',
                    label: '12号'
                },{
                    value: '13号',
                    label: '13号'
                },{
                    value: '14号',
                    label: '14号'
                },{
                    value: '15号',
                    label: '15号'
                },{
                    value: '16号',
                    label: '16号'
                },{
                    value: '17号',
                    label: '17号'
                },{
                    value: '18号',
                    label: '18号'
                },{
                    value: '19号',
                    label: '19号'
                },{
                    value: '20号',
                    label: '20号'
                },{
                    value: '21号',
                    label: '21号'
                },{
                    value: '22号',
                    label: '22号'
                },{
                    value: '23号',
                    label: '23号'
                },{
                    value: '24号',
                    label: '24号'
                },{
                    value: '25号',
                    label: '25号'
                },{
                    value: '26号',
                    label: '26号'
                },{
                    value: '27号',
                    label: '27号'
                },{
                    value: '28号',
                    label: '28号'
                },{
                    value: '29号',
                    label: '29号'
                },{
                    value: '30号',
                    label: '30号'
                },{
                    value: '31号',
                    label: '31号'
                }],
                provinceLoading: true,
                cityLoading: false,
                addShow: false,
                editShow: false,
            }
        },
        methods: {
            editClose(){  //操作截止日取消
                this.editDialog = false;
                this.editForm = {
                    officialSocialMonth:'',
                    socialDateType:'',
                    socialAccurate:'',
                    socialFirstRange:'',
                    socialSecondRange:'',
                    socialOutDate:'',
                    socialDate:'',
                    officialAccumulationMonth:'',
                    accumulationDateType:'',
                    accumulationAccurate:'',
                    accumulationFirstRange:'',
                    accumulationSecondRange:'',
                    accumulationOutDate:'',
                    accumulationDate:''
                };
            },
            editConfirm(){  //操作截止日确认
                if(this.editForm.officialSocialMonth == '' || this.editForm.socialDateType == '' || this.editForm.socialOutDate == '' || this.editForm.socialDate == '' 
                    || this.editForm.officialAccumulationMonth == '' || this.editForm.accumulationDateType == '' || this.editForm.accumulationOutDate == '' || this.editForm.accumulationDate == ''){
                    this.$message.error("数据未填写完整，请补充完整！");
                    return;
                }
                if(this.editForm.socialDateType == '准确日期'){
                    if(this.editForm.socialAccurate == ''){
                        this.$message.error("数据未填写完整，请补充完整！");
                        return;
                    }
                }
                if(this.editForm.accumulationDateType == '准确日期'){
                    if(this.editForm.accumulationAccurate == ''){
                        this.$message.error("数据未填写完整，请补充完整！");
                        return;
                    }
                }
                if(this.editForm.socialDateType == '模糊日期'){
                    if(this.editForm.socialFirstRange == '' || this.editForm.socialSecondRange == ''){
                        this.$message.error("数据未填写完整，请补充完整！");
                        return;
                    }
                    if(this.editForm.socialFirstRange != '' && this.editForm.socialSecondRange != ''){
                        let first = this.editForm.socialFirstRange.split('号')[0];
                        let second = this.editForm.socialSecondRange.split('号')[0];
                        if(parseInt(first) >= parseInt(second)){
                            this.$message.error("社保官方操作截止日模糊日期左边需小于右边！");
                            return;
                        }
                    }
                }
                if(this.editForm.accumulationDateType == '模糊日期'){
                    if(this.editForm.accumulationFirstRange == '' || this.editForm.accumulationSecondRange == ''){
                        this.$message.error("数据未填写完整，请补充完整！");
                        return;
                    }
                    let first = this.editForm.accumulationFirstRange.split('号')[0];
                    let second = this.editForm.accumulationSecondRange.split('号')[0];
                    if(parseInt(first) >= parseInt(second)){
                        this.$message.error("公积金官方操作截止日模糊日期左边需小于右边！");
                        return;
                    }
                }
                let row = this.$refs.tableData.selection[0];
                console.log(row);
                let params = {
                    id:row.id,
                    province:row.province,
                    city:row.city,
                    region:row.region,
                    socialOutAccount:this.editForm.socialOutDate + ' ' + this.editForm.socialDate,
                    accumulationOutAccount:this.editForm.accumulationOutDate + ' ' + this.editForm.accumulationDate,
                    creator:row.creator,
                    areaCode:row.areaCode,
                    code:row.code
                }
                if(this.editForm.socialDateType == '准确日期'){
                    params.officialSocialDeadline = this.editForm.officialSocialMonth + ' ' + this.editForm.socialAccurate;
                }
                if(this.editForm.socialDateType == '模糊日期'){
                    params.officialSocialDeadline = this.editForm.officialSocialMonth + ' ' + this.editForm.socialFirstRange + '~' + this.editForm.socialSecondRange;
                }
                if(this.editForm.accumulationDateType == '准确日期'){
                    params.officialAccumulationDeadline = this.editForm.officialAccumulationMonth + ' ' + this.editForm.accumulationAccurate;
                }   
                if(this.editForm.accumulationDateType == '模糊日期'){
                    params.officialAccumulationDeadline = this.editForm.officialAccumulationMonth + ' ' + this.editForm.accumulationFirstRange + '~' + this.editForm.accumulationSecondRange;
                }
                this.$api.OperateDeadline.EditSocialOperateDeadline(params).then(res => {
                    if(res){
                        this.$message.success('编辑成功！');
                        this.editDialog = false;
                        this.editForm = {
                            officialSocialMonth:'',
                            socialDateType:'',
                            socialAccurate:'',
                            socialFirstRange:'',
                            socialSecondRange:'',
                            socialOutDate:'',
                            socialDate:'',
                            officialAccumulationMonth:'',
                            accumulationDateType:'',
                            accumulationAccurate:'',
                            accumulationFirstRange:'',
                            accumulationSecondRange:'',
                            accumulationOutDate:'',
                            accumulationDate:''
                        };
                        this.getList();
                    }else{
                        this.$message.error('编辑失败！');
                        return;
                    }
                }).catch(() =>{
                    this.$message.error("系统出现错误，请联系管理员！");
                });
            },
            addCityClose(){  //添加配置取消
                this.addCityDialog = false;
                this.addCityForm = {
                    province:'',
                    city:'',
                    region:''
                };
                this.provinceOptions = [];
                this.cityOptions = [];
                this.$refs['addCityForm'].resetFields();
            },
            addCityConfirm(){  //添加配置确认
                this.$refs['addCityForm'].validate((valid) => {
                    if(valid){
                        let params = {
                            province: this.addCityForm.province,
                            city: this.addCityForm.city,
                            region: this.addCityForm.region,
                            // username: name
                        };
                        this.$api.OperateDeadline.AddAreaAndSocialOperateDeadline(params).then(res => {
                            if(res.code == 200){
                                this.$message.success(res.msg);
                                this.addCityDialog = false;
                                this.addCityForm = {
                                    province:'',
                                    city:'',
                                    region:''
                                };
                                this.provinceOptions = [];
                                this.cityOptions = [];
                                this.$refs['addCityForm'].resetFields();
                                this.resetFilter();
                            }else{
                                this.$message.error(res.msg);
                                return;
                            }
                        }).catch(() =>{
                            this.$message.error("系统出现错误，请联系管理员！");
                        });
                    }else{
                        this.$message.error("请完整填写资料！");
                        return;
                    }
                })
            },
            addConfig(){  //添加配置
                this.addCityDialog = true;
                this.$api.OperateDeadline.GetAreaData().then(data => {
                    data.forEach(x => {
                        let obj = {
                            label: x.areaName,
                            value: x.code
                        };
                        this.provinceOptions.push(obj);
                    })
                    this.provinceLoading = false;
                });
            },
            edit(){   //编辑操作截止日
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要编辑的数据！",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if(selection.length > 1){
                    this.$message({
                        message: "请选择一条数据进行编辑！",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                let row = this.$refs.tableData.selection[0];
                if((row.officialSocialDeadline != '' && row.officialSocialDeadline != null) && (row.officialAccumulationDeadline != '' && row.officialAccumulationDeadline != null)){
                    this.editForm.officialSocialMonth = row.officialSocialDeadline.split(' ')[0];
                    this.editForm.officialAccumulationMonth = row.officialAccumulationDeadline.split(' ')[0];
                    if(row.officialSocialDeadline.search('~') != -1){
                        this.editForm.socialDateType = '模糊日期';
                        this.editForm.socialFirstRange = row.officialSocialDeadline.split(' ')[1].split('~')[0];
                        this.editForm.socialSecondRange = row.officialSocialDeadline.split(' ')[1].split('~')[1];
                    }else{
                        this.editForm.socialDateType = '准确日期';
                        this.editForm.socialAccurate = row.officialSocialDeadline.split(' ')[1];
                    }
                    if(row.officialAccumulationDeadline.search('~') != -1){
                        this.editForm.accumulationDateType = '模糊日期';
                        this.editForm.accumulationFirstRange = row.officialAccumulationDeadline.split(' ')[1].split('~')[0];
                        this.editForm.accumulationSecondRange = row.officialAccumulationDeadline.split(' ')[1].split('~')[1];
                        console.log(this.editForm.accumulationFirstRange);
                        console.log(this.editForm.accumulationSecondRange);
                    }else{
                        this.editForm.accumulationDateType = '准确日期';
                        this.editForm.accumulationAccurate = row.officialAccumulationDeadline.split(' ')[1];
                    }
                }
                if((row.socialOutAccount != '' && row.socialOutAccount != null) && (row.accumulationOutAccount != '' && row.accumulationOutAccount != null)){
                    this.editForm.socialOutDate = row.socialOutAccount.split(' ')[0];
                    this.editForm.socialDate = row.socialOutAccount.split(' ')[1];
                    this.editForm.accumulationOutDate = row.accumulationOutAccount.split(' ')[0];
                    this.editForm.accumulationDate = row.accumulationOutAccount.split(' ')[1];
                }
                this.editDialog = true;
            },
            del(){  //删除
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要删除的数据！",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                let dtos = [];
                selection.forEach(x => {
                    let obj = {
                        areaName: x.province + x.city + x.region,
                        code: x.code
                    };
                    dtos.push(obj);
                })
                this.$confirm("是否删除数据？", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning",
                }).then(() => {
                    this.$api.OperateDeadline.DeleteAreaAndSocialOperateDeadline(dtos).then(res => {
                        if(res.code == 200){
                            this.$message.success(res.msg);
                            this.getList();
                        }else{
                            this.$message.error(res.msg);
                            this.getList();
                        }
                    });
                }).catch(() => {
                    return false;
                });
            },
            searchData(){   //查询
                this.pageSize = 10;  
                this.currentPage = 1;
                this.totalCount = 0;
                this.getList();
            },
            resetFilter(){   //重置
                this.filters = {
                    Province:'',
                    City:'',
                    Region:''
                };
                this.pageSize = 10;
                this.currentPage = 1;
                this.totalCount = 0;
                this.getList();
            },
            indexMethod(index) { //实现表格序号自增
                let curpage = this.currentPage;
                let limitpage = this.pageSize;
                return (index + 1) + (curpage - 1) * limitpage;
            },
            async getList() { //获取申报字段列表
                let params = {
                    pageCount: this.currentPage,
                    takeCount: this.pageSize,
                    province: this.filters.Province,
                    city : this.filters.City,
                    region : this.filters.Region
                };
                this.tableLoading = true;
                try {
                    let res = await this.$api.OperateDeadline.GetAll(params);
                    if (res) {
                        this.totalCount = res.totalCount;
                        this.tableData = res.item;
                    } else {
                        this.$message.error("查询出错");
                    }
                    this.tableLoading = false;
                } catch (e) {
                    this.tableLoading = false;
                }
            },
            handleSizeChange(pageSize) {
                // 每页条数改变
                this.pageSize = pageSize;
                this.getList();
            },
            handleCurrentChange(currentPage) {
                // 当前页码改变
                this.currentPage = currentPage;
                this.getList();
            },
            provinceOption(val){
                this.cityLoading = true;
                this.cityOptions = [];
                this.addCityForm.city = '';
                let params = {
                    parentCode: val
                };
                this.$api.OperateDeadline.GetAreaData(params).then(data => {
                    data.forEach(x => {
                        let obj = {
                            label: x.areaName,
                            value: x.code
                        };
                        this.cityOptions.push(obj);
                    })
                    this.cityLoading = false;
                }).catch(() =>{
                    this.$message.error("数据出现错误，请联系管理员！");
                });
            },
            officialSocialMonthOption(val){
                this.editForm.socialOutDate = val;
            },
            socialAccurateOption(val){
                val = parseInt(val.split('号')[0]) + 2;
                if(val > 31){
                    val = 31;
                }
                this.editForm.socialDate = val + "号";
            },
            socialSecondRangeOption(val){
                val = parseInt(val.split('号')[0]) + 2;
                if(val > 31){
                    val = 31;
                }
                this.editForm.socialDate = val + "号";
            },
            officialAccumulationMonthOption(val){
                this.editForm.accumulationOutDate = val;
            },
            accumulationAccurateOption(val){
                val = parseInt(val.split('号')[0]) + 2;
                if(val > 31){
                    val = 31;
                }
                this.editForm.accumulationDate = val + "号";
            },
            accumulationSecondRangeOption(val){
                val = parseInt(val.split('号')[0]) + 2;
                if(val > 31){
                    val = 31;
                }
                this.editForm.accumulationDate = val + "号";
            },
            async IsOperable(pid, userid) {
                try {
                    let res = await this.$api.authority.IsOperable(pid, userid);
                    if (pid == "OP100437") {
                        this.addShow = res;
                    }
                    if (pid == "OP100438") {
                        this.editShow = res;
                    }
                } catch (e) {
                    console.log(e);
                    this.$message.error("获取按钮权限失败！");
                }
            },
            getBtnAuth() {

                // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
                let userId = this.$cookies.get("userId");
                if (userId == null || userId == "") userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

                this.IsOperable("OP100437", userId);
                this.IsOperable("OP100438", userId);
            },
        },
        mounted() {
            this.getList();
            this.getBtnAuth();
        },
    };
</script>
<style src="@/assets/public.css"></style>
<style scoped>
    #OperateDeadlineList {
        padding: 20px;
    }

    .op-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .row-input {
        width: 240px;
        margin: 0 20px 0 10px;
    }

    .table-container {
        background: #fff;
        padding: 0 20px 20px;
    }

    .popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0,0,0,0.3);
    }
</style>
