<template>
  <div id="BusinessAcceptance">
    <div class="page-header">
      <p class="page-path">
        业务管理<span class="slashline">/</span>业务反馈列表
      </p>
      <p class="page-title">业务反馈列表</p>
    </div>
    <div class="operation-pannel">
      <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item label="所属单位：" prop="enterpriceName">
              <el-input
                v-model="filters.enterpriseName"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="员工姓名：" prop="name">
              <el-input
                v-model="filters.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="身份证：" prop="idCard">
              <el-input
                v-model="filters.idCard"
                placeholder="请输入身份证"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="5" style="padding-left: 50px">
            <el-button
              type="primary"
              @click="searchList"
              :loading="queryLoading"
              >查询</el-button
            >
            <el-button
              type="default"
              @click="
                pageSize = 10;
                currentPage = 1;
                totalCount = 0;
                resetForm('filtersform');
              "
              :loading="queryLoading"
              >重置</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item label="托管网点：" prop="branch">
              <el-input
                v-model="filters.branch"
                placeholder="请输入网点名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="生效月份：">
              <el-date-picker
                value-format="yyyy-MM"
                v-model="filters.effectiveMonth"
                type="month"
                placeholder="选择月"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="反馈状态：" prop="feedBackStatus">
              <el-select
                v-model="filters.feedBackStatus"
                clearable
                placeholder="请选择"
              >
                <el-option label="待反馈" value="0"></el-option>
                <el-option label="成功" value="1"></el-option>
                <el-option label="失败" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="险种名称：" prop="insuranceName">
              <el-input
                v-model="filters.insuranceName"
                placeholder="请输入险种名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button
          type="primary"
          @click="handleSuccessfully"
          v-show="feedbackShow"
          >反馈成功</el-button
        >
        <el-button
          type="danger"
          plain
          @click="failedToHandle"
          v-show="feedbackShow"
          >反馈失败</el-button
        >
        <el-button type="default" v-show="feedbackShow" @click="showPendingFrom"
          >异常通知</el-button
        >
        <el-button type="default" v-show="feedbackShow" @click="exportExcel"
          >导出数据</el-button
        >
      </div>
      <el-table
        ref="OrderFeedbackTable"
        max-height="500"
        :data="OrderFeedbackTableData"
        border
        style="margin: 32px 0 30px"
        v-loading="queryLoading"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column label="反馈状态" prop="feedBackStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.feedBackStatus == 0">待反馈</span>
            <span v-if="scope.row.feedBackStatus == 1">反馈成功</span>
            <span v-if="scope.row.feedBackStatus == 2">反馈失败</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="剩余处理时间" prop="exportNum" width="80" >
                       <template scope="">
                                 <span style="color: red">11:22:33</span>
                        </template>
                 </el-table-column> -->
        <el-table-column
          label="反馈失败说明"
          prop="feedBackDetails"
        ></el-table-column>
        <el-table-column label="姓名" prop="name"> </el-table-column>
        <el-table-column label="身份证" prop="idCard" width="160">
        </el-table-column>
        <el-table-column label="所属单位" prop="enterpriseName" width="270">
        </el-table-column>
        <el-table-column
          label="托管网点"
          prop="branch"
          width="200"
        ></el-table-column>
        <el-table-column label="所属地区" prop="district"></el-table-column>
        <el-table-column label="申报类型" width="85" prop="applyType">
          <template slot-scope="scope">
            {{ getApplyType(scope.row.applyType) }}
          </template>
        </el-table-column>
        <el-table-column label="险种名称" prop="insName"> </el-table-column>
        <el-table-column
          label="参缴类型"
          width="85"
          prop="insuranceType"
        ></el-table-column>
        <el-table-column label="申报基数" prop="applyBaseNum"></el-table-column>
        <el-table-column
          label="生效月份"
          prop="effectiveMonth"
        ></el-table-column>
        <el-table-column label="实缴月份" prop="paidMonth"></el-table-column>
        <el-table-column
          label="申报时间"
          prop="applyTime"
          width="120"
        ></el-table-column>
        <el-table-column
          label="受理时间"
          prop="processTime"
          width="120"
        ></el-table-column>
        <el-table-column
          label="反馈时间"
          prop="feedBackTime"
          width="120"
        ></el-table-column>
        <el-table-column label="异常通知状态" prop="pendingStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.pendingStatus == 0">未发送</span>
            <span v-if="scope.row.pendingStatus == 1">发送成功</span>
            <span v-if="scope.row.pendingStatus == 2">发送失败</span>
            <span v-if="scope.row.pendingStatus == 3">Bu已反馈</span>
          </template>
        </el-table-column>
        <el-table-column label="异常反馈" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.pendingStatus != 0"
              type="primary"
              @click="detail(scope.row.id)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000, 2000]"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="反馈失败"
      :visible.sync="failDialog"
      width="500px"
      @close="resetForm('fileDialogForm')"
    >
      <el-form
        :model="failDialogForm"
        ref="fileDialogForm"
        :rules="failDialogFormRules"
        label-width="100px"
      >
        <el-form-item label="失败原因：" prop="failReason">
          <el-select
            v-model="failDialogForm.failReason"
            placeholder="请选择失败原因"
          >
            <el-option label="资料不全" value="资料不全"></el-option>
            <el-option label="不符合资格" value="不符合资格"></el-option>
            <el-option label="其他原因" value="其他原因"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因说明：" prop="failDescribe">
          <el-input
            type="textarea"
            v-model="failDialogForm.failDescribe"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ImportDataDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="SubmitFailDialogForm('fileDialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="异常明细"
      :visible.sync="dialogDetail"
      width="60%"
      @close="detailClose"
    >
      <el-table :data="detailTable" style="min-height: auto !important" border>
        <el-table-column type="index" label="序号" align="center" width="50px">
        </el-table-column>
        <el-table-column prop="reason" label="异常发送情况"></el-table-column>
        <el-table-column prop="addTime" label="发起时间"></el-table-column>
        <el-table-column prop="deadLine" label="处理截止时间"></el-table-column>
        <el-table-column label="BU反馈情况">
          <template slot-scope="scope">
            {{ scope.row.buFeedBackMsg }}
            <a
              v-if="scope.row.fileUrl"
              :href="scope.row.fileUrl"
              :download="scope.row.fileName"
              >下载</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="feedBackTime"
          label="反馈时间"
          width="150"
        ></el-table-column>
        <!-- <el-table-column label="重新发送">
                  <template slot-scope="scope">
                    <span v-if="scope.row.canSend" @click="sendAgain(scope.row.id)">发送</span>
                    <span v-if="!scope.row.canSend">--</span>
                  </template>
                </el-table-column> -->
      </el-table>
    </el-dialog>

    <el-dialog
      title="异常情况通知"
      :visible.sync="dialogSendMsg"
      @close="cancelSend"
      width="40%"
    >
      <div class="row" style="margin-bottom: 10px">
        <p class="row" style="margin: 10px 10px 0 0">
          <span class="required">*</span>异常反馈理由：
        </p>
        <el-select
          v-model="reason"
          placeholder="--请选择异常反馈理由--"
          style="width: 250px; margin-top: 10px"
        >
          <el-option
            v-for="item in reasonOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="row" style="margin-bottom: 10px">
        <p class="row" style="margin-right: 10px">
          <span class="required">*</span>处理截止日期：
        </p>
        <el-date-picker
          v-model="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="年/月/日"
        >
        </el-date-picker>
      </div>
      <div class="row" v-if="reason != ''" style="margin-top: 10px">
        <p class="ass">异常情况说明：</p>
        <el-input type="textarea" :rows="2" placeholder="" v-model="explain">
        </el-input>
      </div>
      <div class="row" v-if="reason != ''">
        <p style="font-weight: bold">
          已选择 {{ itemNum }} 条，
          {{ personNum }} 人，请确认是否给员工对应BU发送异常通知？
        </p>
      </div>
      <span slot="footer">
        <el-button
          @click="DoPendingNotice"
          :loading="loadingSend"
          plain
          type="warning"
          size="small"
          >确 定</el-button
        >
        <el-button @click="cancelSend" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import kutabs from "@/components/kutabs";
export default {
  name: "AddNetwork",
  components: {
    kutabs,
  },
  data: function () {
    return {
      menuId: "",
      reasonOptions: [
        { value: "资料缺失", label: "资料缺失" },
        { value: "上家单位未停", label: "上家单位未停" },
        { value: "未转移", label: "未转移" },
      ],
      dialogDetail: false,
      Kutabs: [
        {
          name: "全部",
          num: 0,
        },
        {
          name: "社保新增",
          num: 0,
        },
        {
          name: "社保续保",
          num: 0,
        },
        {
          name: "社保停保",
          num: 0,
        },
        {
          name: "社保变更",
          num: 0,
        },
        {
          name: "社保补缴",
          num: 0,
        },
        {
          name: "社保退保",
          num: 0,
        },
        {
          name: "公积金新增",
          num: 0,
        },
        {
          name: "公积金调入",
          num: 0,
        },
        {
          name: "公积金封存",
          num: 0,
        },
        {
          name: "公积金变更",
          num: 0,
        },
        {
          name: "公积金补缴",
          num: 0,
        },
        {
          name: "公积金退保",
          num: 0,
        },
      ],
      queryLoading: false,
      feedbackShow: false,
      activeKutab: -1,
      filters: {
        enterpriseName: "",
        name: "",
        branch: "",
        effectiveMonth: "",
        feedbackResult: "",
        feedBackStatus: "0",
        insuranceName: "",
        idCard: "",
      },
      OrderFeedbackTableData: [],
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      failDialog: false, // 办理失败填写框
      failDialogForm: {
        failReason: "",
        failDescribe: "",
      },
      reason: "",
      datetime: "",
      explain: "",
      loadingSend: false,
      dialogSendMsg: false,
      itemNum: 0,
      personNum: 0,
      detailTable: [],
      failDialogFormRules: {
        failReason: [
          { required: true, message: "请选择失败原因", trigger: "change" },
        ],
      },
    };
  },
  async created() {
    let menuId = await this.$api.publicapi.getMenuId("托管业务管理-业务反馈");
    this.menuId = menuId;
  },
  methods: {
    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    getApplyType(applyType) {
      switch (applyType) {
        case 0:
          return "社保新增";
        case 1:
          return "社保续保";
        case 2:
          return "社保停保";
        case 3:
          return "社保变更";
        case 4:
          return "社保补缴";
        case 5:
          return "社保退保";
        case 6:
          return "公积金新增";
        case 7:
          return "公积金调入";
        case 8:
          return "公积金封存";
        case 9:
          return "公积金变更";
        case 10:
          return "公积金补缴";
        case 11:
          return "公积金退保";
        default:
          return "";
      }
    },
    tabChanged(params) {
      if (params.name == "全部") {
        this.activeKutab = -1;
      } else if (params.name == "社保新增") {
        this.activeKutab = 0;
      } else if (params.name == "社保续保") {
        this.activeKutab = 1;
      } else if (params.name == "社保停保") {
        this.activeKutab = 2;
      } else if (params.name == "社保变更") {
        this.activeKutab = 3;
      } else if (params.name == "社保补缴") {
        this.activeKutab = 4;
      } else if (params.name == "社保退保") {
        this.activeKutab = 5;
      } else if (params.name == "公积金新增") {
        this.activeKutab = 6;
      } else if (params.name == "公积金调入") {
        this.activeKutab = 7;
      } else if (params.name == "公积金封存") {
        this.activeKutab = 8;
      } else if (params.name == "公积金变更") {
        this.activeKutab = 9;
      } else if (params.name == "公积金补缴") {
        this.activeKutab = 10;
      } else if (params.name == "公积金退保") {
        this.activeKutab = 11;
      } else {
        this.activeKutab = -1;
      }
      this.searchList();
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getData();
    },
    handleSuccessfully() {
      // 办理成功
      let selection = this.$refs.OrderFeedbackTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待反馈'
        if (item.feedBackStatus != 0) {
          this.$message({
            message: "只能对处于“待反馈”的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      this.$confirm("确认所选的业务反馈成功?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api --> 受理业务
          this.successAPI(ids);
        })
        .catch(() => {});
    },
    failedToHandle() {
      // 办理失败
      let selection = this.$refs.OrderFeedbackTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待反馈'
        if (item.feedBackStatus != 0) {
          this.$message({
            message: "只能对处于“待反馈”的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      this.failDialog = true;
    },
    SubmitFailDialogForm(formName) {
      // 提交“办理失败”弹窗表格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let selection = this.$refs.OrderFeedbackTable.selection;
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          // api --> 办理失败
          this.failedAPI(ids);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.filters.feedBackStatus = "";
      this.searchList();
    },
    async successAPI(ids) {
      try {
        console.log(ids);
        let res = await this.$api.orderFeedback.success(ids);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.searchList();
        } else {
          this.$message.error("操作失败！");
        }
      } catch (e) {
        this.$message.error("操作失败！");
        console.log(e);
        this.$msgTotxt(e.data.msg, "S反馈错误信息.txt");
      }
    },
    async failedAPI(ids) {
      try {
        console.log(ids);
        let res = await this.$api.orderFeedback.fail({
          ids: ids,
          resaon: this.failDialogForm.failReason,
          desc: this.failDialogForm.failDescribe,
        });
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.failDialog = false;
          this.searchList();
        } else {
          this.$message.error("操作失败！");
        }
      } catch (e) {
        this.$message.error("操作失败！");
        console.log(e);
        this.$msgTotxt(e.data.msg, "F反馈错误信息.txt");
      }
    },
    searchList() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    exportExcel() {
      let selection = this.$refs.OrderFeedbackTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        ids.push(item.id);
      }
      this.$confirm("确认导出选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.orderFeedback.export({ ids: ids }).then((response) => {
            let fileName =
              "业务反馈" + new Date().toLocaleString("en-GB") + ".zip";
            this.$downloadByFileBytes(response, fileName);
            this.getData();
          });
        })
        .catch(() => {});
    },
    detail(id) {
      // 查看明细
      this.dialogDetail = true;
      this.$api.pendingNotice
        .SeenPendingNotice(id)
        .then((res) => {
          this.detailTable = [];
          res.data.forEach((v) => {
            if (v.buFeedBackMsg != null && v.buFeedBackMsg != "") {
              this.detailTable.push({
                accountId: "",
                accountName: "",
                addTime: "",
                applyType: -1,
                batchNumber: "",
                buFeedBackMsg: v.buFeedBackMsg,
                categoryId: v.categoryId,
                city: "",
                deadLine: "",
                description: "",
                editor: "admin",
                feedBackTime: v.feedBackTime,
                fileName: v.fileName,
                fileUrl: v.fileUrl,
                id: v.id,
                idCard: v.idCard,
                insuranceName: "",
                month: "",
                reason: "",
                sendTime: "",
                canSend: false,
              });
            }
            v.canSend = v.buFeedBackMsg == null || v.buFeedBackMsg == "";
            v.feedBackTime = null;
            v.buFeedBackMsg = "";
            v.fileName = "";
            v.fileUrl = "";
            this.detailTable.push(v);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detailClose() {
      this.detailTable = [];
    },
    // 关闭异常通知对话框
    cancelSend() {
      this.reason = "";
      this.datetime = "";
      this.explain = "";
      this.dialogSendMsg = false;
    },
    // 请求发送通知
    DoPendingNotice() {
      if (this.datetime == null || this.datetime === "") {
        this.$message.error("处理截止日期不能为空!");
        return;
      }
      if (this.reason == null || this.reason === "") {
        this.$message.error("请选择异常反馈理由!");
        return;
      }
      let data = {};
      let dateStr = this.datetime;
      let categoryIds = [];
      let multipleSelection = this.$refs.OrderFeedbackTable.selection;
      for (var i = 0; i < multipleSelection.length; i++) {
        categoryIds.push(multipleSelection[i].id);
      }

      data = {
        categoryIds: categoryIds,
        materialApplyType: -1,
        accountId: "小库",
        reason: this.reason,
        description: this.explain,
        deadLine: dateStr,
        userName:
          this.$cookies.get("userId") ?? "e92983fd6e4c4f598b1e7dfb9d6365d1",
        concatFieldDossiers: [],
      };

      this.loadingSend = true;
      this.$api.pendingNotice
        .DoPendingNotice(data)
        .then((res) => {
          this.loadingSend = false;
          console.log(res);
          this.$message({ message: "操作成功", type: "success" });
          this.Trigger(categoryIds);
          this.getData();
          this.cancelSend();
        })
        .catch((err) => {
          this.$message.error("发送失败，请稍后重试!");
          this.loadingSend = false;
          console.log(err);
        });
    },
    showPendingFrom() {
      // 异常情况通知
      let list = this.$refs.OrderFeedbackTable.selection;
      if (list.length == 0) {
        // 无选择，提示请选择
        this.$message({ message: "请勾选需要通知的数据项！", type: "warning" });
        return false;
      }
      let nameSet = new Set();
      for (let i = 0; i < list.length; i++) {
        if (list[i].feedBackStatus != 0) {
          this.$message({
            message: "只能选择“待反馈”的数据项！",
            type: "warning",
          });
          return false;
        }
        nameSet.add(list[i].idCard);
      }
      this.itemNum = list.length;
      this.personNum = nameSet.size;
      this.dialogSendMsg = true;
    },
    async getData() {
      let listParams = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        idCard: this.filters.idCard,
        name: this.filters.name,
        enterpriseName: this.filters.enterpriseName,
        branch: this.filters.branch,
        customer: this.filters.customer,
        feedBackStatus: this.filters.feedBackStatus,
        effectiveMonth: this.filters.effectiveMonth,
        insuranceName: this.filters.insuranceName,
      };
      if (this.activeKutab != -1) {
        listParams.applyType = this.activeKutab;
      }
      try {
        this.queryLoading = true;
        let listData = await this.$api.orderFeedback.list(listParams);
        this.totalCount = listData.data.totalCount;
        this.OrderFeedbackTableData = listData.data.item;
        this.queryLoading = false;
      } catch (err) {
        console.log(err);
        this.queryLoading = false;
      }
    },
    async getStat() {
      let stat = await this.$api.orderFeedback.stat();
      let arr = [];
      for (let i of stat.data) {
        arr.push({ name: i.name, num: i.count, applyType: i.applyType });
      }
      this.Kutabs = arr;
    },
    async IsOperable(pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid);
        this.feedbackShow = res;
      } catch (e) {
        console.log(e);
        this.$message.error("获取按钮权限失败！");
      }
    },
    async Trigger(cIds){
      await this.$api.orderFeedback.trigger(cIds);
    },
    getBtnAuth() {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get("userId");
      if (userId == null || userId == "")
        userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

      this.IsOperable("OP100354", userId);
    },
  },
  mounted() {
    this.getData();
    this.getStat();
    this.getBtnAuth();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#BusinessAcceptance {
  padding: 20px;
}

#BusinessAcceptance .operation-pannel {
  padding-top: 0;
}

.required {
  color: red;
}
</style>
