<!-- 发票详情的编辑框 -->
<template slot="title">
  <!-- title下面的主要内容 -->
  <div>
    <el-form
      ref="addForm"
      label-suffix="："
      label-width="120px"
      :model="form"
      :rules="addRules"
    >
      <hr />
      <br />
      <el-form-item label="托管类型" prop="TrusteeshipType" >
        <el-radio
          disabled
          v-model="form.TrusteeshipType"
          label="1"
          v-if="trusteeshipType ==1"
          >社保托管</el-radio
        >
        <el-radio
          disabled
          v-model="form.TrusteeshipType"
          label="2"
          v-if="trusteeshipType == 2"
          >公积金托管</el-radio
        >
      </el-form-item>
      <el-form-item label="所属单位" prop="EnterpriseId">
        <el-select
          @change="handleEnterpriseChange"
          v-model="form.EnterpriseId"
          filterable
          placeholder="请选择"
          style="width: 50%"
        >
          <el-option
            v-for="item in selectenterprises"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="托管网点" prop="BranchId">
        <el-input
          disabled
          v-model="form.Branch"
          :value="form.BranchId"
          style="width: 50%"
        ></el-input>
      </el-form-item>
      <el-form-item label="参缴城市" prop="InsuredCity">
        <el-input
          v-model="form.InsuredCity"
          placeholder="请输入参缴城市"
          style="width: 50%"
        ></el-input>
      </el-form-item>
      <el-form-item label="托管生效月份" prop="EntrustEffectiveMonth">
        <el-date-picker
          :picker-options="pickerOptions"
          style="width: 50%"
          v-model="form.EntrustEffectiveMonth"
          type="month"
          placeholder="请选择托管生效月份"
          @change="handlePeriodChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form ref="ImportDataForm" label-width="100px">
        <el-form-item label="Excel表格" prop="File">
          <a style="color: #409eff; cursor: pointer" @click="downloadTemplate"
            >下载导入模板</a
          >
          <el-upload
            class="upload-demo"
            action="/"
            :limit="limitFile"
            :on-remove="handleRemove"
            :auto-upload="false"
            :on-change="onChangeFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传.xls|.xlsx格式文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form-item label="相关凭证信息" prop="Attachments">
        <template #label>
          <span style="color: red">*</span> 相关凭证信息
        </template>
        <el-upload
          action="/"
          :auto-upload="false"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove1"
          :on-change="onChangeFile1"
          :file-list="fileList1"
        >
          <i class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              style="width: 100%; height: 100%"
              v-if="isPDF(file)"
              src="@/assets/fileassets/pdf.png"
            />
            <img
              style="width: 100%; height: 100%"
              v-else-if="isXls(file)"
              src="@/assets/fileassets/excel.png"
              alt="Excel"
            />
            <img
              style="width: 100%; height: 100%"
              v-else
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>

              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove1(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
      <!-- 图片缩略图 -->
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
        <!-- <img  height="500px"  :src="dialogImageUrl" alt="" /> -->
        <iframe
          style="width: 100%; height: 500px"
          :src="dialogImageUrl"
          frameborder="0"
        ></iframe>
      </el-dialog>
    </el-form>
    <hr />
    <br />
    <div style="text-align: center">
      <el-button @click="handleCancel">取消</el-button>
      <el-button @click="handleok" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
export default {
  props: {
    trusteeshipType: {
      type: [Number, String],
      required: true
    },
    entrustType: {
      type: [Number, String],
      required: true
    },
    id: {
      type: [Number, String]
      // required: true,
    },
    isEdit: {
      type: Boolean
      // required: true
    },
    isOnlyRead: {
      type: Boolean,
      required: false,
      default: false
    },
    formData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      pickerOptions: {
        // 设置可选的起始日期为当前月份的开始
        disabledDate (time) {
          return (
            time.getTime() <
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getTime()
          )
        }
      },
      isDisabled: false, //禁用
      dialogImageUrl: '',
      form: {
        TrusteeshipType: '',
        EnterpriseId: '',
        BranchId: '',
        InsuredCity: '',
        EntrustEffectiveMonth: '',
        File: [],
        Attachments: []
      },
      limitFile: 1, //允许上传最大文件数
      addRules: {
        EnterpriseId: [{ required: true, message: '请选择所属单位' }],
        InsuredCity: [
          { required: true, trigger: 'blur', message: '请输入参缴城市' },
          { min: 1, max: 20, message: '请输入1-20位以内的字符' }
        ],
        EntrustEffectiveMonth: [
          { required: true, trigger: 'blur', message: '请选择托管生效月份' }
        ],
      },
      fileList: [],
      fileList1: [],
      selectenterprises: [],
      selectbranchs: [],
      disabled: false,
      dialogVisible: false,
      showDialog: false,
      imgLoading: false,
      dialogView: {
        showDialog: false,
        isEdit: false,
        isOnlyRead: false,
        curId: 0,
        serviceFeeInvoiceType: '',
        curData: {}
      },
      dialogcode: {
        showDialog: false,
        isEdit: false,
        isOnlyRead: false,
        curId: 0,
        sellerTaxNo: '',
        QRcode: '',
        serviceFeeInvoiceType: '',
        curData: {}
      },
      loading: false
    }
  },

  created () {
    this.form.TrusteeshipType = this.trusteeshipType + ''
    this.loadselectenterprises()
  },
  methods: {
    isPDF (file) {
      return file.raw.type && file.raw.type.toLowerCase().includes('pdf')
    },
    isXls (file) {
      console.log('hhh', file)
      const fileType = file.raw.type && file.raw.type.toLowerCase()
      return fileType && fileType.includes('application')
    },
    // 缩略图
    handlePictureCardPreview (file) {
      if (file.raw.type.includes('application')) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = file.url
        document.body.appendChild(iframe)
      } else {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleRemove1 (file) {
      const index = this.fileList1.indexOf(file)
      if (index !== -1) {
        this.fileList1.splice(index, 1)
      }
    },
    handleExceed () {
      this.$message.warning(`一次只能选择单个文件上传`)
    },
    handleRemove () {
      this.fileList = []
    },

    onChangeFile1 (file, fileList) {
      const allowedExtensions = [
        'gif',
        'jpg',
        'jpeg',
        'png',
        'pdf',
        'xls',
        'xlsx'
      ]
      const extension = file.name.split('.').pop().toLowerCase()

      if (!allowedExtensions.includes(extension)) {
        const index = fileList.indexOf(file)
        if (index !== -1) {
          fileList.splice(index, 1)
        }
        this.$message({
          message: '请上传 .gif|.jpg|.jpeg|.png|.pdf|.xls|.xlsx',
          type: 'error'
        })
      } else {
        this.fileList1.push(file)
      }
    },
    onChangeFile (file, fileList) {
      console.log('123', file)
      let f = file.name.split('.').pop()
      if (f != 'xls' && f != 'xlsx') {
        fileList = []
        this.fileList = []
        this.$message({
          message: '请上传 .xls|.xlsx 格式的文件',
          type: 'error'
        })
      } else {
        this.fileList = fileList.length > 0 ? [file.raw] : []
      }
    },
    handleEnterpriseChange () {
      // 获取当前选择的单位
      const selectedEnterprise = this.selectenterprises.find(
        item => item.value === this.form.EnterpriseId
      )
      // 更新托管网点输入框的值
      if (selectedEnterprise) {
        this.form.BranchId = selectedEnterprise.branchId
        this.form.Branch = selectedEnterprise.branch
      } else {
        this.form.BranchId = '' // 如果未选择单位，则清空托管网点输入框的值
        this.form.Branch = ''
      }
    },
    async downloadTemplate () {
      // 调用 API 下载 Excel 表格
      let res = await this.$api.PersonnelUnderProtection.downmb()
      if (!res) {
        return false
      }
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = '参保人员名册导入模板.xlsx' //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    },

    // 时间
    handlePeriodChange (value) {
      if (value) {
        const year = value.getFullYear()
        const month = String(value.getMonth() + 1).padStart(2, '0')
        this.form.EntrustEffectiveMonth = `${year}-${month}`
      } else {
        this.form.EntrustEffectiveMonth = null
      }
    },
    loadselectenterprises() {
      debugger
    this.$api.PersonnelUnderProtection.getselectenterprises( this.entrustType)
      .then(response => {
        this.selectenterprises = response;
      })
      .catch(error => {
        this.$message.error('获取数据失败：' + (error.message || '未知错误'));
      });
  },
    handleok () {
      // 添加配置确认
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.fileList[0] == null) {
            this.$message.error(`请选择文件上传!`)
            return
          }
          if (this.fileList1.length == 0) {
            this.$message.error(`请上传凭证，不能为空!`)
            return
          }

          let formData = new FormData()
          formData.append('file', this.fileList[0])
          formData.append('TrusteeshipType', this.form.TrusteeshipType)
          formData.append('EnterpriseId', this.form.EnterpriseId)
          formData.append('BranchId', this.form.BranchId)
          formData.append('InsuredCity', this.form.InsuredCity)
          formData.append(
            'EntrustEffectiveMonth',
            this.form.EntrustEffectiveMonth
          )
          this.fileList1.forEach(item => {
            formData.append('Attachments', item.raw)
          })

          const loading = this.$loading({
            lock: true,
            text: '导入数据较多，正在处理中，请耐心等待',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          this.$api.PersonnelUnderProtection.addslist(formData)
            .then(res => {
              console.log('xians', res)
              if (res.type === 'text/plain') {
                const blob = new Blob([res])
                const downloadElement = document.createElement('a')
                const href = window.URL.createObjectURL(blob)
                downloadElement.style.display = 'none'
                downloadElement.href = href
                downloadElement.download = '参保人员名册导入模板.txt' // 下载后文件名
                document.body.appendChild(downloadElement)
                downloadElement.click() // 点击下载
                document.body.removeChild(downloadElement) // 下载完成移除元素
                window.URL.revokeObjectURL(href) // 释放掉 blob 对象
                loading.close()
                this.$alert(
                  '<span style="color: red;">注意: 数据未成功导入，请关注相关公司政策设置和导入数据信息（所属客户、社保参保类型和公积金参保类型等）是否正确。</span>',
                  '导入新增弹框',
                  {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true, // 允许使用 HTML 字符串
                    customClass: 'custom-alert' // 自定义样式类名
                  }
                )
              } else {
                loading.close()
                this.$alert('数据导入成功', '导入新增弹框', {
                  confirmButtonText: '确定',
                  dangerouslyUseHTMLString: true, // 允许使用 HTML 字符串
                  customClass: 'custom-alert' // 自定义样式类名
                })
                this.$emit('success')
              }
            })
            .catch(e => {
              let { data } = e
              if (!data == '') {
                console.log('{ data }', { data })
                if (data.type === 'application/json') {
                  const reader = new FileReader() // 创建 FileReader 实例
                  reader.readAsText(data, 'utf-8') // 读取文件, 用字符串显示
                  reader.onload = () => {
                    const msg = JSON.parse(reader.result).msg; 
                    // this.$message.error(JSON.parse(reader.result).msg);
                    this.$alert(
                      // ' 导入失败，列表中存在待发送、待审核、审核通过的托管公司信息',
                      msg,
                      '导入新增弹框',
                      {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true, // 允许使用 HTML 字符串
                        customClass: 'custom-alert' // 自定义样式类名
                      }
                    )
                  }
                }

                loading.close()
              }
            })
        } else {
          this.$message.error('请完整填写资料！')
          return
        }
      })
    },
    // 取消弹框
    handleCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.el-dialog__header {
  padding: 10 0px;
}
.el-dialog__body {
  padding: 15px 21px;
}
.custom-alert .el-message-box__content {
  color: red;
}
.custom-alert .el-message-box__btns {
  text-align: center; /* 让按钮居中 */
}
</style>
