<template>
  <div id="OpenAccount">
    <div class="page-header">
      <p class="page-path">开户申报列表</p>
      <p class="page-title">开户申报列表</p>
    </div>
    <div class="operation-pannel">
      <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="开户单位名称：" prop="accountName">
              <el-input
                v-model="filters.accountName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请编号：" prop="applyCode">
              <el-input
                v-model="filters.applyCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户类型：" prop="applyType">
              <el-select
                v-model="filters.applyType"
                placeholder="请选择"
                clearable
              >
                <el-option label="社保账户" :value="0"></el-option>
                <el-option label="公积金账户" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="searchAccount">查询</el-button>
            <el-button type="default" @click="updateTableData()"
              >重置</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="所属客户：" prop="client">
              <el-select
                v-model="filters.client"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in customerList"
                  :label="item.lable"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户网点：" prop="branch">
              <el-input
                v-model="filters.branch"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资料是否回寄：" prop="isReturned">
              <el-select
                v-model="filters.isReturned"
                placeholder="请选择"
                clearable
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button type="primary" @click="AcceptBusiness" v-show="addShow"
          >受理业务</el-button
        >
        <el-button
          type="primary"
          plain
          @click="editUkeys"
          :loading="ukeyEditing"
          v-show="addShow"
          >编辑Ukey</el-button
        >
        <el-button
          type="primary"
          plain
          @click="BusinessSucceed"
          v-show="addShow"
          >办理成功</el-button
        >
        <el-button type="danger" plain @click="BusinessFailed" v-show="addShow"
          >办理失败</el-button
        >
        <el-button type="warning" plain @click="BusinessCancel" v-show="addShow"
          >取消办理</el-button
        >
        <el-button type="default" @click="MailMaterial" v-show="addShow"
          >资料回寄</el-button
        >
        <el-button type="default" @click="exportData">导出数据</el-button>
      </div>
      <el-table
        ref="openAccountTable"
        max-height="500"
        :data="openAccountTableData"
        border
        style="margin: 32px 0 30px"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column
          label="申请编号"
          prop="code"
          width="155"
        ></el-table-column>
        <el-table-column label="状态" prop="statusText"> </el-table-column>
        <el-table-column label="反馈结果说明" prop="failResson" width="150">
        </el-table-column>
        <el-table-column label="状态变更时间" prop="operationTime" width="120">
        </el-table-column>
        <el-table-column
          label="单位名称"
          prop="enterpriseName"
          width="180"
        ></el-table-column>
        <el-table-column
          label="开户类型"
          width="85"
          prop="typeText"
        ></el-table-column>
        <el-table-column label="所属客户" prop="customer"></el-table-column>
        <el-table-column
          label="开户网点"
          prop="branch"
          width="180"
        ></el-table-column>
        <el-table-column label="开户人员信息" width="120">
          <template slot-scope="scope">
            <el-button
              type="default"
              @click="AccountOpeningPersonnelInformation(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="开户资料" width="120">
          <template slot-scope="scope">
            <el-button
              type="default"
              @click="AccountOpeningInformation(scope.row)"
              >资料下载</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="业务申报时间"
          prop="creationTime"
          width="150"
        ></el-table-column>
        <el-table-column label="是否托管">
          <template slot-scope="scope">
            <div v-if="scope.row.isEntrusted">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column label="资料是否回寄">
          <template slot-scope="scope">
            <div v-if="scope.row.isReturned">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column label="资料邮寄往来" width="120">
          <template slot-scope="scope">
            <el-button type="default" @click="InformationMailing(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          width="120"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="办理失败"
      :visible.sync="BusinessFailedDialog"
      width="500px"
      @close="resetForm('BusinessFailedForm')"
    >
      <el-form
        :model="BusinessFailedForm"
        ref="BusinessFailedForm"
        :rules="BusinessFailedFormRules"
        label-width="100px"
      >
        <el-form-item label="失败原因：" prop="failReason">
          <el-select
            v-model="BusinessFailedForm.failReason"
            placeholder="请选择失败原因"
          >
            <el-option label="资料不全" value="资料不全"></el-option>
            <el-option label="不符合资格" value="不符合资格"></el-option>
            <el-option label="其他原因" value="其他原因"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因说明：" prop="failDescribe">
          <el-input
            type="textarea"
            v-model="BusinessFailedForm.failDescribe"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="BusinessFailedDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="SubmitBusinessFailedForm('BusinessFailedForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="取消办理"
      :visible.sync="BusinessCancelDialog"
      width="500px"
      @close="resetForm('BusinessCancelForm')"
    >
      <el-form
        :model="BusinessCancelForm"
        ref="BusinessCancelForm"
        :rules="BusinessCancelFormRules"
        label-width="100px"
      >
        <el-form-item label="失败原因：" prop="cancelReason">
          <el-select
            v-model="BusinessCancelForm.cancelReason"
            placeholder="请选择失败原因"
          >
            <el-option label="资料不全" value="资料不全"></el-option>
            <el-option label="不符合资格" value="不符合资格"></el-option>
            <el-option label="其他原因" value="其他原因"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因说明：" prop="cancelDescribe">
          <el-input
            type="textarea"
            v-model="BusinessCancelForm.cancelDescribe"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="BusinessCancelDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="SubmitBusinessCancelForm('BusinessCancelForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="资料回寄"
      :visible.sync="MailMaterialDialog"
      width="500px"
      @close="cancelMaillingMaterial"
    >
      <el-form
        :model="MailMaterialForm"
        ref="MailMaterialForm"
        :rules="MailMaterialFormRules"
        label-width="120px"
      >
        <el-form-item label="回寄单号：" prop="returnNoteNumber">
          <el-input
            placeholder="请输入"
            v-model="MailMaterialForm.returnNoteNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人姓名：" prop="recipientName">
          <el-input
            placeholder="请输入"
            v-model="MailMaterialForm.recipientName"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人电话：" prop="recipientPhone">
          <el-input
            placeholder="请输入"
            v-model="MailMaterialForm.recipientPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="MailMaterialForm.address"
          ></el-input>
        </el-form-item>

        <el-form-item label="回寄图片：" prop="fileName">
          <el-upload
            class="upload-demo"
            ref="upload"
            multiple
            action=""
            :on-remove="handleRemove"
            :on-change="onChangeFile"
            :file-list="fileList"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              支持拓展名：.rar .zip .doc .docx .pdf .jpg...
            </div>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item label="回寄图片：" prop="fileName">
                  <el-button icon="el-icon-upload2" @click="uploadMailFile"
                    >上传文件</el-button
                  >
                  <p class="uploadTips">
                    支持拓展名：.rar .zip .doc .docx .pdf .jpg...
                  </p>
                  <input
                    type="file"
                    hidden
                    id="mailFile"
                    ref="mailFile"
                    @change="getUploadMailFile"
                  />
                  <span>{{ MailMaterialForm.fileName }}</span>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    class="delUploadFileIcon"
                    v-if="MailMaterialForm.fileName != ''"
                    @click="delUploadFile"
                  ></el-button>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quitOperation">取 消</el-button>
        <el-button
          type="primary"
          @click="SubmitMailMaterialForm('MailMaterialForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="在保人员信息"
      :visible.sync="InsuranceMemberInfo"
      width="750px"
      @closed="
        InsuranceMemberInfoTableData = [];
        memberInfoDownloadId = '';
      "
    >
      <el-button
        type="primary"
        plain
        :data-downloadId="memberInfoDownloadId"
        @click="exportMemberInfo"
        >下载文件</el-button
      >
      <el-table
        ref="InsuranceMemberInfoTable"
        max-height="500"
        :data="InsuranceMemberInfoTableData"
        border
        style="margin: 16px 0 16px"
      >
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column
          label="身份证"
          prop="idCard"
          width="150"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="phone"
          width="120"
        ></el-table-column>
        <el-table-column label="申报类型">
          <template slot-scope="scope">
            {{ transferApplyType(scope.row.applyType) }}
          </template>
        </el-table-column>
        <el-table-column label="申报基数" prop="applyNum"></el-table-column>
        <el-table-column label="生效月份" prop="startMonth"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="InsuranceMemberInfo = false">取 消</el-button>
        <el-button type="primary" @click="InsuranceMemberInfo = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="资料邮寄往来"
      :visible.sync="InformationMailingDialog"
      width="500px"
      @close="InformationMailingDetail = []"
    >
      <div class="mailDetailInfo">
        <div
          class="eachMailInfo"
          v-for="(item, index) in InformationMailingDetail"
          :key="index"
        >
          <p class="mail-info" v-if="item.type === 0">
            客户邮寄单号：{{ item.trackingNo }}
          </p>
          <p class="mail-info" v-if="item.type === 1">
            回寄单号：{{ item.trackingNo }}
          </p>
          <p class="mail-info">收件人姓名：{{ item.name }}</p>
          <p class="mail-info">收件人联系方式：{{ item.phone }}</p>
          <p class="mail-info">详细地址：{{ item.address }}</p>
          <p class="mail-info">图片：</p>

          <div class="block" v-for="fit in item.snapshots" :key="fit">
            链接：<el-link type="success" :href="fit" target="_blank">{{
              fit
            }}</el-link>
            <el-image :src="fit"></el-image>
          </div>

          <p class="mail-info">物流信息：</p>
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in item.tracksJson"
              :key="index"
              :timestamp="
                commonjs.dateFtt('yyyy-MM-dd hh:mm', new Date(activity.time))
              "
            >
              {{ activity.station }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            InformationMailingDialog = false;
            InformationMailingDetail = [];
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            InformationMailingDialog = false;
            InformationMailingDetail = [];
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--编辑Ukey数据对话框-->
    <el-dialog
      title="编辑Ukey数据"
      :visible.sync="ukeyDialogVisible"
      @close="closeUkeyDialog"
      width="70%"
    >
      <el-form
        :model="ukeyForm.data"
        label-position="right"
        label-width="160px"
        ref="ukeyForm"
        class="basic-info-form"
      >
        <el-row
          :gutter="30"
          v-for="(item, index) in ukeyForm.data"
          :key="index"
        >
          <!--Ukey说明-->
          <el-col :span="7">
            <el-form-item
              label-width="80px"
              label="Ukey："
              :prop="'ukeyForm.data.' + index + '.ukey'"
              :rules="[
                { required: true, message: '请输入Ukey', trigger: 'blur' },
              ]"
            >
              <el-input v-model="item.ukey" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <!--Ukey账号-->
          <el-col :span="7">
            <el-form-item
              label-width="80px"
              label="账号："
              :prop="'ukeyForm.data.' + index + '.account'"
              :rules="[
                { required: true, message: '请输入账号', trigger: 'blur' },
              ]"
            >
              <el-input v-model="item.account" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <!--Ukey密码-->
          <el-col :span="7">
            <el-form-item
              label-width="80px"
              label="密码："
              :prop="'ukeyForm.data.' + index + '.password'"
              :rules="[
                { required: true, message: '请输入密码', trigger: 'blur' },
              ]"
            >
              <el-input v-model="item.password" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <!--操作按钮-->
          <el-col :span="3">
            <!--删除-->
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="delUkeys(index)"
            ></el-button>
            <!--新增-->
            <el-button
              v-if="index === 0"
              type="primary"
              icon="el-icon-plus"
              circle
              @click="addUkeys()"
            ></el-button>
          </el-col>
        </el-row>
      </el-form>
      <!--提交或关闭-->
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            ukeyDialogVisible = false;
            ukeyEditing = false;
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmUkeyData"
          :loading="confirmEditLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cookie from "js.cookie";
import kutabs from "@/components/kutabs";

export default {
  name: "AddNetwork",
  components: {
    kutabs,
  },
  data: function () {
    return {
      Kutabs: [
        {
          name: "全部",
          num: 0,
        },
        {
          name: "待受理",
          num: 0,
        },
        {
          name: "办理中",
          num: 0,
        },
        {
          name: "办理成功",
          num: 0,
        },
        {
          name: "办理失败",
          num: 0,
        },
        {
          name: "已取消",
          num: 0,
        },
      ],
      fileList: [],
      customerList: [],
      activeKutab: -1,
      addShow: false,
      filters: {
        accountName: "",
        applyCode: "",
        applyType: "",
        client: "",
        branch: "",
      },
      openAccountTableData: [],
      BusinessFailedDialog: false, // 办理失败填写框
      BusinessFailedForm: {
        failReason: "",
        failDescribe: "",
      },
      // 编辑UKey数据表单数据
      ukeyEditing: false,
      ukeyDialogVisible: false, // UkeyDialog的显示/隐藏
      confirmEditLoading: false,
      ukeyForm: {
        editId: "", // 选中的开户ID
        data: [
          {
            ukeyId: 0,
            entrustId: 0,
            entrustType: 0,
            ukey: "",
            account: "",
            password: "",
          },
        ],
      },
      BusinessFailedFormRules: {
        failReason: [
          { required: true, message: "请选择失败原因", trigger: "change" },
        ],
      },
      BusinessCancelDialog: false, // 取消办理填写框
      BusinessCancelForm: {
        cancelReason: "",
        cancelDescribe: "",
      },
      BusinessCancelFormRules: {
        cancelReason: [
          { required: true, message: "请选择失败原因", trigger: "change" },
        ],
        cancelDescribe: [{ required: false, message: "", trigger: "change" }],
      },
      MailMaterialDialog: false,
      MailMaterialForm: {
        returnNoteNumber: "",
        recipientName: "",
        recipientPhone: "",
        address: "",
        fileName: "",
        file: "",
      },
      MailMaterialFormRules: {
        returnNoteNumber: [
          { required: true, message: "请输入回寄单号", trigger: "blur" },
        ],
        recipientName: [
          { required: true, message: "请输入收件人姓名", trigger: "blur" },
        ],
        recipientPhone: [
          { required: true, message: "请输入收件人电话", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      InsuranceMemberInfo: false,
      InsuranceMemberInfoTableData: [],
      InformationMailingDialog: false,
      InformationMailingDetail: [],
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      memberInfoDownloadId: "", // 在保人员信息下载id
      menuId: "",
    };
  },
  methods: {
    exportData() {
      let selection = this.$refs.openAccountTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        ids.push(item.id);
      }
      this.$confirm("确认导出选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.accounts.export(ids).then((response) => {
            let fileName =
              "开户" + new Date().toLocaleString("en-GB") + ".xlsx";
            this.$downloadByFileBytes(response, fileName);
            //this.getData();
          });
        })
        .catch(() => {});
    },
    quitOperation() {
      (this.MailMaterialDialog = false), (this.fileList = []);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChangeFile(file, fileList) {
      this.fileList = fileList;
    },
    tabChanged(params) {
      if (params.name == "全部") {
        this.activeKutab = -1;
      } else if (params.name == "待受理") {
        this.activeKutab = 0;
      } else if (params.name == "办理中") {
        this.activeKutab = 1;
      } else if (params.name == "办理成功") {
        this.activeKutab = 2;
      } else if (params.name == "办理失败") {
        this.activeKutab = 3;
      } else if (params.name == "已取消") {
        this.activeKutab = 4;
      } else {
        this.activeKutab = -1;
      }
      this.searchAccount();
    },
    AccountOpeningPersonnelInformation(row) {
      // 开户人员信息
      this.InsuranceMemberInfo = true;
      this.memberInfoDownloadId = row.id;
      this.getPesonals(row.id);
    },
    AccountOpeningInformation(row) {
      // 开户资料
      console.log(row);
      //window.open(row.fileUrl)
      //下载方法2
      const link = document.createElement("a");
      link.setAttribute("download", row.branch + "开户资料");
      link.setAttribute("href", row.fileUrl);
      link.click();
    },
    InformationMailing(row) {
      // 资料邮寄往来
      this.InformationMailingDialog = true;
      this.getLogistics(row.id);
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getAccounts();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getAccounts();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    transferApplyType(applyType) {
      if (applyType == 0) {
        return "社保新增";
      } else if (applyType == 1) {
        return "社保续保";
      } else if (applyType == 6) {
        return "公积金新增";
      } else if (applyType == 7) {
        return "公积金续保";
      } else {
        return "";
      }
    },
    AcceptBusiness() {
      //办理状态枚举名：0 待受理；1 办理中；2 办理成功；3 办理失败；4 已取消；
      // 受理业务
      let selection = this.$refs.openAccountTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待受理'
        if (item.status != 0) {
          this.$message({
            message: "请选择“待受理”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("是否确认受理业务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api --> 受理业务
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          this.updateStatus(ids, 1);
        })
        .catch(() => {});
    },

    // 新增Ukey数据
    addUkeys() {
      this.ukeyForm.data.push({
        ukeyId: 0,
        entrustId: 0,
        ukey: "",
        account: "",
        password: "",
      });
    },
    // 编辑Ukey数据
    editUkeys() {
      let selection = this.$refs.openAccountTable.selection;
      // 判断table行是否被选中
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择开户申报数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // 判断是否有多条数据被选中
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "只能选中1条数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // 判断是否为办理成功
      if (selection[0].status == 2) {
        this.$message({
          message: "办理成功后不能再编辑Ukey数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // 获取开户申报单对应的Ukey数据，若不存在则创建一个初始化对象
      this.getUkeys(selection[0].id);
    },
    // 删除Ukey数据
    delUkeys(index) {
      if (index === 0) {
        return;
      }
      this.ukeyForm.data.splice(index, 1);
    },
    // 渲染Ukey初始数据
    async getUkeys(id) {
      try {
        this.ukeyEditing = true;
        let res = await this.$api.accounts.getUkeys(id);
        if (res) {
          // UkeyForm.Data赋值
          this.ukeyForm.editId = id;
          this.ukeyForm.data = res;
          // 显示Dialog
          this.ukeyDialogVisible = true;
        } else {
          this.$message.error("获取Ukey数据失败");
          this.ukeyEditing = false;
        }
      } catch (ex) {
        this.$message.error("获取Ukey数据失败：" + ex.data.msg);
        this.ukeyEditing = false;
      }
    },
    // 提交表单数据
    confirmUkeyData() {
      //let validRes = [];
      //// 验证表单数据
      //this.$refs.ukeyForm.Data.validate((valid) => {
      //    validRes.push(valid);
      //});
      //let res = validRes.every((item) => { return item; })
      this.postUkeys();
      //if (res)
      //else this.$message({ message: "请选择需要处理的数据", type: "warning", showClose: true });
    },
    // 提交Ukey数据
    async postUkeys() {
      try {
        this.confirmEditLoading = true;
        let id = this.ukeyForm.editId;
        let params = this.ukeyForm.data;
        // 提交编辑数据
        let $this = this;
        this.$api.accounts
          .postUkeys(id, params)
          .then(function () {
            $this.$message({ message: "编辑成功！", type: "success" });
            $this.ukeyEditing = false;
            $this.ukeyDialogVisible = false;
            $this.confirmEditLoading = false;
            $this.getAccounts();
          })
          .catch(function (err) {
            $this.ukeyEditing = false;
            $this.confirmEditLoading = false;
            $this.$message.error("编辑失败：" + err.data.msg);
          });
      } catch (result) {
        this.ukeyEditing = false;
        this.confirmEditLoading = false;
        this.$message.error("编辑失败！");
      }
    },
    // Dialog关闭事件
    closeUkeyDialog() {
      this.ukeyEditing = false;
      this.ukeyForm.editId = "";
    },

    BusinessSucceed() {
      // 办理成功
      let selection = this.$refs.openAccountTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'办理中'
        if (item.status != 1) {
          this.$message({
            message: "请选择“办理中”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("是否确认办理成功?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api --> 办理成功
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          this.updateStatus(ids, 2);
        })
        .catch(() => {});
    },
    BusinessFailed() {
      // 办理失败
      let selection = this.$refs.openAccountTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待受理'
        if (item.status != 1) {
          this.$message({
            message: "请选择“办理中”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.BusinessFailedDialog = true;
    },
    SubmitBusinessFailedForm(formName) {
      // 提交“办理失败”弹窗表格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let selection = this.$refs.openAccountTable.selection;
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          // api --> 办理失败
          this.updateStatus(ids, 3);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    BusinessCancel() {
      // 取消办理
      let selection = this.$refs.openAccountTable.selection;
      console.log(this.$refs.openAccountTable.selection);
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是“待受理”和“办理中”
        if (item.status != 0 && item.status != 1) {
          this.$message({
            message: "请选择“待受理” 或“办理中”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.BusinessCancelDialog = true;
    },
    SubmitBusinessCancelForm(formName) {
      // 提交“取消办理”弹窗表格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let selection = this.$refs.openAccountTable.selection;
          let ids = [];
          for (let i of selection) {
            ids.push(i.id);
          }
          // api --> 取消办理
          this.updateStatus(ids, 4);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    MailMaterial() {
      // 资料回寄
      let selection = this.$refs.openAccountTable.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.isReturned === true) {
          this.$message({
            message: "已资料回寄过的开户申请，不可再次资料回寄！",
            type: "warning",
            showClose: true,
          });
          return;
        }
      }
      this.MailMaterialDialog = true;
    },
    SubmitMailMaterialForm(formName) {
      // 提交“资料回寄”弹窗表格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // api --> 资料回寄
          this.logistic();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getAccounts() {
      let params = {
        Keyword: this.filters.accountName,
        Code: this.filters.applyCode,
        Type: this.filters.applyType,
        CustomerId: this.filters.client,
        Branch: this.filters.branch,
        PageCount: this.currentPage,
        TakeCount: this.pageSize,
        IsReturned: this.filters.isReturned,
      };
      if (this.activeKutab != -1) {
        params.Status = this.activeKutab;
      }
      try {
        let [listData, stat] = await Promise.all([
          this.$api.accounts.accounts(params),
          this.$api.accounts.count(),
        ]);
        this.totalCount = listData.totalCount;
        this.openAccountTableData = listData.item;
        this.Kutabs = [
          {
            name: "全部",
            num: stat.allNumber,
          },
          {
            name: "待受理",
            num: stat.ststus0Number,
          },
          {
            name: "办理中",
            num: stat.ststus1Number,
          },
          {
            name: "办理成功",
            num: stat.ststus2Number,
          },
          {
            name: "办理失败",
            num: stat.ststus3Number,
          },
          {
            name: "已取消",
            num: stat.ststus4Number,
          },
        ];
      } catch (e) {
        console.log(e);
      }
    },
    async getPesonals(id) {
      let params = {
        id: id,
      };
      try {
        let res = await this.$api.accounts.pesonals(params);
        this.InsuranceMemberInfoTableData = res;
      } catch (e) {
        console.log(e);
      }
    },
    async exportMemberInfo() {
      let ids = [];
      ids.push(this.memberInfoDownloadId);
      let params = ids;
      try {
        let res = await this.$api.accounts.export(params);
        console.log(res);
        if (!res) {
          return false;
        }
        const blob = new Blob([res]);
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.style.display = "none";
        downloadElement.href = href;
        downloadElement.download = "开户人员数据.xlsx"; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      } catch (e) {
        console.log(e);
      }
    },
    async getLogistics(id) {
      let params = {
        id: id,
      };
      try {
        let res = await this.$api.accounts.logistics(params);
        this.InformationMailingDetail = res;
      } catch (e) {
        console.log(e);
      }
    },
    async logistic() {
      let selection = this.$refs.openAccountTable.selection;
      let params = new FormData();
      if (this.fileList.length > 0) {
        this.fileList.forEach((element) => {
          params.append("Files", element.raw);
        });
      }
      params.append("AccountId", selection[0].id);
      params.append("Name", this.MailMaterialForm.recipientName);
      params.append("Phone", this.MailMaterialForm.recipientPhone);
      params.append("Address", this.MailMaterialForm.address);
      params.append("TrackingNo", this.MailMaterialForm.returnNoteNumber);
      try {
        let res = await this.$api.accounts.logistic(params);
        if (res) {
          this.$message({
            message: "回寄成功",
            type: "success",
          });
        }
        this.MailMaterialDialog = false;
        this.fileList = [];
      } catch (e) {
        console.log(e);
        this.$message.error("回寄失败");
        this.MailMaterialDialog = false;
        this.fileList = [];
      }
    },
    async updateStatus(ids, status) {
      let failReason = "";
      if (status == 4) {
        failReason =
          this.BusinessCancelForm.cancelDescribe == ""
            ? this.BusinessCancelForm.cancelReason
            : this.BusinessCancelForm.cancelReason +
              "," +
              this.BusinessCancelForm.cancelDescribe;
      } else if (status == 3) {
        failReason =
          this.BusinessFailedForm.failDescribe == ""
            ? this.BusinessFailedForm.failReason
            : this.BusinessFailedForm.failReason +
              "," +
              this.BusinessFailedForm.failDescribe;
      }
      let params = {
        lstId: ids,
        status: status,
        failResson: failReason,
      };
      try {
        let res = await this.$api.accounts.status(params);
        if (res) {
          if (status == 1) {
            this.$message({
              type: "success",
              message: "受理成功!",
            });
          } else if (status == 2) {
            this.$message({
              type: "success",
              message: "办理成功!",
            });
          } else if (status == 3) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs["BusinessFailedForm"].resetFields();
            this.BusinessFailedDialog = false;
          } else if (status == 4) {
            this.$message({
              type: "success",
              message: "已取消办理!",
            });
            this.$refs["BusinessCancelForm"].resetFields();
            this.BusinessCancelDialog = false;
          }
          this.updateTableData();
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.data.msg);
      }
    },
    searchAccount() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getAccounts();
    },
    updateTableData() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.totalCount = 0;
      this.$refs["filtersform"].resetFields();
      this.getAccounts();
    },
    uploadMailFile() {
      // 资料回寄上传文件
      this.$refs.mailFile.click();
    },
    getUploadMailFile(event) {
      let files = event.target.files;
      this.MailMaterialForm.fileName = files[0].name;
      this.MailMaterialForm.file = files[0];
    },
    delUploadFile() {
      this.MailMaterialForm.fileName = "";
      this.MailMaterialForm.file = "";
    },
    cancelMaillingMaterial() {
      this.$refs["MailMaterialForm"].resetFields();
      this.MailMaterialForm = {
        returnNoteNumber: "",
        recipientName: "",
        recipientPhone: "",
        address: "",
        fileName: "",
        file: "",
      };
    },
    async getCustomer() {
      try {
        let res = await this.$api.publicapi.customers();
        this.customerList = res;
      } catch (e) {
        console.log(e);
        this.$message.error("获取所属客户列表失败！");
      }
    },
    async IsOperable(pid, userid) {
      // try {
      //   let res = await this.$api.authority.IsOperable(pid, userid);
      //   this.addShow = res;
      // } catch (e) {
      //   console.log(e);
      //   this.$message.error("获取按钮权限失败！");
      // }
      console.log(pid + userid);
      this.addShow = true;
    },
    getBtnAuth() {
      this.IsOperable("OP100347", Cookie.get("userId"));
    },
  },
  mounted() {
    this.getAccounts();
    this.getCustomer();
    this.getBtnAuth();
  },
  async created() {
    let menuId =  await this.$api.publicapi.getMenuId("开户业务管理");
    this.menuId = menuId;
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#OpenAccount {
  padding: 20px;
}

#OpenAccount .operation-pannel {
  padding-top: 0;
}

.mailDetailInfo .eachMailInfo {
  border-bottom: 1px solid #ededed;
}

.eachMailInfo + .eachMailInfo {
  margin-top: 30px;
}

.mail-info {
  line-height: 20px;
  margin-bottom: 16px;
}

.mailDetailInfo {
  max-height: 500px;
  overflow-y: auto;
}

.delUploadFileIcon {
  margin-left: 16px;
}

.uploadTips {
  color: #888;
}
</style>
