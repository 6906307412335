import api from '../index'

export default {
    list(params) {
        return api.post('/personaltrace/list', params)
    },
    info(id) {
        return api.get('/personaltrace/traceinfo?id='+id)
    },
    uploadExcel(params){
        return api.post('/personaltrace/import', params,{
            'Content-Type':'multipart/form-data'
        })
    },
    getTmplExcel(){
        return api.getfile('/personaltrace/getTmplExcel')
    },
    downloadExcel(params){
        return api.postfile('/personaltrace/export', params)
    },
}
