import api from '../index'

export default {
    list(params) {
        return api.post('/orderfb/list', params)
    },
    stat() {
        return api.get('/orderfb/stat?seenHistory=false')
    },
    success(params) {
        return api.post('/orderfb/success', params)
    },
    fail(params) {
        return api.post('/orderfb/fail', params)
    },
    export(params) {
        return api.postfile('/orderfb/output', params)
    },
    trigger(params) {
        return api.post('/orderfb/trigger', params)
    }
}
