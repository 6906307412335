<template>
    <div id="ApplyList">
        <div class="page-header">
            <p class="page-path">
                业务管理<span class="slashline">/</span>托管账户管理
            </p>
            <p class="page-title">托管账户管理</p>
        </div>
        <div class="operation-pannel">
            <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
        </div>
        <div class="operation-pannel">
            <el-form :model="filters" class="filters-form" label-width="110px" ref="filtersform">
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item label="单位名称：" prop="EnterpriseName">
                            <el-input v-model="filters.EnterpriseName" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="托管类型：" prop="EntrustType">
                            <el-select v-model="filters.EntrustType" placeholder="请选择">
                                <el-option label="社保托管" value="0"></el-option>
                                <el-option label="公积金托管" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="所属客户：" prop="CustomerId">
                            <el-select v-model="filters.CustomerId" placeholder="请选择" clearable>
                                <el-option label="ERS" value="KH100010"></el-option>
                                <el-option label="FAR" value="KH100012"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="primary" @click="searchList">查询</el-button>
                        <el-button type="default" @click="
                            resetForm('filtersform');
                        currentPage = 1;
                        pageSize = 10;
                        totalCount = 0;
                        getData();
                        ">重置</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item label="托管网点：" prop="Branch">
                            <el-input v-model="filters.Branch" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                      <el-form-item label="所属地区：" prop="District">
                        <el-input
                          v-model="filters.District"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <div>
                <el-button type="primary" plain @click="editEntrust" :loading="editing" v-show="editShow">编辑</el-button>
                <el-button type="warning" plain @click="cancelEntrust" v-show="cancelShow">取消托管</el-button>
            </div>
            <el-table ref="AccountListTable" max-height="500" :data="AccountListTableData" border
                style="margin: 32px 0 30px" v-loading="AccountListTableLoading">
                <el-table-column type="selection" width="48"> </el-table-column>
                <el-table-column label="单位名称" prop="enterpriseName" width="180"></el-table-column>
                <el-table-column label="所属客户" prop="customer" width="150"></el-table-column>
                <el-table-column label="托管类型" prop="entrustTypeText"></el-table-column>
                <el-table-column label="账户状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isActive">有效</div>
                        <div v-else>无效</div>
                    </template>
                </el-table-column>
                <el-table-column label="托管状态" prop="entrustStatusText">
                </el-table-column>
                <el-table-column label="社保出账日" prop="socialBillDate">
                </el-table-column>
                <el-table-column label="社保扣款日" prop="socialDeductDate">
                </el-table-column>
                <el-table-column label="公积金出账日" width="105" prop="accBillDate">
                </el-table-column>
                <el-table-column label="公积金扣款日" width="105" prop="accDeductDate">
                </el-table-column>
                <el-table-column label="托管网点" width="190" prop="branch"> </el-table-column>
                <el-table-column label="开始托管时间" prop="beginTime">
                </el-table-column>
                <el-table-column label="结束托管时间" prop="endTime"> </el-table-column>
                <el-table-column label="操作" width="110">
                    <template slot-scope="scope">
                        <el-button type="default" @click="accountDetail(scope.row.id)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :total="totalCount" :page-size="pageSize" :current-page="currentPage"
                layout="prev, pager, next, sizes, jumper">
            </el-pagination>
        </div>
        <el-dialog title="编辑托管账户" :visible.sync="editEntrustDialog" width="80%" @closed="editEntrustDialogClosed">
            <el-collapse v-model="editEntrustDialogActiveNames">
                <el-collapse-item name="1" v-if="editingEntrustType == 0">
                    <template slot="title">
                        <div class="basic-info">社保信息</div>
                    </template>
                    <el-form :model="socialSecurityForm" label-position="right" label-width="160px" ref="socialSecurityForm"
                        class="basic-info-form" :rules="socialSecurityFormRules">
                        <el-row :gutter="30" v-for="(item, index) in socialSecurityForm.entrustForm.info" :key="index">
                            <el-col :span="6">
                                <el-form-item label-width="80px" label="Ukey：" :prop="'entrustForm.info.' + index + '.ukey'"
                                    :rules="[
                                        {
                                            required: true,
                                            message: '请输入Ukey',
                                            trigger: 'blur',
                                        },
                                    ]">
                                    <el-input v-model="item.ukey" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label-width="80px" label="账号："
                                    :prop="'entrustForm.info.' + index + '.account'" :rules="[
                                        { required: true, message: '请输入账号', trigger: 'blur' },
                                    ]">
                                    <el-input v-model="item.account" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label-width="80px" label="密码："
                                    :prop="'entrustForm.info.' + index + '.password'" :rules="[
                                        { required: true, message: '请输入密码', trigger: 'blur' },
                                    ]">
                                    <el-input v-model="item.password" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="danger" icon="el-icon-delete" circle
                                    @click="delEntrustInfo(index, 'socialSecurityForm')"></el-button>
                                <el-button v-if="index === 0" type="primary" icon="el-icon-plus" circle
                                    @click="addEntrustInfo('socialSecurityForm')"></el-button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="社保出账日：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="billMonth">
                                            <el-select v-model="socialSecurityForm.billMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="billDay">
                                            <el-select v-model="socialSecurityForm.billDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-form-item label="社保扣款日：" prop="deductDate">
                                    <el-select v-model="socialSecurityForm.deductDate" placeholder="请选择">
                                        <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                            :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="增员截止日(社保)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="addDeadlineMonth">
                                            <el-select v-model="socialSecurityForm.addDeadlineMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="addDeadlineDay">
                                            <el-select v-model="socialSecurityForm.addDeadlineDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-form-item label="减员截止日(社保)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="minusDeadlineMonth">
                                            <el-select v-model="socialSecurityForm.minusDeadlineMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="minusDeadlineDay">
                                            <el-select v-model="socialSecurityForm.minusDeadlineDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item name="1" v-if="editingEntrustType == 1">
                    <template slot="title">
                        <div class="basic-info">公积金政策</div>
                    </template>
                    <el-form :model="providentFundForm" label-position="right" label-width="160px" ref="providentFundForm"
                        class="basic-info-form" :rules="providentFundFormRules">
                        <el-row :gutter="30" v-for="(item, index) in providentFundForm.entrustForm.info" :key="index">
                            <el-col :span="7">
                                <el-form-item label="Ukey：" :prop="'entrustForm.info.' + index + '.ukey'" :rules="[
                                    {
                                        required: true,
                                        message: '请输入Ukey',
                                        trigger: 'blur',
                                    },
                                ]">
                                    <el-input v-model="item.ukey" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label-width="80px" label="账号："
                                    :prop="'entrustForm.info.' + index + '.account'" :rules="[
                                        { required: true, message: '请输入账号', trigger: 'blur' },
                                    ]">
                                    <el-input v-model="item.account" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label-width="80px" label="密码："
                                    :prop="'entrustForm.info.' + index + '.password'" :rules="[
                                        { required: true, message: '请输入密码', trigger: 'blur' },
                                    ]">
                                    <el-input v-model="item.password" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="danger" icon="el-icon-delete" circle
                                    @click="delEntrustInfo(index, 'providentFundForm')"></el-button>
                                <el-button v-if="index === 0" type="primary" icon="el-icon-plus" circle
                                    @click="addEntrustInfo('providentFundForm')"></el-button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="公积金出账日：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="billMonth">
                                            <el-select v-model="providentFundForm.billMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="billDay">
                                            <el-select v-model="providentFundForm.billDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-form-item label="公积金扣款日：" prop="deductDate">
                                    <el-select v-model="providentFundForm.deductDate" placeholder="请选择">
                                        <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                            :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="增员截止日(公积金)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="addDeadlineMonth">
                                            <el-select v-model="providentFundForm.addDeadlineMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="addDeadlineDay">
                                            <el-select v-model="providentFundForm.addDeadlineDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-form-item label="减员截止日(公积金)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="minusDeadlineMonth">
                                            <el-select v-model="providentFundForm.minusDeadlineMonth" placeholder="请选择"
                                                style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="minusDeadlineDay">
                                            <el-select v-model="providentFundForm.minusDeadlineDay" placeholder="请选择"
                                                style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 30" :key="index" :label="item"
                                                    :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="12">
                                <el-form-item label="单位缴费比例：">
                                    <div class="form-inline-group">
                                        <el-form-item prop="enterprisePercent">
                                            <el-input v-model="providentFundForm.enterprisePercent" placeholder="单位比例"
                                                style="width: 208px">
                                                <template slot="append">
                                                    %
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="个人缴费比例：">
                                    <div class="form-inline-group">
                                        <el-form-item prop="personPercent">
                                            <el-input v-model="providentFundForm.personPercent" placeholder="个人比例"
                                                style="width: 208px; margin-left: 4px">
                                                <template slot="append">
                                                    %
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editEntrustDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirmEdit" :loading="confirmEditLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// import Cookie from "js.cookie";
import kutabs from "@/components/kutabs";
export default {
    name: "AddNetwork",
    components: {
        kutabs,
    },
    data: function () {
        return {
            Kutabs: [
                {
                    name: "全部",
                    num: 0,
                },
                {
                    name: "托管中",
                    num: 0,
                },
                {
                    name: "待受理",
                    num: 0,
                },
                {
                    name: "办理中",
                    num: 0,
                },
                {
                    name: "已取消",
                    num: 0,
                },
            ],
            activeKutab: -1,
            filters: {
                EnterpriseName: "",
                EntrustType: "",
                CustomerId: "",
                Branch: "",
                District: "",
            },
            editShow: false,
            cancelShow: false,
            AccountListTableData: [],
            AccountListTableLoading: false,
            totalCount: 0,
            pageSize: 10,
            currentPage: 1,
            editEntrustDialog: false, // 编辑dialog
            editEntrustDialogActiveNames: ["1", "2"],
            editingEntrustType: 0, // 编辑的数据托管类型 0 社保 1 公积金
            editingId: "",
            editing: false,
            socialSecurityForm: {
                // 社保信息
                entrustType: 0,
                billMonth: "",
                billDay: "",
                deductDate: "",
                addDeadlineMonth: "",
                addDeadlineDay: "",
                minusDeadlineMonth: "",
                minusDeadlineDay: "",
                enterprisePercent: "",
                personPercent: "",
                entrustForm: {
                    // 托管资料
                    info: [
                        {
                            entrustId: 0,
                            ukey: "",
                            account: "",
                            password: "",
                        },
                    ],
                },
            },
            socialSecurityFormRules: {
                billMonth: [{ required: true, message: "请选择", trigger: "change" }],
                billDay: [{ required: true, message: "请选择", trigger: "change" }],
                deductDate: [{ required: true, message: "请选择", trigger: "change" }],
                addDeadlineMonth: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                addDeadlineDay: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                minusDeadlineMonth: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                minusDeadlineDay: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
            },
            providentFundForm: {
                // 社保信息
                entrustType: 1,
                billMonth: "",
                billDay: "",
                deductDate: "",
                addDeadlineMonth: "",
                addDeadlineDay: "",
                minusDeadlineMonth: "",
                minusDeadlineDay: "",
                enterprisePercent: "",
                personPercent: "",
                entrustForm: {
                    // 托管资料
                    info: [
                        {
                            entrustId: 0,
                            ukey: "",
                            account: "",
                            password: "",
                        },
                    ],
                },
            },
            providentFundFormRules: {
                billMonth: [{ required: true, message: "请选择", trigger: "change" }],
                billDay: [{ required: true, message: "请选择", trigger: "change" }],
                deductDate: [{ required: true, message: "请选择", trigger: "change" }],
                addDeadlineMonth: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                addDeadlineDay: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                minusDeadlineMonth: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                minusDeadlineDay: [
                    { required: true, message: "请选择", trigger: "change" },
                ],
                enterprisePercent: [
                    { required: true, message: "请输入单位比例", trigger: "change" },
                ],
                personPercent: [
                    { required: true, message: "请输入个人比例", trigger: "change" },
                ],
            },
            confirmEditLoading: false,
        };
    },
    methods: {
        tabChanged(params) {
            // 托管状态：1 托管中； 2 待受理； 3 办理中； 4 已取消
            if (params.name == "全部") {
                this.activeKutab = -1;
            } else if (params.name == "托管中") {
                this.activeKutab = 1;
            } else if (params.name == "待受理") {
                this.activeKutab = 2;
            } else if (params.name == "办理中") {
                this.activeKutab = 3;
            } else if (params.name == "已取消") {
                this.activeKutab = 4;
            }
            this.searchList();
        },
        handleSizeChange(pageSize) {
            // 每页条数改变
            this.pageSize = pageSize;
            this.getData();
        },
        handleCurrentChange(currentPage) {
            // 当前页码改变
            this.currentPage = currentPage;
            this.getData();
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        accountDetail(id) {
            // 查看详情
            this.$router.push({
                name: "HostingAccountDetail",
                params: {
                    id: id,
                },
            });
        },
        addEntrustInfo(formName) {
            if (formName == "socialSecurityForm") {
                this.socialSecurityForm.entrustForm.info.push({
                    entrustId: 0,
                    ukey: "",
                    account: "",
                    password: "",
                });
            } else {
                this.providentFundForm.entrustForm.info.push({
                    entrustId: 0,
                    ukey: "",
                    account: "",
                    password: "",
                });
            }
        },
        delEntrustInfo(index, formName) {
            if (index === 0) {
                return;
            }
            if (formName == "socialSecurityForm") {
                this.socialSecurityForm.entrustForm.info.splice(index, 1);
            } else {
                this.providentFundForm.entrustForm.info.splice(index, 1);
            }
        },
        editEntrust() {
            // 编辑
            let selection = this.$refs.AccountListTable.selection;
            if (selection == undefined || selection.length <= 0) {
                this.$message({
                    message: "请选择需要处理的数据",
                    type: "warning",
                    showClose: true,
                });
                return;
            }
            if (selection == undefined || selection.length > 1) {
                this.$message({
                    message: "请选择1条需要处理的数据",
                    type: "warning",
                    showClose: true,
                });
                return;
            }
            // 获取操作截止日api
            this.getEntrustDate(selection[0].id, selection[0].entrustType);
        },
        async getEntrustDate(id, entrustType) {
            try {
                this.editing = true;
                let res = await this.$api.entrustAccount.editData(id);
                if (res) {
                    let formData = {};
                    for (let i in res) {
                        if (i === "entrustDate") {
                            for (let j in res["entrustDate"]) {
                                formData[j] = res["entrustDate"][j];
                            }
                        } else if (i === "entrustUkeys") {
                            formData.entrustForm = {
                                info: res["entrustUkeys"],
                            };
                        }
                    }
                    if (entrustType == 0) {
                        this.socialSecurityForm = formData;
                    } else if (entrustType == 1) {
                        this.providentFundForm = formData;
                    } else {
                        this.$message.error("获取操作截止日失败！");
                    }
                    this.editingEntrustType = entrustType;
                    this.editingId = id;
                    this.editEntrustDialog = true;
                } else {
                    this.$message.error("获取操作截止日失败！");
                }
                this.editing = false;
            } catch (e) {
                console.log(e);
                if (e.data) {
                    this.$message.error(e.data.msg);
                } else {
                    this.$message.error("获取操作截止日失败！");
                }
                this.editing = false;
            }
        },
        confirmEdit() {
            let validRes = [];
            if (this.editingEntrustType == 0) {
                this.$refs.socialSecurityForm.validate((valid) => {
                    validRes.push(valid);
                });
            }
            if (this.editingEntrustType == 1) {
                this.$refs.providentFundForm.validate((valid) => {
                    validRes.push(valid);
                });
            }
            let res = validRes.every((item) => {
                return item;
            });
            if (res) {
                this.editAPI();
            } else {
                console.log("error submit!!");
            }
        },
        async editAPI() {
            try {
                this.confirmEditLoading = true;
                let params = {
                    id: this.editingId,
                    body: {
                        entrustDate: {},
                        entrustUkeys: [],
                    },
                };
                let formData =
                    this.editingEntrustType == 0
                        ? this.socialSecurityForm
                        : this.providentFundForm;
                for (let i in formData) {
                    if (i != "entrustForm") {
                        params.body.entrustDate[i] = formData[i];
                    } else {
                        params.body.entrustUkeys = formData["entrustForm"].info;
                    }
                }

                //  提交编辑数据
                let $this = this;
                this.$api.entrustAccount.editDataModify(params)
                    .then(function () {
                        $this.$message({
                            message: "编辑成功！",
                            type: "success",
                        });
                        $this.confirmEditLoading = false;
                        $this.editEntrustDialog = false;
                        $this.getData();
                    })
                    .catch(function (err) {
                        $this.$message.error("编辑失败：" + err.data.msg);
                        $this.confirmEditLoading = false;
                    });
            } catch (e) {
                console.log(e);
                this.$message.error("编辑失败！");
                this.confirmEditLoading = false;
            }
        },
        editEntrustDialogClosed() {
            // 编辑弹窗关闭
            this.editingEntrustType == 0
                ? this.resetForm("socialSecurityForm")
                : this.resetForm("providentFundForm");
            this.editingId = "";
        },
        async cancelEntrust() {
            // 取消托管 （托管状态：0 未托管；1 托管中；2 取消托管办理中；3 已取消）
            let selection = this.$refs.AccountListTable.selection;
            if (selection == undefined || selection.length <= 0) {
                this.$message({
                    message: "请选择需要处理的数据",
                    type: "warning",
                    showClose: true,
                });
                return;
            }
            if (selection == undefined || selection.length > 1) {
                this.$message({
                    message: "请选择1条需要处理的数据",
                    type: "warning",
                    showClose: true,
                });
                return;
            }
            for (let item of selection) {
                if (item.entrustStatus != 1) {
                    this.$message({
                        message: "请选择“托管中”托管状态的数据进行处理",
                        type: "error",
                        showClose: true,
                    });
                    return;
                }
            }

            try {
                var thas = this;
                thas.$api.entrustAccount.orderCount(selection[0].enterpriseId, selection[0].entrustType)
                    .then(function (res) {
                        let msg = "取消托管后，将不可申报次月的增减员业务，申报本月增减员业务不受影响，您还要继续吗？";
                        if (res != '' && res != null) {
                            var counts = res.split(",");
                            if (counts[1] != 0) {
                                msg = "待反馈数据" + counts[1] + "条，" + msg;
                            }
                            if (counts[0] != 0) {
                                msg = "待受理数据" + counts[0] + "条，" + msg;
                            }
                            thas.cancelEntrustAPIConfirm(selection[0].id, msg);
                        }
                        else {
                            console.log("");
                        }
                    })
                    .catch(function (e) {
                        console.log(e);
                    });

            } catch (e) {
                console.log(e);
            }
        },
        async changeStatus(id) {
            let params = {
                lstId: [id],
                applyStatus: 4,
            };
            try {
                let res = this.$api.entrustOrder.status(params);
                if (res) {
                    this.$message({
                        message: "取消托管成功！",
                        type: "success",
                    });
                    this.getData();
                }
            } catch (e) {
                console.log(e);
            }
        },

        async cancelEntrustAPIConfirm(id, msg) {
            this.$confirm(
                msg,
                "取消托管提醒",
                {
                    confirmButtonText: "继续",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                this.cancelEntrustAPI(id);
            }).catch(function (e) {
                console.log(e);
            });
        },

        async cancelEntrustAPI(id) {
            try {
                let res = this.$api.entrustAccount.cancel(id);
                if (res) {
                    this.$message({
                        message: "取消托管成功！",
                        type: "success",
                    });
                    this.getData();
                }
            } catch (e) {
                console.log(e);
            }
        },
        searchList() {
            this.currentPage = 1;
            this.pageSize = 10;
            this.getData();
        },
        async getData() {
            let listParams = {
                EnterpriseName: this.filters.EnterpriseName,
                EntrustType: this.filters.EntrustType,
                CustomerId: this.filters.CustomerId,
                Branch: this.filters.Branch,
                District: this.filters.District,
                PageCount: this.currentPage,
                TakeCount: this.pageSize,
            };
            if (this.activeKutab != -1) {
                listParams.EntrustStatus = this.activeKutab;
            }
            this.AccountListTableLoading = true;
            try {
                let [listData, stat] = await Promise.all([
                    this.$api.entrustAccount.accounts(listParams),
                    this.$api.entrustAccount.count(),
                ]);
                this.totalCount = listData.totalCount;
                this.AccountListTableData = listData.item;
                this.Kutabs = [
                    {
                        name: "全部",
                        num: stat.allNumber,
                    },
                    {
                        name: "托管中",
                        num: stat.status1Number,
                    },
                    {
                        name: "待受理",
                        num: stat.status2Number,
                    },
                    {
                        name: "办理中",
                        num: stat.status3Number,
                    },
                    {
                        name: "已取消",
                        num: stat.status4Number,
                    },
                ];
                this.AccountListTableLoading = false;
            } catch (err) {
                console.log(err);
                this.$message.error(err.msg);
                this.AccountListTableLoading = false;
            }
        },
        async IsOperable(pid, userid) {
            try {
                let res = await this.$api.authority.IsOperable(pid, userid);
                console.log(res);
                if (pid == "OP100350") {
                    this.editShow = res;
                }
                if (pid == "OP100351") {
                    this.cancelShow = res;
                }
            } catch (e) {
                console.log(e);
                this.$message.error("获取按钮权限失败！");
            }
        },

        getBtnAuth() {

            // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
            let userId = this.$cookies.get("userId");
            if (userId == null || userId == "") userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

            this.IsOperable("OP100350", userId);
            this.IsOperable("OP100351", userId);
        },
    },
    mounted() {
        this.getData();
        this.getBtnAuth();
    },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#ApplyList {
    padding: 20px;
}

#ApplyList .operation-pannel {
    padding-top: 0;
}

.mail-info {
    line-height: 20px;
    margin-bottom: 16px;
}

.basic-info {
    width: 100%;
    font-size: 14px;
    background: #fafafa;
    padding: 0 20px;
    border-radius: 2px 2px 0 0;
    border-bottom: 1px solid #ededed;
}

.basic-info-form {
    padding: 20px 20px 0;
}

.form-inline-group .el-form-item {
    display: inline-block;
    margin-bottom: 0;
}
</style>

