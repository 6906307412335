<template>
    <div id="ApplyFieldDetail">
        <div class="page-header">
            <p class="page-path">
                申报设置<span class="slashline">/</span>申报字段设置
            </p>
            <p class="page-title" v-if="this.type == 0">申报字段详情</p>
            <p class="page-title" v-if="this.type == 1">申报资料详情</p>
        </div>
        <div class="operation-pannel"><br/>
            <el-form ref="detailForm" :model="detailForm" label-width="80px">
                <el-row :gutter="16">
                    <el-col :offset="1">
                        <el-form-item label="生效时间:">
                            <el-date-picker v-model="detailForm.useTime" :disabled="true" type="date" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="1">
                        <el-form-item label="适用地区:">
                            <el-input type="textarea" :rows="3" placeholder="请添加适用地区" v-model="detailForm.addRegion" :readonly="true" style="width:30%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="1">
                        <el-form-item label="业务类型:" prop="operationType">
                            <el-select v-model="detailForm.operationType" :disabled="true" placeholder="请选择业务类型">
                                <el-option label="社保" :value="0"></el-option>
                                <el-option label="公积金" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="1">
                        <el-form-item label="审核意见:">
                            <el-input type="textarea" :rows="3" v-model="detailForm.remark" :readonly="true" style="width:30%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="table-container">
                <div style="background: #fafafa;border: 1px solid #ededed;height:48px;display: flex;align-items: center;">
                    <span v-if="this.type == 0">申报字段设置</span>
                    <span v-if="this.type == 1">申报资料设置</span>
                    <label style="margin-left:100px;" v-if="this.type == 0">字段名称:</label>
                    <label style="margin-left:100px;" v-if="this.type == 1">资料名称:</label>
                    <el-input v-model="fieldName" placeholder="请输入字段名称" style="width:250px;margin-left:15px;" v-if="this.type == 0"></el-input>
                    <el-input v-model="fieldName" placeholder="请输入资料名称" style="width:250px;margin-left:15px;" v-if="this.type == 1"></el-input>
                    <el-button @click="queryField" type="primary"  style="margin-left:50px;">查询</el-button>
                    <el-button @click="reset"  plain style="margin-left:50px;">重置</el-button>
                </div>
                <el-form ref="fieldListForm" :model="fieldListForm">
                    <el-table
                        ref="fieldListTable"
                        max-height="350"
                        :data="fieldListForm.fieldList"
                        border
                        style="width: 100%; margin-bottom: 30px"
                        v-loading="tableLoading">"
                        <el-table-column prop="applyFieldId" label="id" v-if="show" key="1"></el-table-column>
                        <el-table-column prop="applyTypeText" label="申报类型" key="2"></el-table-column>
                        <el-table-column prop="fieldName" label="字段名称" v-if="this.type == 0" key="3"></el-table-column>
                        <el-table-column prop="fieldName" label="资料名称" v-if="this.type == 1" key="3"></el-table-column>
                        <el-table-column prop="fieldType" label="字段类型" v-if="this.type == 0" key="4">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldType == 0">文本类</span>
                                <span v-else-if="scope.row.fieldType == 1">下拉选择类</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldType" label="资料类型" v-if="this.type == 1" key="4">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldType == 0">原件类</span>
                                <span v-else-if="scope.row.fieldType == 1">图像类</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="isRequired" v-if="true" label="是否必填" key="5">
                            <template slot-scope="scope">
                                <span v-if="scope.row.isRequired == 0">否</span>
                                <span v-else-if="scope.row.isRequired == 1">是</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldContent" label="字段内容" show-overflow-tooltip v-if="this.type == 0" key="6"></el-table-column>  
                        <el-table-column prop="fieldContent" label="资料要求" show-overflow-tooltip v-if="this.type == 1" key="6"></el-table-column>
                        <el-table-column prop="count" label="资料份数" v-if="this.type == 1" key="7"></el-table-column>
                        <el-table-column prop="fileType" label="文件格式" v-if="this.type == 1" key="8"></el-table-column>
                        <el-table-column prop="dataSampleUrl" label="资料样本" v-if="this.type == 1" key="9">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="downLoad(scope.row.dataSampleUrl)" v-if="scope.row.dataSampleUrl != null && scope.row.dataSampleUrl != ''">下载</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="emptyListUrl" label="空表上传" v-if="this.type == 1" key="10">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="downLoad(scope.row.emptyListUrl)" v-if="scope.row.emptyListUrl != null && scope.row.emptyListUrl != ''">下载</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
                <div style="text-align:center"><br/>
                    <el-button @click="returnList" >返回</el-button>
                </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "ApplyFieldDetail",
        components: {},
        data: function () {
            return {
                show:false,
                tableLoading:false,
                detailForm:{
                    useTime:'',
                    addRegion:'',
                    operationType:'',
                    remark:''
                },
                fieldName:'',
                fieldListForm:{
                    fieldList:[]
                },
                type:''
            };
        },
        methods: {
            returnList(){  //返回申报字段列表页面
                // let activeName = '';
                // if(this.type == 0){
                //     activeName = 'first';
                // }
                // if(this.type == 1){
                //     activeName = 'second';
                // }
                // this.$router.push({
                //     name: "ApplyFieldList",
                //     params: {
                //         type: this.type,
                //         activeName: activeName
                //     },
                // });
                this.$router.go(-1);  
            },
            queryField(){  //带条件查询子表数据
                this.getList();
            },
            reset(){  //重置数据
                this.fieldName ='';
                this.getList();
            },
            async getList(){  //获取子表数据
                let pm = this.$route.params.rowDetail;
                let params = {
                    applyFieldId: pm.id,
                    fieldName: this.fieldName
                }
                this.tableLoading = true;
                    try {
                        console.log(params);
                        let res = await this.$api.ApplyField.ApplyFieldDetailList(params);
                        console.log(res);
                        if (res) {
                            this.fieldListForm.fieldList = res;
                        } else {
                            this.$message.error("查询出错");
                        }
                        this.tableLoading = false;
                    } catch (e) {
                        console.log(e);
                        this.tableLoading = false;
                }
            },
            downLoad(row){  //下载文件
                if(row == null || row == ''){
                    this.$message({
                        message: "没有上传文件，请上传",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }else{
                    //下载方法2
                    const link = document.createElement('a')
                    link.setAttribute('href',row)
                    link.click()
                }
            }
        },
        mounted() {
            let pm = this.$route.params.rowDetail;  //获取从列表页面传来的主表数据
            this.detailForm = {
                useTime: pm.effectMonth,
                addRegion: pm.area,
                operationType: pm.operationType,
                remark : pm.remark
            }
            this.type = this.$route.params.type;  //记录类型为申报字段或申报资料
            this.getList();
        },
    };
</script>
<style src="@/assets/public.css"></style>
<style scoped>
    #ApplyFieldDetail {
        padding: 20px;
    }

    .op-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .row-input {
        width: 240px;
        margin: 0 20px 0 10px;
    }

    .table-container {
        background: #fff;
        padding: 0 20px 20px;
    }

    .popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0,0,0,0.3);
    }

    .control-panel {
        width: 100%;
        height: 48px;
        background: #fafafa;
        /* padding: 0 20px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #ededed;
        box-sizing: border-box;
    }
</style>