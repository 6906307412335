import api from '../index'

export default {
  count() { // 获取托管申报列表统计数据
    return api.get('/entrust/orders/count')
  },
  orders(params) { // 获取托管申报列表
    return api.get('/entrust/orders', params)
  },
  logistics(id) { // 获取邮寄往来信息
    return api.get('/entrust/orders/' + id + '/logistics')
  },
  ukey(id) { // 获取托管资料
    return api.get('/entrust/orders/' + id + '/ukeys')
  },
  nextId() { // 自动生成单位编号
    return api.get('/entrust/orders/nextId')
  },
  status(params) { // 更新托管申请状态
    return api.post('/entrust/orders/status', params)
  },
  create(params) { // 新增托管账户
    return api.post('/entrust/orders/create', params)
  },
  addLogistic(params) { // 填写邮寄资料
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return api.post('/entrust/orders/logistic', params, headers)
  },
}