<!-- 查看凭证信息的编辑框 -->
<template slot="title">
  <!-- title下面的主要内容 -->
  <div>
    <el-form>
      <br />
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="enterprise" label="所属单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="branch" label="托管网点" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="city" label="参缴城市" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="customerName" label="所属客户" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="initNum" label="初始人数" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="month" label="托管生效月份" show-overflow-tooltip> </el-table-column>
        <el-table-column
          prop="generatedTime"
          label="生成时间"
        >
        <template slot-scope="scope">
            {{ formatDateTime(scope.row.generatedTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
        >
        <template slot-scope="scope">
            {{ formatDateTime(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column prop="returnReasons" label="退回原因" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <br />
      <div>
        <div
          style="
            line-height: 24px;
            font-size: 18px;
            margin-top: 20px;
            color: #303133;
          "
        >
          客户初始员工信息
        </div>
        <div style="margin-top: 10px; margin-bottom: 10px">
          <div style="padding: 20px">
            <el-form
              ref="searchForm"
              :model="searchForm"
              :inline="true"
              :hide-required-asterisk="true"
            >
              <el-row style="display: flex">
                <el-col :span="6">
                  <el-form-item label="姓名：">
                    <el-input
                      v-model="searchForm.empoloyee"
                      placeholder="请输入员工姓名"
                      style="width: 250px"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="身份证：" style="margin-left: 30px">
                    <el-input
                      v-model="searchForm.idCard"
                      placeholder="请输入员工身份证信息"
                      style="width: 250px"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-button type="primary" @click="search"> 筛选 </el-button>
                  </el-form-item>
                  <el-form-item
                    ><el-button type="info" @click="resetFormData">
                      重置
                    </el-button></el-form-item
                  ></el-col
                >
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="tablemoney" style="margin-top: 15px">
          <el-table
            class="tablemoney"
            border-collapse="collapse"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="tableData1"
            row-key="id"
            ref="ArticleCommitTable"
            border
            :row-style="{ height: '35px' }"
            style="text-align: center"
            :header-cell-style="{ background: '#9abce6', color: '#000' }"
            :default-sort="{ prop: 'UserEmpiricalValue', order: 'descending' }"
          >
            <el-table-column type="selection" width="40px"></el-table-column>
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="80px"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.$index + 1 + (page.currentPage - 1) * page.pageSize
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="employee"
              label="姓名"
              width="120px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="idCard"
              align="center"
              width="200px"
              show-overflow-tooltip
              label="身份证号"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="手机号"
              prop="phone"
              width="160px"
              show-overflow-tooltip
              style="background:'#9acfe6"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="insuranceType"
              label="参保类型"
              width="150px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="200px"
              v-if="entrustType == '社保托管'"
              prop="applyBase"
              label="社保申报基数"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              width="200px"
              prop="applyBase"
              label="公积金申报基数"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-if="entrustType == '社保托管'"
              align="center"
              width="200px"
              prop="policyNumber"
              label="社保政策编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              width="200px"
              prop="policyNumber"
              label="公积金政策编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="anomalousCause"
              label="异常原因"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120px"
              class="tools"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    class="tools-a"
                    type="text"
                    @click="handleView1(scope.row)"
                  >
                    <span class="blue-col">查看详情</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页组件 -->
        <el-row
          type="flex"
          justify="center"
          align="middle"
          style="height: 60px"
        >
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
            :page-size="page.pageSize"
            :page-sizes="[5, 10, 20, 30]"
            :current-page="page.currentPage"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </el-row>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="handleCancel" type="info">返回</el-button>
      </div>
    </el-form>
    <el-dialog
      :visible.sync="dialogdetailsData.showDialog"
      width="60%"
      :append-to-body="true"
      :close-on-click-modal="false"
      left
      :close-on-press-escape="false"
    >
      <details-or-edit
        v-if="dialogdetailsData.showDialog"
        :id="dialogdetailsData.curId"
        :entrustType="dialogdetailsData.entrustType"
        :is-edit="dialogdetailsData.isEdit"
        :inv-check-status="dialogdetailsData.InvCheckStatus"
        :is-only-read="dialogdetailsData.isOnlyRead"
        :form-data="dialogdetailsData.curData"
        @success="dialogSuccess"
        @close="dialogClose"
      />
    </el-dialog>
  </div>
</template>
<script>
import detailsOrEdit from './detailsindetail.vue'
import moment from 'moment';
export default {
  components: {
    detailsOrEdit
  },
  props: {
    entrustType: {
      type: String
    },
    id: {
      type: [Number, String]
      // required: true,
    },
    isEdit: {
      type: Boolean
      // required: true
    },
    isOnlyRead: {
      type: Boolean,
      required: false,
      default: false
    },
    formData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      isDisabled: false, //禁用
      searchForm: { empoloyee: '', idCard: '' },
      page: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      showDialog: false,
      dialogdetailsData: {
        showDialog: false,
        isEdit: false,
        isOnlyRead: false,
        curId: 0
      },
      tableData1: [],
      tableData: [],
      loading: false
    }
  },
  created () {
    this.look()
    this.getPageList()
  },
  methods: {
    handleView1 (row) {
      this.dialogdetailsData.isEdit = true
      this.dialogdetailsData.isOnlyRead = true
      this.dialogdetailsData.curId = row.detailId
      this.dialogdetailsData.curData = row
      this.dialogdetailsData.showDialog = true
      this.dialogdetailsData.entrustType = this.entrustType
      console.log('111', this.dialogdetailsData.entrustType)
    },
    //重置搜索数据
    resetFormData () {
      this.searchForm = {
        empoloyee: '',
        idCard: ''
      }
      this.page.pageSize = 10
      this.search()
    },
    // 重置查询
    search () {
      this.page.totalItems = 0
      this.page.currentPage = 1
      this.getPageList()
    },
    // 查询
    getPageList () {
      this.loading = false
      let params = {
        listId: this.id,
        ...this.searchForm,
        ...{
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize
        }
      }
      this.$api.PersonnelUnderProtection.lookemployeetabledate(params)
        .then(response => {
          this.tableData1 = response.item
          this.page.totalItems = response.totalCount
        })
        .catch(error => {
          console.error('下载 Excel 文件时发生错误：', error)
        })
    },
    look () {
      this.$api.PersonnelUnderProtection.lookessentialInformation(this.id)
        .then(response => {
          this.tableData = [response]
          console.log('nnn', this.tableData)
        })
        .catch(error => {
          console.error('下载 Excel 文件时发生错误：', error)
        })
    },
    download () {},
    // 取消弹框
    handleCancel () {
      this.$emit('close')
    },
    dialogSuccess () {
      this.dialogdetailsData.showDialog = false
    },
    // 取消
    dialogClose () {
      this.dialogdetailsData.showDialog = false
    },
    //#region 分页
    handleCurrentChange (pageIndex) {
      this.page.currentPage = pageIndex
      this.getPageList()
    },
    handleSizeChange (pageSize) {
      this.page.pageSize = pageSize
      this.getPageList()
    },
    // 时间的统一管理
    formatDateTime (dateTimeString) {
      if (!dateTimeString) {
        return '' // 如果日期时间字符串为空，则直接返回空字符串
      }

      // 使用 Moment.js 解析并格式化日期时间
      const formattedDateTime = moment(
        dateTimeString,
        'MM/DD/YYYY HH:mm:ss'
      ).format('YYYY-MM-DD HH:mm:ss')
      return formattedDateTime
    },
  }
}
</script>

<style>
.el-dialog__header {
  padding: 10 0px;
}
.el-dialog__body {
  padding: 15px 21px;
}
</style>
