import api from '../index'

export default {
  CostBillList(params) { // 应付账单列表
    return api.get('/bill/sale', params)
  },
  CostBillDetail(bid) { // 应付账单详情
    return api.get('/bill/sale/' + bid)
  },
  CostSocialBillInfo(bid) { // 应付社保账单
    return api.get('/bill/sale/socialBill/'+ bid)
  },
  CostAccBillInfo(bid) { // 应付公积金账单
    return api.get('/bill/sale/accBill/'+ bid)
  },
  SocialBillDetail(params) { // 社保账单详情
    return api.get('/bill/sale/getSocialdetail', params)
  },
  AccBillDetail(params) { // 公积金账单详情
    return api.get('/bill/sale/getAccdetail', params)
  }
}