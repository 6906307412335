<template>
  <div id="SocialBill">
    <div class="page-header">
      <p class="page-path">
        账单管理<span class="slashline">/</span>应付台账<span
          class="slashline"
          >/</span
        >台账详情
      </p>
      <p class="page-title">台账详情</p>
    </div>
    <div class="operation-pannel">
       <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="basic-info">基础信息</div>
          </template>
          <el-form
            :model="basicInfoForm"
            label-position="top"
            ref="basicInfoForm"
            class="basic-info-form"
            :rules="basicInfoFormRules"
          >
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item prop="enterpriseName">
                  <label style="text-align:right">单位名称</label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.enterpriseName"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">     
                <el-form-item prop="branch">
                  <label >托管网点</label>
                  <el-input
                    v-model="basicInfoForm.branch"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="city">
                   <label >参缴城市</label>
                  <el-input
                    v-model="basicInfoForm.city"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
               <el-col :span="8">
                <el-form-item prop="customer">
                  <label style="text-align:right">所属客户</label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.customer"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">     
                <el-form-item prop="billType">
                  <label >台账类型</label>
                  <el-select
                    v-model="basicInfoForm.billType"
                    placeholder="请选择"
                    clearable
                    :disabled="true"
                  >
                    <el-option label="社保台账" :value="0"></el-option>
                    <el-option label="公积金台账" :value="1"></el-option>
                    <el-option label="服务费台账" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="month">
                   <label >出账月份</label>
                 <el-date-picker
                  :editable="true"
                  :disabled="true"
                  v-model="basicInfoForm.month"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder=""
                >
                </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
               <el-col :span="8">
                <el-form-item prop="id">
                  <label style="text-align:right">台账编号</label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.id"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">     
                <el-form-item prop="count">
                  <label >账单人数</label>
                  <el-input
                    v-model="basicInfoForm.count"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="creationTime">
                   <label >账单生成时间</label>
                  <el-input
                    v-model="basicInfoForm.creationTime"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
               <el-col :span="8">
                <el-form-item prop="totalCost">
                  <label style="text-align:right">费用合计</label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.totalCost"
                    placeholder=""
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="basic-info">社保台账详情</div>
          </template>
          <div class="socialBill">
          <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="员工姓名：" prop="nameOrIdCard">
              <el-input v-model="filters.nameOrIdCard" placeholder="请输入员工姓名或身份证"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="缴费类型：" prop="joinType">
               <el-select v-model="filters.joinType" clearable placeholder="请选择">
                <el-option label="正常缴费" value="0"></el-option>
                <el-option label="补缴" value="1"></el-option>
                <el-option label="退费" value="2"></el-option>
                <el-option label="补差" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="类型：" prop="costType">
               <el-select v-model="filters.costType" clearable placeholder="请选择">
                <el-option label="社保" value="0"></el-option>
                <el-option label="公积金" value="1"></el-option>
                <el-option label="社保和公积金" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="searchData">查询</el-button>
            <el-button type="default" 
            @click="
            resetFilter();
            searchData();
            "
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
          <el-table
            max-height="500"
            :data="tableData"
            ref="tableData"
            border
            style="width: 100%; margin-bottom: 30px"
            v-loading="tableLoading"
          >
            <el-table-column
              type="index"
              label="序号"
              :index="indexMethod"
              width="50">
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
            ></el-table-column>
            <el-table-column
              prop="idCard"
              label="身份证号"
            ></el-table-column>
            <el-table-column
              prop="month"
              label="台账月份"
            >
              <template slot-scope="scope">
              <div>
                {{ commonjs.dateFtt("yyyy-MM", new Date(scope.row.month)) }}
              </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="actualPayMonth"
              label="实际缴纳月份"
            >
              <template slot-scope="scope">
              <div>
                {{ commonjs.dateFtt("yyyy-MM", new Date(scope.row.actualPayMonth)) }}
              </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="CostType"
              label="类型"
            >
            <template slot-scope="scope">
            <span v-if="scope.row.costType == 0">社保</span>
            <span v-else-if="scope.row.costType == 1">公积金</span>
            <span v-else-if="scope.row.costType == 2">社保和公积金</span>
          </template>
            </el-table-column>
            <el-table-column
              prop="joinType"
              label="缴费类型"
            >
            <template slot-scope="scope">
            <span v-if="scope.row.joinType == 0">正常缴费</span>
            <span v-else-if="scope.row.joinType == 1">补缴</span>
            <span v-else-if="scope.row.joinType == 2">退费</span>
            <span v-else-if="scope.row.joinType == 3">补差</span>
          </template>
            </el-table-column>
            <el-table-column
              prop="serviceMoney"
              label="服务费金额"
            ></el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="totalCount"
            layout="prev, pager, next, sizes, jumper"
          >
          </el-pagination>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="bottom-btn-group">
     <el-button type="info" @click="returnGeneralList">返回</el-button>
  </div>
  </div>
</template>

<script>
export default {
  name: "TemplateInfo",
  components: {},
  data: function () {
    return {
      activeNames: ["1","2"],
      selectedRegion: [],
      checkType:false,
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      basicInfoForm: {
        enterpriseName:"",
        branch:"",
        city:"",
        customer:"",
        billType:0,
        month:"",
        totalCost:0,
        id:"",
        count:0,
        creationTime:"",
      },
      basicInfoFormRules: {
      },
      activeCard: "正常",
      savingTemplate: false, // 保存
      fanyouStorageDialog: false,
      fanyouStorageFilterForm: {
        Name: "",
      },
      filters:{
        joinType:"",
        nameOrIdCard:"",
        costType:"",
      },
      tableLoading: false,
      linkingTotable: false,
    };
  },
  methods: {
    indexMethod(index) { //实现表格序号自增
        let curpage = this.currentPage;
        let limitpage = this.pageSize;
        return (index + 1) + (curpage - 1) * limitpage;
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getDetail();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getDetail();
    },
    //查询
    searchData() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.getDetail();
    },
    //重置查询条件
    resetFilter() {
      this.filters = [];
    },
    SubmitForm() {
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      if (res) {
        this.saveCostTemplate();
      } else {
        console.log("error submit!!");
      }
    },
    returnGeneralList() {
      this.$router.go(-1);  
    },
    async getBillInfo() {
      let bid = this.$route.params.bid;
      try {
        let res = await this.$api.SaleBill.SaleServiceBillInfo(bid);
        if (res.code==200) {
          this.basicInfoForm = res.data;
          //如有需要，在这重新赋值
          //this.GetcheckType();
        } else {
          this.$message.error("获取详情失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取详情失败！");
      }
    },
    async getDetail(){
      let bid = this.$route.params.bid; 
      let Params = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        BillId: bid,
        Name:this.filters.nameOrIdCard,
        JoinType:this.filters.joinType,
        CostType:this.filters.costType,
      };
      this.tableLoading = true;
      try {
        let res = await this.$api.SaleBill.ServiceBillDetail(Params);
        console.log(res);
        console.log("账单明细查询")
        if (res) {
          this.totalCount = res.totalCount;
          this.tableData = res.item;
        } else {
          this.$message.error("获取明细失败！");
        }
        this.tableLoading = false;
      } catch (e) {
        console.log(e);
        this.$message.error("获取明细失败！");
        this.tableLoading = false;
      }
    }, 
  
  },
  mounted() {
    //获取明细
    console.log("获取信息");
    this.getBillInfo();
    this.pageSize = 10;
    this.currentPage = 1;
    this.getDetail();
  }
}
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#addGeneralPolicy {
  padding: 20px;
}
.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.el-collapse {
  border-top: 0;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.basic-info-form .el-select {
  width: 100%;
}
.basic-info-form .el-date-editor {
  width: 100%;
}
.policy-info {
  margin: 20px 0 0;
}
.control-panel {
  width: 100%;
  height: 48px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}
.uploadfileName {
  margin-left: 8px;
}
</style>
