<template>
  <div class="ku-tabs-component">
    <div class="ku-tabs-container">
      <div
        class="ku-tab"
        :class="{ 'ku-active-tab': activeTab === index }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="changeTab(item, index)"
      >
        {{ item.name
        }}<span
          class="ku-tab-num"
          v-if="item.num != undefined && typeof item.num === 'number'"
          >（{{ item.num }}）</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kutabs",
  props: {
    tabs: Array,
    value: [String, Number], // 添加 value 属性
  },
  data: function () {
    return {
      activeTab: 0,
    };
  },
  computed: {
    // 添加 computed 属性，用于双向绑定
    selectedTab: {
      get() {
        return this.value; // 获取 value
      },
      set(val) {
        this.$emit("input", val); // 触发 input 事件
      },
    },
  },
  watch: {
    // 监听 value 的变化，并更新 activeTab
    value(newVal) {
      let index = this.tabs.findIndex(c => newVal == c.value);
      this.activeTab = index >-1 ? index : 0;
    },
  },
  methods: {
    changeTab(tab, tabIndex) {
      this.activeTab = tabIndex;
      this.selectedTab = tab.value; // 设置 selectedTab，触发双向绑定
      this.$emit("tabChanged", tab);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ku-tabs-container {
  background: #fff;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  overflow-x: auto;
}
.ku-tabs-container .ku-tab {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  margin-right: 35px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 14px;
  flex-shrink: 0;
  white-space: nowrap;
}
.ku-tabs-container .ku-tab:hover {
  color: #1890ff;
}
.ku-tabs-container .ku-tab:last-child {
  margin-right: 0;
}
.ku-tabs-container .ku-tab.ku-active-tab {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.ku-tabs-container .ku-tab .ku-tab-num {
  margin-left: 0px;
}
</style>
