<!-- 查看凭证信息的编辑框 -->
<template slot="title">
  <!-- title下面的主要内容 -->
  <div>
    <el-form ref="addForm" label-suffix="：" label-width="120px">
      <br />
      <el-form-item label="所属单位" prop="enterprise">
        <el-select disabled v-model="enterprise" style="width: 50%">
        </el-select>
      </el-form-item>
      <el-form-item label="托管类型" prop="entrustType">
        <el-input disabled v-model="entrustType" style="width: 50%"></el-input>
      </el-form-item>
      <el-form-item label="相关凭证信息" prop="attachments">
        <div style="display: flex; flex-wrap: wrap">
          <div
            v-for="attachment in attachments"
            :key="attachment"
            style="width: 100px; height: 100px; margin-right: 20px"
          >
            <img
              style="width: 100%; height: 100%"
              :src="
                attachment.includes('.xls')
                  ? require('@/assets/fileassets/excel.png')
                  : attachment.includes('.pdf')
                  ? require('@/assets/fileassets/pdf.png')
                  : attachment
              "
              @click="handlePic(attachment)"
            />
          </div>
        </div>
      </el-form-item>
      <br />
      <div style="text-align: center">
        <el-button @click="downloadAttachmentsAsZip()" type="primary"
          >下载</el-button
        >
      </div>
    </el-form>
    <!-- 图片缩略图 -->
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img
        width="600px"
        height="500px"
        style="display: flex"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>
<script>
// 引入 jszip 库
// import { saveAs } from 'file-saver';
import JSZip from 'jszip'
export default {
  props: {
    id: {
      type: [Number, String]
    },
    isEdit: {
      type: Boolean
    },
    isOnlyRead: {
      type: Boolean,
      required: false,
      default: false
    },
    formData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      disabled: false, //禁用
      enterprise: '',
      entrustType: '',
      attachments: []
    }
  },
  created () {
    this.look()
  },
  methods: {
    // 缩略图
    handlePic (attachment) {
      // console.log('111',attachment)
      if (
        attachment.includes('.pdf') ||
        attachment.includes('.xls') ||
        attachment.includes('.xlsx')
      ) {
        window.open(attachment, '_blank') // 直接打开文件
      } else {
        this.dialogImageUrl = attachment
        this.dialogVisible = true
      }
    },
    look () {
      this.$api.PersonnelUnderProtection.lookattachment(this.id).then(
        response => {
          this.enterprise = response.enterprise
          this.entrustType = response.entrustType
          this.attachments = response.attachments
        }
      )
      // .catch(error => {
      //   this.$message(error)
      // })
    },
    // 定义 extractFileNameFromUrl 函数
    getFileName (url) {
      const parsedUrl = new URL(url)
      const pathName = parsedUrl.pathname
      let fileName = pathName.substring(pathName.lastIndexOf('/') + 1)
      fileName = decodeURIComponent(fileName)

      // 查找文件名中最后一个点号的位置
      const lastDotIndex = fileName.lastIndexOf('.')
      if (lastDotIndex !== -1) {
        // 如果存在点号
        fileName = fileName.substring(0, lastDotIndex) // 截取点号之前的部分作为文件名
      }

      return fileName
    },
    downloadAttachmentsAsZip () {
      // 调用接口获取附件链接数组
      this.$api.PersonnelUnderProtection.lookattachment(this.id)
        .then(async response => {
          this.attachments = response.attachments
          console.log('123455', this.attachments)
          // this.attachments.push(
          //   'https://cdn.pixabay.com/photo/2017/03/12/11/30/alishan-2136879_1280.jpg'
          // )
          // 创建一个 JSZip 实例
          const zip = new JSZip()

          // 使用 Promise.all() 等待所有异步操作完成
          await Promise.all(
            this.attachments.map(async attachmentUrl => {
              try {
                // 提取文件名
                const filename = this.getFileName(attachmentUrl)
                // 提取文件后缀名
                const extension = this.getFileExtension(attachmentUrl)
                // 根据文件后缀名判断并下载文件
                if (['gif', 'png', 'jpeg', 'jpg'].includes(extension)) {
                  const response = await fetch(attachmentUrl)
                  const blob = await response.blob()
                  zip.file(`${filename}.${extension}`, blob)
                } else if (extension === 'pdf') {
                  // 下载 PDF 文件
                  const response = await fetch(attachmentUrl)
                  const blob = await response.blob()
                  zip.file(`${filename}.${extension}`, blob)
                } else if (extension === 'xlsx') {
                  // 下载 Excel 文件
                  const response = await fetch(attachmentUrl)
                  const arrayBuffer = await response.arrayBuffer()
                  const blob = new Blob([arrayBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  })
                  zip.file(`${filename}.${extension}`, blob)
                } else if (extension === 'xls') {
                  // 下载 Excel (.xls) 文件
                  const response = await fetch(attachmentUrl)
                  const blob = await response.blob()
                  zip.file(`${filename}.xls`, blob) // 文件名以 .xls 结尾
                }
              } catch (error) {
                // 处理单个 Promise 失败的情况，可以选择忽略错误继续下载其他文件
                console.error(`下载文件时发生错误: ${error}`)
                // this.$message.error(error.message)
              }
            })
          )

          // 生成并下载 zip 文件
          zip.generateAsync({ type: 'blob' }).then(content => {
            const zipFilename = '凭证信息.zip'
            // 创建下载链接并点击下载
            const url = URL.createObjectURL(content)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', zipFilename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            // 释放对象 URL
            URL.revokeObjectURL(url)
          })
        })
        .catch(error => {
          console.error('下载附件时发生错误:', error)
        })
    },

    getFileExtension (filename) {
      return filename.split('?')[0].split('.').pop().toLowerCase()
    },
    // 取消弹框
    handleCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.el-dialog__header {
  padding: 10 0px;
}
.el-dialog__body {
  padding: 15px 21px;
}
</style>
