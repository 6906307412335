<!-- 查看凭证信息的编辑框 -->
<template slot="title">
  <!-- title下面的主要内容 -->
  <div>
    <el-form style="border: 1px solid #ccc; padding: 20px">
      <br />
      <div
        style="
          line-height: 24px;
          font-size: 18px;
          margin-bottom: 20px;
          color: #303133;
        "
      >
        基本信息
      </div>
      <el-table :data="tableData2" border style="width: 100%">
        <el-table-column prop="employee" label="姓名" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="enterprise" label="所属单位" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="customer" label="所属客户" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="branch" label="托管网点" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="city" label="参缴城市" show-overflow-tooltip> </el-table-column>
        <el-table-column show-overflow-tooltip
          v-if="entrustType == '社保托管'"
          prop="insuranceType"
          label="社保参保类型"
        >
        </el-table-column>
        <el-table-column
        v-else
        show-overflow-tooltip
          prop="insuranceType"
          label="公积金参保类型"
        >
        </el-table-column>
      </el-table>
      <br />
      <div>
        <div
          style="
            line-height: 24px;
            font-size: 18px;
            margin-top: 20px;
            color: #303133;
            margin-bottom: 20px;
          "
        >
          险种信息
        </div>
        <div style="margin-top: 10px; margin-bottom: 10px">
          <el-card>
            <div style="display: flex; font-size: 18px">
              <el-form-item style="margin-bottom: 0px"
                ><span  v-if="entrustType == '社保托管'">社保险种信息</span
                ><span  v-else>公积金险种信息</span></el-form-item
              >
              <el-form-item style="margin-left: 50px; margin-bottom: 0px"
                >政策编号：{{ policyNumber }}</el-form-item
              >
              <el-form-item style="margin-left: 50px; margin-bottom: 0px"
                >申报基数：{{ applyBase }}</el-form-item
              >
            </div>
          </el-card>
        </div>
        <div class="tablemoney" style="margin-top: 15px">
          <el-table
            class="tablemoney"
            border-collapse="collapse"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="tableData3"
            row-key="id"
            ref="ArticleCommitTable"
            border
            :row-style="{ height: '35px' }"
            style="text-align: center"
            :header-cell-style="{ background: '#9abce6', color: '#000' }"
            :default-sort="{ prop: 'UserEmpiricalValue', order: 'descending' }"
          >
            <el-table-column
              align="center"
              prop="insuranceName"
              label="险种名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="enterpriseBase"
              align="center"
              show-overflow-tooltip
              label="缴纳基数"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="单位缴纳比例"
              show-overflow-tooltip
              prop="enterprisePercent"
              style="background:'#9acfe6"
            >
              <template slot-scope="{ row }">
                {{ (row.enterprisePercent * 100).toFixed(0) }}%
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="单位缴纳总计"
              show-overflow-tooltip
              prop="enterpriseMoney"
              style="background:'#9acfe6"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="personPercent"
              label="个人缴纳比例"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{ (row.personPercent * 100).toFixed(0) }}%
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="personMoney"
              label="个人缴纳总计"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页组件 -->
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="handleCancel" type="info">返回</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    entrustType: {
      type: String
      // required: true,
    },
    id: {
      type: [Number, String]
      // required: true,
    },
    isEdit: {
      type: Boolean
      // required: true
    },
    isOnlyRead: {
      type: Boolean,
      required: false,
      default: false
    },
    formData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      tableData2: [],
      tableData3: [],
      loading: false,
      policyNumber: '',
      applyBase:"",
    }
  },
  created () {
    this.look()
  },
  methods: {
    look () {
      this.$api.PersonnelUnderProtection.detailsindetail(this.id)
        .then(response => {
          this.policyNumber = response.policyNumber
          this.applyBase=response.applyBase
          this.tableData2 = [response]
          this.tableData3 = response.policyDatas
        })
        .catch(error => {
          this.$message.error(error.message)
        })
    },
    // 取消弹框
    handleCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.el-dialog__header {
  padding: 10 0px;
}
.el-dialog__body {
  padding: 15px 21px;
}
</style>
