<template>
  <div id="addGeneralPolicy">
    <div class="page-header">
      <p class="page-path">
        账单管理<span class="slashline">/</span>台账模板管理<span
          class="slashline"
          >/</span
        >编辑模板
      </p>
      <p class="page-title">编辑费用模板</p>
    </div>
    <div class="operation-pannel">
      <el-form
            :model="basicInfoForm"
            label-position="top"
            ref="basicInfoForm"
            class="basic-info-form"
            :rules="basicInfoFormRules"
          >
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="basic-info">基本模板信息</div>
            </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
              <el-row :gutter="30" >
                <el-col :span="10" >
                  <el-form-item prop="type" >
                    <label style="width:154px;margin-left:84px"><span style="text-align:right">模板类型：</span></label>
                    <el-select
                      v-model="basicInfoForm.type"
                      :disabled="true"
                      placeholder="请选择"
                      @change="typeChange"
                      style="width:50%"
                    >
                        <el-option label="开户通用模板" :value="0"></el-option>
                        <el-option label="托管通用模板" :value="1"></el-option>
                        <el-option label="自定义网点托管模板" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                  <el-form-item  prop="name">
                    <label style="width:154px;margin-left:84px"><span style="text-align:right">模板名称：</span></label>
                    <el-input
                      v-model="basicInfoForm.name"
                      placeholder=""
                      :disabled="true"
                      style="width:50%"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="16">
                  <el-form-item prop="remark">
                    <label style="width:154px;margin-left:105px"><span style="text-align:right">备&nbsp;注：</span></label>
                    <el-input
                      type = "textarea"
                      v-model="basicInfoForm.remark"
                      placeholder="请输入"
                      :disabled="false"
                      :rows="4"
                      style="width:45%;margin-left:2px"
                      maxlength="200"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <div class="basic-info">版本详情</div>
            </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                   <el-form-item prop="effectMonth">
                   <label style="width:154px;margin-left:84px"><span style="text-align:right">生效月份：</span></label>
                <el-date-picker
                  :editable="true"
                  :disabled="versionUpdate"
                  v-model="basicInfoForm.effectMonth"
                  type="month"
                  @change="selectTime"
                  value-format="yyyy-MM"
                  placeholder="请选择"
                  style="width:50%;"
                >
                </el-date-picker>
                </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop="version">
                  <label style="width:112px;margin-left:70px;"><span style="text-align:right">版本：</span></label>
                <el-select clearable v-model="basicInfoForm.version" @change="versionChange" placeholder="请选择"  style="width:50%;">
                  <el-option v-for="(item,index) in versionList"  :key="index" :label="item.flag==1?item.version+' 当前使用版本':item.version" :value="item.version"></el-option>
                </el-select>
                </el-form-item>
                </el-col>
                 <el-button @click="UpdateVersion" style="text-align:center" type="primary" plain>版本更新</el-button>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="12">
                   <el-form-item v-show="checkType" prop="branchesName">
                  <label style="width:154px;margin-left:84px"><span style="text-align:right">关联网点：</span></label>
                  <el-button @click="ChooseBranches" v-show="versionUpdate==true?false:true" style="background-color:rgba(254,64,102,.25);text-align:center">关联网点</el-button>
                  <el-input
                    type = "textarea"
                    v-model="basicInfoForm.branchesName"
                    placeholder="请点击选择关联网点"
                    :disabled="true"
                    style="width:60%;margin-left:2px"
                    :rows="4"
                    maxlength="512"
                  ></el-input>
                </el-form-item>
                </el-col>
              </el-row>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title">
              <div class="basic-info">销售价设置</div>
            </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                  <el-form-item prop="socialSaleCost">
                    <label style="margin-left:57px;width:20%;text-align:right"><template>
                      <span v-if=" basicInfoForm.type == null">社保开户费用：</span>
                      <span v-else-if="basicInfoForm.type == 0">社保开户费用：</span>
                      <span v-else>社保托管费用：</span>
                    </template></label>
                    <el-input
                      :span="3"
                      v-model="basicInfoForm.socialSaleCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  
                  <el-form-item prop="accSaleCost">
                    <label style="width:20%;"><template>
                      <span v-if=" basicInfoForm.type == null">公积金开户费用：</span>
                      <span v-else-if="basicInfoForm.type == 0">公积金开户费用：</span>
                      <span v-else>公积金托管费用：</span>
                    </template></label>
                    <el-input
                      v-model="basicInfoForm.accSaleCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                  <el-form-item prop="socialAndAccSaleCost">
                    <label style="width:20%;"><template>
                      <span v-if=" basicInfoForm.type == null">社保和公积金开户费用：</span>
                      <span v-else-if="basicInfoForm.type == 0">社保和公积金开户费用：</span>
                      <span v-else>社保和公积金托管费用：</span>
                    </template></label>
                    <el-input
                      v-model="basicInfoForm.socialAndAccSaleCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template slot="title">
              <div class="basic-info">成本价设置</div>
            </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                  <el-form-item prop="socialSaleCost">
                    <label style="margin-left:57px;width:20%;text-align:right">
                    <template>
                      <span v-if=" basicInfoForm.type == null">社保开户成本：</span>
                      <span v-else-if="basicInfoForm.type == 0">社保开户成本：</span>
                      <span v-else>社保托管成本：</span>
                    </template>
                    </label>
                    <el-input
                      :span="3"
                      v-model="basicInfoForm.socialCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  
                  <el-form-item prop="accSaleCost">
                    <label style="width:20%;"><template>
                      <span v-if=" basicInfoForm.type == null">公积金开户成本：</span>
                      <span v-else-if="basicInfoForm.type == 0">公积金开户成本：</span>
                      <span v-else>公积金托管成本：</span>
                    </template></label>
                    <el-input
                      v-model="basicInfoForm.accCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                  <el-form-item prop="socialAndAccSaleCost">
                    <label style="width:20%;"><template>
                      <span v-if=" basicInfoForm.type == null">社保和公积金开户成本：</span>
                      <span v-else-if="basicInfoForm.type == 0">社保和公积金开户成本：</span>
                      <span v-else>社保和公积金托管成本：</span>
                    </template></label>
                    <el-input
                      v-model="basicInfoForm.socialAndAccCost"
                      placeholder="请输入"
                      :disabled="versionUpdate"
                      style="width:50%"
                    ><template slot="append"><template>
                      <span v-if=" basicInfoForm.type == null">元/次</span>
                      <span v-else-if="basicInfoForm.type == 0">元/次</span>
                      <span v-else>元/人次</span>
                    </template></template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="bottom-btn-group">
        <el-button @click="returnGeneralList">取消</el-button>
        <el-button type="primary" @click="SubmitForm()" :loading="savingTemplate"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog
      title="关联网点"
      :visible.sync="BranchesDialog"
      @closed="BranchesData = []"
    >
      <el-form
        :inline="true"
        ref="BranchesFilterForm"
        :model="BranchesFilterForm"
      >
        <el-form-item label="网点名称：" prop="BranchName">
          <el-input
            v-model="BranchName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getBranchesList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="BranchesTable"
        max-height="380"
        :data="BranchesData"
        border
        v-loading="BranchesTableLoading"
        :row-key="getRowKeys"
      >
        <el-table-column
        type="index"
        label="序号"
        width="50">
        </el-table-column>
        <el-table-column
          property="name"
          label="网点名称"
        ></el-table-column>
        <el-table-column type="selection" width="48" :reserve-selection="true" :selectable="initSeleTable"  v-model="BranchesFilterForm.switchStatus" property="switchStatus" label="操作">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="BranchesCurrentChange"
        :total="BranchesTotalCount"
        layout="total, prev, pager, next"
        style="margin-top: 16px"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="BranchesDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getBranchesSelected"
          :loading="linkingTotable"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TemplateInfo",
  components: {},
  data: function () {
    return {
      activeNames: ["1","2","3","4"],
      selectedRegion: [],
      tableData: [],
      checkType:false,
      templateType:0,
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      versionList:[],
      versionUpdate:true,//false为可编辑
      UnSelectedList:[],
      basicInfoForm: {
        name: "",
        type: "",
        branches: "",
        branchesName: "",
        version:"",
        socialSaleCost: 0,
        accSaleCost: 0,
        socialAndAccSaleCost: 0,
        socialCost: 0,
        accCost: 0,
        socialAndAccCost: 0,
        remark: "",
        effectMonth:"",
        versionUpdate:false,
      }, 
      pickerOptions1:{
        disabledDate:time=> {
          return time.getTime() > new Date();
        }
      },
      basicInfoFormRules: {
        // type: [
        //   { required: true, message: "请选择模板类型", trigger: "change" },
        // ],
        effectMonth: [
          { required: true, message: "请选择生效月份", trigger: "change" },
        ],
        socialSaleCost: [
          { required: true, message: "社保费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        accSaleCost: [
          { required: true, message: "公积金费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialAndAccSaleCost: [
          { required: true, message: "社保和公积金费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialCost: [
          { required: true, message: "社保成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        accCost: [
          { required: true, message: "公积金成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialAndAccCost: [
          { required: true, message: "社保和公积金成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
      },
      activeCard: "正常",

      savingTemplate: false, // 保存
      fanyouStorageDialog: false,
      fanyouStorageFilterForm: {
        Name: "",
      },
      Data: [],
      TableLoading: false,
      //网点
      BranchesDialog:false,
      BranchesFilterForm: {
        name: "",
        id:"",
        switchStatus:false,
      },
      BranchName:"",
      BranchesData: [],
      BranchesTableLoading: false,
      BranchespageSize:10,
      BranchesCurrentPage: 1,
      BranchesTotalCount: 0,
      linkingTotable: false,
    };
  },
  methods: {
    async versionChange(version) {
      if(version!=null){
        let TemplateName = this.basicInfoForm.name;
        let Version = version;
        console.log(TemplateName + version);
        try {
          let res =await this.$api.BillManage.GetTemplateByNameAndVersion(TemplateName,Version);
          console.log(res);
          if (res) {
             this.basicInfoForm = res.data;
          } else
          {
            this.$message.error("查询模板明细信息出错！");
          }
        } catch (e) {
          console.log(e);
          this.$message.error(e.message);
        }
      }
    },
    UpdateVersion()
    {
      this.versionUpdate=false;
    },
    LogCurrentPageChange(currentPage) {
      // 日志当前页码改变
      this.CurrentPage = currentPage;
      this.getLog();
    },
    GetcheckType(){
      if(this.basicInfoForm.type==2){
        this.checkType = true;
      }
      else{
        this.checkType = false;
      }
    },
    SubmitForm() {
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      if (res) {
        this.saveCostTemplate();
       
      } else {
        console.log("error submit!!");
      }
    },
    returnGeneralList() {
      this.$router.go(-1);  
    },
    
    async saveCostTemplate() {
      // 保存费用模板
      this.basicInfoForm.versionUpdate = this.versionUpdate;
      if(this.basicInfoForm.type==2 && this.basicInfoForm.branchesName=="")
      {
        this.$message.error("自定义网点模板必须绑定关联网点");
        return;
      }
      let params = this.basicInfoForm;
      this.savingTemplate = true;
      console.log(params);
      try {
        let res = await this.$api.BillManage.UpdateCostTemplate(params);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.savingTemplate = false;
          this.$router.push({
                    name: "CostTemplateManage",
                    query: {
                      cache: "false" // 设置缓存标志位
                        }
                });
        } else {
          this.savingTemplate = false;
          this.$message.error(res.msg);
        }
      } catch (e) {
        console.log(e);
        this.$message.error("保存失败！");
        this.savingTemplate = false;
      }
    },
    typeChange(type) {
      this.SetName(type);
    },
    async SetName(type) {
      this.GetcheckType();
      if(type == 0){
        this.basicInfoForm.name = "开户通用模板";
      }
      else if(type == 1){
        this.basicInfoForm.name = "托管通用模板";
      }else if(type==2){
        //获取最新的自定义网点托管模板名称
        this.basicInfoForm.name = "自定义网点托管模板V"+"1";
        let reqList = [];
         let req = this.$api.policy.getPolicyName({
          source: 0,
          district: 1,
          insuranceType: type,
        });
        reqList.push(req);
        try {
        let res = await Promise.all(reqList);
        let policyNameList = [];
        if (res) {
          res.forEach((item) => {
            policyNameList.push(item.data);
          });
        } else {
          policyNameList = [];
        }
        this.basicInfoForm.name = policyNameList.join(",");
      } catch (e) {
        console.log(e);
      } 
      }
    },
    async getDetail() {
      let bid = this.$route.params.bid;
      try {
        let res = await this.$api.BillManage.CostTemplateDetail(bid);
        if (res.code == 200) {
          this.basicInfoForm = res.data;
          //如有需要，在这重新赋值
          this.GetcheckType();
          this.getVersion();
          let effectMonth =this.basicInfoForm.effectMonth;
          this.getUnselect(effectMonth);
        } else {
          this.$message.error("获取详情失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取详情失败！");
      }
    },
    async getUnselect(effectMonth){
      let bid = this.$route.params.bid;
      try{
       let resG = await this.$api.BillManage.getSelectedBranches(effectMonth,bid);
       if(resG)
       {
         if(resG.data!=null){
           this.UnSelectedList = resG.data.map(function(o) {return o.branchName;});
           console.log("获取已被绑定网点");
           console.log(this.UnSelectedList);
         }
       }else{
        this.$message.error("获取已被绑定网点失败！");
       }
      }catch(e){
        this.$message.error("获取已被绑定网点失败！");
      }
    },
    getVersion()
    {
      if(this.versionList.length==0)
      {
        let TemplateName = this.basicInfoForm.name;
        console.log(TemplateName);
        console.log(this.basicInfoForm);
        this.$api.BillManage.GetVersionListByName(TemplateName).then(res=>{
          this.versionList=res.data;
        }).catch(()=>{ })
      }
    },
    //关于网点的方法
    BranchesCurrentChange(currentPage) {
      // 当前页码改变
      this.BranchesCurrentPage = currentPage;
      this.getBranchesList();
    },
    ChooseBranches() {
      this.getBranchesList();
    },
    //获取网点信息
    async getBranchesList(){
      let effectMonth = this.basicInfoForm.effectMonth;
       if(effectMonth==""||effectMonth==new Date()){
        this.$message.error("请先选择生效月份，再进行网点绑定");
        return;
       }
       this.getUnselect(effectMonth);//获取当前生效月份的被绑定网点
       let params = {
        pageCount: this.BranchesCurrentPage,
        takeCount: this.BranchespageSize,
        branchName: this.BranchName,
        auditStatus: 1,
      };
      console.log(this.BranchName);
      console.log(params);
      this.BranchestableLoading = true;
      try {
        let res = await this.$api.branch.BranchList(params);
        if (res) {
          this.BranchesTotalCount = res.totalCount;
          this.BranchesData = res.item;
          this.BranchesDialog = true;
          this.init();
          console.log("网点数据");
          console.log(this.BranchesData);
        } else {
          console.log("empty data");
          this.BranchesDialog = false;
        }
        this.BranchestableLoading = false;
      } catch (e) {
        console.log(e);
        this.BranchestableLoading = false;
        this.BranchesDialog = false;
      }
      // this.BranchesData.forEach(row => {
      //       this.$refs.BranchesTable.toggleRowSelection(row,row.switchStatus)
      //  });
    },
     //初始化是否禁用
    initSeleTable(row){
       if(this.UnSelectedList.includes(row.name)) {
        return  false
      }  else { 
        return  true
      }
    },
    //勾选的
    init(){
      let str = this.basicInfoForm.branches + ";";
      var listB = str.split(';');
      this.BranchesData.forEach((val,index)=>{if(listB.includes(val.id)){
        this.$nextTick(function(){
          this.$refs.BranchesTable.toggleRowSelection(
          this.BranchesData[index],true
        )
        })
        }
      });
    },
    selectTime(val){
      console.log(val);
      this.UnSelectedList = [];
      this.basicInfoForm.branchesName ="";
      this.basicInfoForm.branches ="";
      this.$refs.BranchesTable.clearSelection();
    },
  getBranchesSelected(){
    let branchSelectList = null;
    branchSelectList = this.$refs["BranchesTable"].selection;
    console.log("打印选中");
    console.log(branchSelectList);
    this.BranchesDialog = false;
    let nameStr = "";
    let ids = ""
    branchSelectList.forEach(br => {
      if(ids.indexOf(br.id)<0){
            if(ids > ""){
            ids +=";"+br.id; 
            nameStr +=";"+br.name;
          }else{
            ids =br.id; 
            nameStr =br.name; 
          }
        }
        
      });
      console.log(nameStr);
      console.log(ids);
      this.basicInfoForm.branchesName = nameStr;
      this.basicInfoForm.branches = ids;
    },
    getRowKeys(row) {
      return row.id;
    },
  },
  mounted() {
    //获取明细
    this.getDetail();
  },
};
</script>
<style src="@/assets/public.css"></style>
<style scoped>
#addGeneralPolicy {
  padding: 20px;
}
.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.el-collapse {
  border-top: 0;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.basic-info-form .el-select {
  width: 100%;
}
.basic-info-form .el-date-editor {
  width: 100%;
}
.policy-info {
  margin: 20px 0 0;
}
.control-panel {
  width: 100%;
  height: 48px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}
.uploadfileName {
  margin-left: 8px;
}
</style>
