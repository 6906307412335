<template>
    <div id="ServiceBranch">
        <div class="page-header">
            <p class="page-path">
                服务网点管理<span class="slashline">/</span>小库网点列表
            </p>
            <p class="page-title">小库网点列表</p>
        </div>
        <div class="operation-pannel">
            <div class="op-row">
                网点编号：<el-input class="row-input"
                               v-model="branchId"
                               placeholder="请输入"></el-input>
                网点名称：<el-input class="row-input"
                               v-model="branchName"
                               placeholder="请输入"></el-input>
                审核状态：<el-select v-model="auditStatus" placeholder="请选择">
                    <el-option v-for="item in auditoptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
                <el-col :span="1"><div class="grid-content"></div></el-col>
                <el-button type="primary" @click="searchData">查询</el-button>
                <el-button @click="
            resetFilter();
            searchData();
          ">重置</el-button>
            </div>
            <div class="op-row">
                <el-button type="primary"
                           icon="el-icon-plus"
                           @click="AddBranch"
                           v-show="addShow">新建</el-button>
                <el-button type="primary" plain @click="aprovedAudit" v-show="auditShow">审核通过</el-button>
                <el-button type="danger" plain @click="failedAudit" v-show="auditShow">审核拒绝</el-button>

                <el-button type="primary" plain @click="submitAudit" v-show="addShow">提交审核</el-button>
                <el-button type="danger" plain @click="withdrawAudit" v-show="addShow">撤回</el-button>
                <el-button @click="editBranch" v-show="addShow">网点信息更新</el-button>
                <el-button type="primary" plain icon="el-icon-plus" @click="copyAddBranch" v-show="addShow">复制</el-button>
                <el-button type="danger" plain @click="deleteBranch" v-show="deleteBranchShow">删除</el-button>
            </div>
        </div>
        <div class="table-container">
            <el-table max-height="500"
                      :data="tableData"
                      ref="tableData"
                      border
                      style="width: 100%; margin-bottom: 30px"
                      v-loading="tableLoading">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="id" width="155" label="网点编号"></el-table-column>
                <el-table-column prop="name" width="185" label="网点名称"></el-table-column>
                <el-table-column prop="consigneeAddress"
                                 width="445"
                                 label="收件地址"></el-table-column>
                <el-table-column prop="branchRepresentative"
                                 width="100"
                                 label="网点负责人"></el-table-column>
                <el-table-column label="审核状态" width="90">
                    <template slot-scope="scope">
                        <div v-if="scope.row.auditStatus == 0">待审核</div>
                        <div v-else-if="scope.row.auditStatus == 1||scope.row.auditStatus == 4">审核成功</div>
                        <div v-else-if="scope.row.auditStatus == 2">审核失败</div>
                        <div v-else-if="scope.row.auditStatus == 3">未提交</div>
                        <div v-else>--</div>
                    </template>
                </el-table-column>
                <el-table-column prop="auditOpinion"
                                 label="审核意见"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-switch v-if="scope.row.auditStatus == 1"
                                   v-model="scope.row.switchStatus"
                                   active-color="#1890ff"
                                   inactive-color="#c0c0c0"
                                   @change="BranchEffectiveChange($event, scope)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="creationTime" width="130" label="创建时间"></el-table-column>
                <el-table-column width="130" label="修改时间">
                    <template slot-scope="scope">
                        <div v-if="new Date(scope.row.editTime).getFullYear() > 2001">
                            {{ scope.row.editTime }}
                        </div>
                        <div v-else>--</div>
                    </template>
                </el-table-column>
                <el-table-column label="开户是否需要在保人员">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isPersonalEssential == 0">否</div>
                        <div v-else>是</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="checkDetails(scope.row.id)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :total="totalCount"
                           :page-size="pageSize"
                           :current-page="currentPage"
                           layout="prev, pager, next, sizes, jumper">
            </el-pagination>
        </div>

        <el-dialog title="审核拒绝"
                   width="40%"
                   :visible.sync="auditFailVisible"
                   @close="auditFailCancel">
            <el-form ref="form" label-width="20%;">
                <el-form-item label="拒绝原因:" required>
                    <el-col :span="11">
                        <el-input type="textarea" v-model="failReason"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="auditFailSubmit"
                               style="margin-left: 26%">确认</el-button>
                    <el-button @click="auditFailCancel">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    // import Cookie from "js.cookie";
    export default {
        name: "ServiceBranch",
        components: {},
        data: function () {
            return {
                branchId: "",
                branchName: "",
                auditStatus: "",
                tableData: [],
                pageSize: 10,
                currentPage: 1,
                totalCount: 0,
                tableLoading: false,
                addShow: false,
                auditShow: false,
                deleteBranchShow: false,
                failReason: "",
                auditFailVisible: false,
                auditoptions: [{
                    value: '3',
                    label: '未提交'
                }, {
                    value: '0',
                    label: '待审核'
                }, {
                    value: '1',
                    label: '审核成功'
                }, {
                    value: '2',
                    label: '审核拒绝'
                }
                ]
            };
        },
        methods: {
            resetFilter() {
                this.branchId = this.branchName = this.auditStatus = "";
            },
            auditFailCancel() {
                this.auditFailVisible = false;
            },
            checkDetails(id) {
                this.$router.push({
                    name: "BranchDetail",
                    params: {
                        bid: id,
                    },
                });
            },
            aprovedAudit() {
                // 审核通过
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus != 0) {
                        this.$message({
                            message: "请选择“待审核”审核状态的数据进行处理",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.$confirm("你确定要通过审核吗?", "审核通过提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.BranchAudit(selection[0].id, 1, "审核");
                    })
                    .catch(() => { });
            },
            submitAudit() {
                // 审核通过
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus != 3) {
                        this.$message({
                            message: "请选择“未提交”状态的数据进行处理",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.$confirm("你确定要提交吗?", "提交审核提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.BranchAudit(selection[0].id, 0, "待审核");
                    })
                    .catch(() => { });
            },
            withdrawAudit() {
                // 审核通过
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus != 0) {
                        this.$message({
                            message: "请选择“待审核”状态的数据进行处理",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.$confirm("你确定要撤回吗?", "撤回提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.BranchAudit(selection[0].id, 3, "待审核");
                    })
                    .catch(() => { });
            },
            failedAudit() {
                // 审核拒绝
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus != 0) {
                        this.$message({
                            message: "请选择“待审核”审核状态的数据进行处理",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.auditFailVisible = true;
                //this.BranchAudit(selection[0].id, false,this.failReason);
            },
            auditFailSubmit() {
                let selection = this.$refs.tableData.selection;
                this.BranchAudit(selection[0].id, 2, this.failReason);
            },
            async BranchAudit(id, flag, failReason) {
                try {
                    let res = await this.$api.branch.BranchAudit(id, flag, failReason);
                    if (res) {
                        if (flag == 1) {
                            this.$message({
                                type: "success",
                                message: "已通过!",
                            });
                        } if (flag == 2) {
                            this.$message({
                                type: "success",
                                message: "已拒绝!",
                            })
                        } if (flag == 0) {
                            this.$message({
                                type: "success",
                                message: "已提交!",
                            });

                        }
                        this.auditFailVisible = false, this.failReason = "";
                        this.getData();
                    } else {
                        this.$message.error("审核失败！");
                    }
                } catch (e) {
                    console.log(e);
                    this.$message.error("审核失败！");
                }
            },
            handleSizeChange(pageSize) {
                // 每页条数改变
                this.pageSize = pageSize;
                this.getData();
            },
            handleCurrentChange(currentPage) {
                // 当前页码改变
                this.currentPage = currentPage;
                this.getData();
            },
            AddBranch() {
                this.$router.push("/AddBranch");
            },
            copyAddBranch() {
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要复制新增的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要复制新增的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                this.$router.push({
                    name: "CopyAddBranch",
                    params: {
                        bid: selection[0].id,
                    },
                });
            },
            deleteBranch() {
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus != 3) {
                        this.$message({
                            message: "只能删除未提交的数据",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.$confirm("你确定要删除吗?", "删除提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.BranchAudit(selection[0].id, 4, "删除");
                    })
                    .catch(() => { });
            },
            editBranch() {
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                for (let item of selection) {
                    if (item.auditStatus == 0) {
                        this.$message({
                            message: "不能编辑“待审核”状态的数据",
                            type: "error",
                            showClose: true,
                        });
                        return;
                    }
                }
                this.$router.push({
                    name: "EditBranch",
                    params: {
                        bid: selection[0].id,
                    },
                });
            },
            async BranchEffectiveChange(val, scope) {
                let params = {
                    bid: scope.row.id,
                    flag: val,
                };
                this.tableLoading = true;
                try {
                    let res = await this.$api.branch.BranchEffective(params);
                    if (!res) {
                        this.$message.error("设置失败！");
                    }
                    this.searchData();
                    this.tableLoading = false;
                } catch (e) {
                    console.log(e);
                    this.tableLoading = false;
                }
            },
            searchData() {
                this.pageSize = 10;
                this.currentPage = 1;
                this.totalCount = 0;
                this.getData();
            },
            async getData() {
                let params = {
                    pageCount: this.currentPage,
                    takeCount: this.pageSize,
                    bid: this.branchId,
                    branchName: this.branchName,
                    auditStatus: this.auditStatus,
                };
                this.tableLoading = true;
                try {
                    let res = await this.$api.branch.BranchList(params);
                    // console.log(res);
                    if (res) {
                        res.item.forEach((item) => {
                            item.switchStatus = item.status == 0 ? false : true;
                        });
                        this.totalCount = res.totalCount;
                        this.tableData = res.item;
                    } else {
                        console.log("empty data");
                    }
                    this.tableLoading = false;
                } catch (e) {
                    console.log(e);
                    this.tableLoading = false;
                }
            },
            async IsOperable(pid, userid) {
                try {
                    let res = await this.$api.authority.IsOperable(pid, userid);
                    if (pid == "OP100345") {
                        this.addShow = res;
                    }
                    if (pid == "OP100346") {
                        this.auditShow = res;
                    }
                    if (pid == "OP100375") {
                        this.deleteBranchShow = res;
                    }
                } catch (e) {
                    console.log(e);
                    this.$message.error("获取按钮权限失败！");
                }
            },
            getBtnAuth() {

                // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
                let userId = this.$cookies.get("userId");
                if (userId == null || userId == "") userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

                this.IsOperable("OP100345", userId);
                this.IsOperable("OP100346", userId);
                this.IsOperable("OP100375", userId);
            },
        },
        mounted() {
            this.getData();
            this.getBtnAuth();
        },
    };
</script>


<style src="@/assets/public.css"></style>
<style scoped>
    #ServiceBranch {
        padding: 20px;
    }

    .op-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .row-input {
        width: 240px;
        margin: 0 20px 0 10px;
    }

    .table-container {
        background: #fff;
        padding: 0 20px 20px;
    }
</style>
