<script>
export default {
  props: ["menuId", "relationId"],
  data() {
    return {
      indexMethod: 1,
      dialog: false,
      lists: [],
      totalCount: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  methods: {
    show() {
      this.currentPage = 1;
      this.pageSize = 10;
      if (this.menuId != "") {
        this.getLists();
      }
      this.dialog = true;
    },
    close() {
      this.lists = [];
      this.totalCount = 0;
    },
    async getLists() {
      let listParams = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      try {
        console.log(this.menuId);
        console.log(this.relationId);
        let listData = await this.$api.publicapi.getOpLogs(
          this.menuId,
          this.relationId,
          listParams
        );
        this.totalCount = listData.totalCount;
        this.lists = listData.item;
      } catch (err) {
        console.log(err);
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getLists();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getLists();
    },
  },
};
</script>

<template>
  <div>
    <slot></slot>
    <el-button @click="show">操作记录</el-button>
    <!-- 弹窗 -->
    <el-dialog
      title="操作记录弹窗"
      width="600px"
      h
      :visible.sync="dialog"
      :append-to-body="true"
      @close="close"
      center
    >
      <el-table
        max-height="400"
        width="700"
        :data="lists"
        style="margin-bottom: 20px"
      
      >
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod"
          width="100"
        />
        <el-table-column prop="operateUser" label="操作者" width="150" />
        <el-table-column prop="operateTime" label="时间" width="150" />
        <el-table-column prop="operateName" label="操作" width="150" />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :current-page="currentPage"
        layout="jumper, prev, pager, next, sizes, total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>
