<template>
  <div id="BusinessAcceptance">
    <div class="page-header">
      <p class="page-path">业务管理<span class="slashline">/</span>业务受理</p>
      <p class="page-title">业务受理</p>
    </div>
    <div class="operation-pannel">
      <kutabs :tabs="Kutabs" @tabChanged="tabChanged"></kutabs>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filters"
      >
        <el-row :gutter="16">
          <el-col :span="4">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="filters.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="身份证：" prop="idCard">
              <el-input
                v-model="filters.idNumber"
                placeholder="请输入身份证"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="所属单位：" prop="company">
              <el-input
                v-model="filters.company"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="受理状态：" prop="applyStatus">
              <el-select
                v-model="filters.applyStatus"
                clearable
                placeholder="请选择"
              >
                <el-option label="待受理" value="0"></el-option>
                <el-option label="已受理" value="1"></el-option>
                <el-option label="退回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button
              type="primary"
              @click="searchList"
              :loading="queryLoading"
              >查询</el-button
            >
            <el-button
              type="default"
              @click="
                pageSize = 10;
                currentPage = 1;
                totalCount = 0;
                resetForm('filters');
              "
              :loading="queryLoading"
              >重置</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="4">
            <el-form-item label="托管网点：" prop="branch">
              <el-input
                v-model="filters.branch"
                placeholder="请输入托管网点"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="所属客户：" prop="customer">
              <el-select
                v-model="filters.customer"
                clearable
                placeholder="请选择"
              >
                <el-option label="ERS" value="ERS"></el-option>
                <el-option label="FAR" value="FAR"></el-option>
                <el-option label="ESB" value="ESB"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="生效月份：" prop="effectiveMonth">
              <el-date-picker
                value-format="yyyy-MM"
                v-model="filters.effectiveMonth"
                type="month"
                placeholder="选择月"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button type="primary" @click="AcceptBusiness" v-show="processShow"
          >受理业务</el-button
        >
        <el-button
          type="danger"
          plain
          @click="BusinessReturn"
          v-show="processShow"
          >业务退回</el-button
        >
        <el-button type="default" @click="exportExcel" v-show="processShow"
          >导出数据</el-button
        >
        <el-button type="default" @click="ImportData" v-show="importShow"
          >导入数据</el-button
        >
      </div>
      <el-table
        ref="BusinessAcceptanceTable"
        max-height="500"
        :data="BusinessAcceptanceTableData"
        border
        style="margin: 32px 0 30px"
        v-loading="queryLoading"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column label="导出次数" prop="exportNum"></el-table-column>
        <el-table-column label="剩余处理时间" width="140">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.color }">{{
              scope.row.endTime
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="受理状态">
          <template slot-scope="scope">
            {{ getApplyStatus(scope.row.applyStatus) }}
          </template>
        </el-table-column>
        <el-table-column
          label="托管网点"
          prop="branch"
          width="185"
        ></el-table-column>
        <el-table-column label="所属客户" prop="customer"> </el-table-column>
        <el-table-column label="姓名" prop="name"> </el-table-column>
        <el-table-column
          label="身份证"
          prop="idCard"
          width="150"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="phone"
          width="120"
        ></el-table-column>
        <el-table-column label="所属单位" width="250" prop="enterpriseName">
        </el-table-column>
        <el-table-column label="个人信息" width="120">
          <template slot-scope="scope">
            <el-button type="default" @click="personnelInformation(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="申报类型">
          <template slot-scope="scope">
            {{ getApplyType(scope.row.applyType) }}
          </template>
        </el-table-column>
        <el-table-column
          label="参保类型"
          prop="insuranceType"
        ></el-table-column>
        <el-table-column label="申报基数" prop="applyBaseNum"></el-table-column>
        <el-table-column label="生效月份" prop="effectiveMonth">
        </el-table-column>
        <el-table-column
          label="补缴起始月份"
          prop="arrearsBegin"
          width="110"
        ></el-table-column>
        <el-table-column
          label="补缴结束月份"
          prop="arrearsEnd"
          width="110"
        ></el-table-column>
        <el-table-column
          label="退保起始月份"
          prop="withdrawBegin"
          width="110"
        ></el-table-column>
        <el-table-column
          label="退保结束月份"
          prop="withdrawEnd"
          width="110"
        ></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column
          label="第三方编号"
          prop="thirdPartNum"
          width="130"
        ></el-table-column>
        <el-table-column label="申请时间" prop="addTime" width="90">
          <template slot-scope="scope">
            {{
              commonjs.dateFtt(
                "yyyy-MM-dd hh:mm:ss",
                new Date(scope.row.addTime)
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="反馈详细" width="100">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row.applyStatus == 1"
              type="primary"
              @click="seenFeedBack(scope.row.thirdPartNum)"
              >反馈详细</el-button
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000, 2000]"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导入数据"
      :visible.sync="ImportDataDialog"
      width="500px"
      :before-close="handleExcelClose"
    >
      <el-form
        :model="ImportDataForm"
        ref="ImportDataForm"
        :rules="ImportDataFormRules"
        label-width="100px"
      >
        <el-form-item label="单位名称：" prop="company">
          <el-select
            clearable
            v-model="ImportDataForm.company"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in companyArray"
              :key="index"
              :label="item.lable"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属客户：" prop="customer">
          <el-select
            clearable
            v-model="ImportDataForm.customer"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in customerArray"
              :key="index"
              :label="item.lable"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Excel文件：" prop="failDescribe">
          <el-button type="text">下载模板</el-button>
          <el-upload
            class="upload-demo"
            action="/"
            :limit="1"
            :on-remove="handleRemove"
            :auto-upload="false"
            :on-change="onChangeFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传.xls|.xlsx格式文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleExcelClose">取 消</el-button>
        <el-button
          type="primary"
          @click="uploadExcelSubmit(ImportDataForm)"
          :loading="importBtnSubmitLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="个人信息"
      :visible.sync="PersonnelInformationDialog"
      width="700px"
      @close="PersonnelInformationDialogTableData = []"
    >
      <el-table
        ref="PersonnelDataDialogTable"
        max-height="500"
        :data="PersonnelInformationDialogTableData"
        border
        style="margin: 32px 0 30px"
      >
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="编号" prop="key"> </el-table-column>
        <el-table-column label="名称" prop="keyName"> </el-table-column>
        <el-table-column label="值" prop="value"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PersonnelInformationDialog = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="反馈详细" :visible.sync="showInfofb">
      <el-table :data="fbData" ref="fbTable">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="insName" label="险种名称"></el-table-column>
        <el-table-column label="反馈状态">
          <template slot-scope="scope">
            <span v-if="scope.row.feedBackStatus == 0">待反馈</span>
            <span v-else-if="scope.row.feedBackStatus == 1">成功</span>
            <span v-else-if="scope.row.feedBackStatus == 2">失败</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="feedBackDetails"
          label="反馈失败原因"
        ></el-table-column>
        <el-table-column prop="applyBaseNum" label="申报基数"></el-table-column>
        <el-table-column
          prop="effectiveMonth"
          label="生效月份"
        ></el-table-column>
        <el-table-column prop="paidMonth" label="实缴月份"></el-table-column>
        <el-table-column prop="feedBackTime" label="反馈时间"></el-table-column>
        <el-table-column
          prop="pendingStatus"
          label="异常反馈"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="受理结果"
      :visible.sync="showInfoAccpet"
      :before-close="handleClose"
    >
      <el-table :data="accpetResultData" ref="acceptTable">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column label="处理结果">
          <template slot-scope="scope">
            <span
              v-if="scope.row.code == 200"
              style="
                 {
                  color: green;
                }
              "
              >成功</span
            >
            <span
              v-else
              style="
                 {
                  color: red;
                }
              "
              >失败</span
            >
          </template>
        </el-table-column>
        <el-table-column label="异常信息">
          <template slot-scope="scope">
            <span v-if="scope.row.code == 200">---</span>
            <span v-else>{{ scope.row.msg }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="idCard" label="身份证"></el-table-column>
        <el-table-column
          prop="enterpriseName"
          label="单位名称"
        ></el-table-column>
        <el-table-column
          prop="thirdPartNum"
          label="第三方编号"
        ></el-table-column>
        <el-table-column label="申报类型">
          <template slot-scope="scope">
            {{ getApplyType(scope.row.applyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="effectiveMonth"
          label="生效月份"
        ></el-table-column>
        <el-table-column prop="policyName" label="政策名称"></el-table-column>
        <el-table-column
          prop="categoryCount"
          label="生成险种数"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import kutabs from "@/components/kutabs";
export default {
  name: "AddNetwork",
  components: {
    kutabs,
  },
  data: function () {
    return {
      menuId: "",
      showInfofb: false,
      fbQueryLoading: false,
      fbData: [],
      accpetResultData: [],
      showInfoAccpet: false,
      fileList: [],
      Kutabs: [
        {
          name: "全部",
          num: 56,
        },
        {
          name: "社保新增",
          num: 24,
        },
        {
          name: "社保续保",
          num: 24,
        },
        {
          name: "社保停保",
          num: 12,
        },
        {
          name: "社保变更",
          num: 23,
        },
        {
          name: "社保补缴",
          num: 44,
        },
        {
          name: "社保退保",
          num: 44,
        },
        {
          name: "公积金新增",
          num: 44,
        },
        {
          name: "公积金调入",
          num: 44,
        },
        {
          name: "公积金封存",
          num: 44,
        },
        {
          name: "公积金变更",
          num: 44,
        },
        {
          name: "公积金补缴",
          num: 44,
        },
        {
          name: "公积金退保",
          num: 44,
        },
      ],
      queryLoading: false,
      importShow: false,
      importBtnSubmitLoading: false,
      processShow: false,
      activeKutab: -1,
      filters: {
        name: "",
        idNumber: "",
        company: "",
        branch: "",
        customer: "",
        effectiveMonth: "",
        applyStatus: "0",
      },
      BusinessAcceptanceTableData: [],
      totalCount: 0,
      ImportDataDialog: false,
      ImportDataForm: {
        company: "",
        customer: "",
      },
      customerArray: [],
      companyArray: [],
      ImportDataFormRules: {
        company: [
          { required: true, message: "请选择单位名称", trigger: "change" },
        ],
        customer: [
          { required: true, message: "请选择所属客户", trigger: "change" },
        ],
      },
      PersonnelInformationDialog: false,
      PersonnelInformationDialogTableData: [],
      pageSize: 10,
      currentPage: 1,
    };
  },
  async created() {
    let menuId =  await this.$api.publicapi.getMenuId("托管业务管理-业务受理");
    this.menuId = menuId;
  },
  methods: {
    tabChanged(params) {
      if (params.name == "全部") {
        this.activeKutab = -1;
      } else if (params.name == "社保新增") {
        this.activeKutab = 0;
      } else if (params.name == "社保续保") {
        this.activeKutab = 1;
      } else if (params.name == "社保停保") {
        this.activeKutab = 2;
      } else if (params.name == "社保变更") {
        this.activeKutab = 3;
      } else if (params.name == "社保补缴") {
        this.activeKutab = 4;
      } else if (params.name == "社保退保") {
        this.activeKutab = 5;
      } else if (params.name == "公积金新增") {
        this.activeKutab = 6;
      } else if (params.name == "公积金调入") {
        this.activeKutab = 7;
      } else if (params.name == "公积金封存") {
        this.activeKutab = 8;
      } else if (params.name == "公积金变更") {
        this.activeKutab = 9;
      } else if (params.name == "公积金补缴") {
        this.activeKutab = 10;
      } else if (params.name == "公积金退保") {
        this.activeKutab = 11;
      } else {
        this.activeKutab = -1;
      }
      this.searchList();
    },
    handleExceed() {
      this.$message.warning(`一次只能选择单个文件上传`);
    },
    handleRemove() {
      this.fileList = [];
    },
    handleExcelClose() {
      this.fileList = [];
      this.ImportDataDialog = false;
    },
    uploadExcelSubmit() {
      if (
        this.ImportDataForm.company == null ||
        this.ImportDataForm.company == ""
      ) {
        this.$message.error(`请选择单位!`);
        return;
      }
      if (
        this.ImportDataForm.customer == null ||
        this.ImportDataForm.customer == ""
      ) {
        this.$message.error(`请选择所属客户!`);
        return;
      }
      if (this.fileList[0] == null) {
        this.$message.error(`请选择文件上传!`);
        return;
      }
      let formData = new FormData();
      formData.append("file", this.fileList[0]);
      formData.append("CustomerName", this.ImportDataForm.customer.lable);
      formData.append("CustomerId", this.ImportDataForm.customer.value);
      formData.append("EnterpriseName", this.ImportDataForm.company.lable);
      formData.append("EnterpriseId", this.ImportDataForm.company.value);
      this.importBtnSubmitLoading = true;
      this.$api.orderProcess
        .uploadExcel(formData)
        .then((res) => {
          if (res.code == 500) {
            this.$message.error(`导入异常!`);
            let text = res.msg;
            this.$msgTotxt(text, "导入异常原因.txt");
          } else if (res.failList != null && res.failList.length > 0) {
            this.$message.error(`处理失败!`);
            let text = res.msg + "\n\r \n\r";
            res.failList.forEach((e) => {
              text +=
                e.name +
                "_" +
                e.idCard +
                "_" +
                e.thirdPartNum +
                "：" +
                e.msg +
                "\r\n";
            });
            this.$msgTotxt(text, "导入处理失败原因.txt");
          } else {
            this.$message({
              type: "success",
              message: "批量导入申报成功!",
            });
            this.ImportDataDialog = false;
            this.resetForm();
          }
          this.importBtnSubmitLoading = false;
        })
        .catch((e) => {
          this.importBtnSubmitLoading = false;
          this.$message.error("处理失败,发生异常");
          this.$msgTotxt(e.data.msg, "导入处理异常原因.txt");
        });
    },
    onChangeFile(file, fileList) {
      let f = file.name.split(".").pop();
      if (f != "xls" && f != "xlsx") {
        fileList = [];
        this.fileList = [];
        this.$message({
          message: "请上传 .xls|.xlsx 格式的文件",
          type: "error",
        });
      } else {
        this.fileList = fileList.length > 0 ? [file.raw] : [];
      }
    },
    getApplyType(applyType) {
      switch (applyType) {
        case 0:
          return "社保新增";
        case 1:
          return "社保续保";
        case 2:
          return "社保停保";
        case 3:
          return "社保变更";
        case 4:
          return "社保补缴";
        case 5:
          return "社保退保";
        case 6:
          return "公积金新增";
        case 7:
          return "公积金调入";
        case 8:
          return "公积金封存";
        case 9:
          return "公积金变更";
        case 10:
          return "公积金补缴";
        case 11:
          return "公积金退保";
        default:
          return "";
      }
    },
    getApplyStatus(applyStatus) {
      switch (applyStatus) {
        case 0:
          return "待受理";
        case 1:
          return "已受理";
        case 2:
          return "退回";
        default:
          return "";
      }
    },
    personnelInformation(row) {
      // 个人信息
      this.getPersonnelInformationDetail(row.id);
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getData();
    },
    AcceptBusiness() {
      // 受理业务
      let selection = this.$refs.BusinessAcceptanceTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待受理'
        if (item.applyStatus != 0) {
          this.$message({
            message: "请选择“待受理”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        if (item.exportNum == 0) {
          this.$message({
            message: "不能受理导出次数为0的数据",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      this.$confirm("确认受理选中的业务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api --> 受理业务
          this.acceptAPI(ids);
        })
        .catch(() => {});
    },
    BusinessReturn() {
      // 业务退回
      let selection = this.$refs.BusinessAcceptanceTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        // 判断选中的数据状态是否全是'待受理'
        if (item.applyStatus != 0) {
          this.$message({
            message: "请选择“待受理”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
        ids.push(item.id);
      }
      this.$confirm("确认退回选中的业务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // api --> 业务退回
          this.cancelAPI(ids);
        })
        .catch(() => {});
    },
    ImportData() {
      this.ImportDataDialog = true;
      if (this.companyArray.length == 0) {
        this.$api.publicapi
          .enterprises()
          .then((res) => {
            this.companyArray = res;
          })
          .catch(() => {});
      }
      if (this.customerArray.length == 0) {
        this.$api.publicapi
          .customers()
          .then((res) => {
            this.customerArray = res;
          })
          .catch(() => {});
      }
    },
    resetForm() {
      this.filters = {
        name: "",
        idNumber: "",
        company: "",
        branch: "",
        customer: "",
        effectiveMonth: "",
        applyStatus: "",
      };
      this.searchList();
    },
    async acceptAPI(ids) {
      try {
        let res = await this.$api.orderProcess.accept(ids);
        if (res.code == 200) {
          let resultMsg = "";
          let successCount = 0;
          let failCount = 0;
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].code == 200) {
              successCount++;
              continue;
            } else failCount++;
            resultMsg +=
              res.data[i].name +
              "_" +
              res.data[i].idCard +
              "_" +
              res.data[i].thirdPartNum +
              ":" +
              res.data[i].msg +
              "\n\r";
          }
          if (failCount != 0) {
            if (successCount == 0) this.$message.error("全部操作失败！");
            else this.$message.error("部分操作失败！");
            this.$msgTotxt(
              "总计处理：" +
                (successCount + failCount) +
                " \t成功：" +
                successCount +
                " \t失败：" +
                failCount +
                "\r\n" +
                resultMsg,
              "受理失败原因.txt"
            );
          } else {
            this.$message({
              type: "success",
              message: "受理成功!",
            });
          }
          this.getData();
          //this.accpetResultData = res.data;
          //this.showInfoAccpet = true;
        } else {
          this.$message.error("操作失败！");
          this.$msgTotxt(res.msg, "受理失败原因.txt");
        }
      } catch (e) {
        this.$message.error("操作失败！");
        console.log(e);
        this.$msgTotxt(e.msg, "受理失败原因.txt");
      }
    },
    async cancelAPI(ids) {
      try {
        let res = await this.$api.orderProcess.cancel(ids);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "业务退回成功!",
          });
          this.searchList();
        } else {
          this.$message.error("操作失败！");
          this.$msgTotxt(JSON.stringify(res), "退回失败原因.txt");
        }
      } catch (e) {
        this.$message.error("操作失败！");
        //this.$msgTotxt(e.message,"业务退回失败原因.txt");
        console.log(e);
      }
    },
    async getPersonnelInformationDetail(id) {
      let params = {
        id: id,
      };
      try {
        let res = await this.$api.orderProcess.detail(params);
        if (res.code == 200) {
          this.PersonnelInformationDialogTableData = res.data;
          this.PersonnelInformationDialog = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.getData();
        })
        .catch(() => {});
    },
    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    searchList() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    exportExcel() {
      let selection = this.$refs.BusinessAcceptanceTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        ids.push(item.id);
      }
      this.$confirm("确认导出选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.orderProcess.export({ ids: ids }).then((response) => {
            let fileName =
              "业务受理" + new Date().toLocaleString("en-GB") + ".zip";
            this.$downloadByFileBytes(response, fileName);
            this.getData();
          });
        })
        .catch(() => {});
    },
    async getData() {
      this.queryLoading = true;
      let listParams = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        idCard: this.filters.idNumber,
        name: this.filters.name,
        enterpriseName: this.filters.company,
        branch: this.filters.branch,
        // branchId: 0,
        effectiveMonth: this.filters.effectiveMonth,
        customer: this.filters.customer,
        applyStatus: this.filters.applyStatus,
      };
      if (this.activeKutab != -1) {
        listParams.applyType = this.activeKutab;
      }
      try {
        let listData = await this.$api.orderProcess.list(listParams);
        this.totalCount = listData.data.totalCount;
        let now = new Date();
        for (var i = 0; i < listData.data.item.length; i++) {
          if (listData.data.item[i].applyStatus != 0) {
            listData.data.item[i].endTime = "已操作";
            listData.data.item[i].color = "green";
          } else if (listData.data.item[i].operationEndTime == null) {
            listData.data.item[i].endTime = "???";
            listData.data.item[i].color = "red";
          } else {
            var date = new Date(listData.data.item[i].operationEndTime);
            var day = (date.getTime() - now.getTime()) / (24 * 60 * 60 * 1000);
            if (day <= 0) {
              listData.data.item[i].endTime = "已截止";
              listData.data.item[i].color = "red";
            } else {
              listData.data.item[i].endTime = diffTime(now, date);
              if (day <= 2) listData.data.item[i].color = "red";
              else listData.data.item[i].color = "#606266";
            }
          }
        }
        this.BusinessAcceptanceTableData = listData.data.item;
        this.queryLoading = false;
      } catch (err) {
        this.$message.error(err);
        console.log(err);
        this.queryLoading = false;
      }
    },
    async getStat() {
      try {
        let stat = await this.$api.orderProcess.stat();
        let arr = [];
        for (let i of stat.data) {
          let obj = new Object();
          obj.name = i.name;
          obj.num = i.count;
          obj.applyType = i.applyType;
          arr.push(obj);
        }
        this.Kutabs = arr;
      } catch (err) {
        this.$message.error(err);
        console.log(err);
      }
    },
    async IsOperable(pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid);
        if (pid == "OP100352") {
          this.processShow = res;
        }
        if (pid == "OP100353") {
          this.importShow = res;
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取按钮权限失败！");
      }
    },
    getBtnAuth() {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get("userId");
      if (userId == null || userId == "")
        userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

      this.IsOperable("OP100352", userId);
      this.IsOperable("OP100353", userId);
    },
    exportErrorMsg() {},
    async seenFeedBack(thirdPartNum) {
      this.fbQueryLoading = true;
      this.showInfofb = true;
      let listParams = {
        pageCount: 1,
        takeCount: 30,
        thirdPartNum: thirdPartNum,
      };
      try {
        let data = await this.$api.orderFeedback.list(listParams);
        console.log(data.item);
        this.fbData = data.data.item;
      } catch (error) {
        console.log(error);
        this.fbQueryLoading = false;
      }
      this.fbQueryLoading = false;
    },
  },
  mounted() {
    this.getData();
    this.getStat();
    this.getBtnAuth();
  },
};

function diffTime(startDate, endDate) {
  var diff = endDate.getTime() - startDate.getTime(); //时间差的毫秒数
  //计算出相差天数
  var days = Math.floor(diff / (24 * 3600 * 1000));
  //计算出小时数
  var leave1 = diff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000));
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));
  var returnStr = "";
  if (minutes > 0) {
    returnStr = minutes + "分" + returnStr;
  }
  if (hours > 0) {
    returnStr = hours + "小时" + returnStr;
  }
  if (days > 0) {
    returnStr = days + "天" + returnStr;
  }
  return returnStr;
}
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#BusinessAcceptance {
  padding: 20px;
}

#BusinessAcceptance .operation-pannel {
  padding-top: 0;
}
</style>
