// import all api and export them
import accounts from './account/index'
import orderProcess from './orderProcess/index'
import policy from './policy/index'
import orderFeedback from './orderFeedback/index'
import insured from './insuredEmp/index'
import publicapi from './public/index'
import entrustOrder from './entrust/order'
import entrustAccount from './entrust/account'
import branch from './branch/index'
import authority from './authority/index'
import pendingNotice from './pendingNotice/index'
import BillManage from './BillManage/index'
import CostBill from './BillManage/costBill'
import SaleBill from './BillManage/saleBill'
import ApplyField from './applyField/applyField'
import OperateDeadline from './operateDeadline/operateDeadline'
import personMove from './statistics/personMove'
import PersonnelUnderProtection from './PersonnelUnderProtection/PersonnelUnderProtection'

export default {
  accounts, // 开户
  orderProcess, // 业务受理
  policy, // 政策
  orderFeedback,
  insured,
  publicapi,
  entrustOrder, // 托管业务申报
  entrustAccount, // 托管账户
  branch, // 网点
  authority,
  pendingNotice, //异常通知
  BillManage,//费用模板管理
  CostBill, //应付账单
  SaleBill, //应收账单
  ApplyField, //申报字段
  OperateDeadline, //城市配置操作截止日
  personMove, //人员异动统计
  PersonnelUnderProtection
}