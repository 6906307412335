import api from '../index'

export default {
  AddBranch(params) { // 新增小库网点
    return api.post('/AddBranch', params)
  },
  UpdateBranch(params) { // 编辑小库网点
    return api.post('/UpdateBranch', params)
  },
  BranchAudit(bid, flag, opinion) { // 小库审核
    return api.post('/BranchAudit/' + bid + '/' + flag+'/' + opinion)
  },
  BranchEffective(params) { // 小库生效
    return api.post('/BranchEffective/' + params.bid + '/' + params.flag)
  },
  BranchList(params) { // 小库网点列表
    return api.post('/BranchList', params)
  },
  BranchDetail(bid) { // 小库网点详情
    return api.get('/BranchDetail/' + bid)
  },
  BranchesCount(district) { // 地区网点编号，拼接网点名称
    return api.get('/BranchesCount/' + district)
  },
  EffecttiveBranches(district) { // 地区网点编号，拼接网点名称
    return api.get('/EffecttiveBranches/' + district)
  },
  EssentialsUpload(params,filename) { // 小库文件上传
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return api.postwithparams('/EssentialsUpload/'+ filename, params, headers)
  },
}